import type { Module } from 'vuex';
import { Organisation } from '@/open-api';
import BrokenContentModule from '@/store/BrokenContentModule';
import LowConfidenceModule from '@/store/LowConfidenceModule';
import MissingKnowledgeModule from '@/store/MissingKnowledgeModule';
//import SnoozedModule from '@/store/SnoozedModule';
import MutedQuestionsModule from '@/store/MutedQuestionsModule';

export interface OptimizeModuleState {}

export default function (org: Organisation) {
    return {
        namespaced: true,
        state: {},
        modules: {
            brokenContent: BrokenContentModule(org),
            lowConfidence: LowConfidenceModule(org),
            missingKnowledge: MissingKnowledgeModule(org),
            mutedQuestions: MutedQuestionsModule(org)
        },
        getters: {},
        mutations: {}
    } as Module<OptimizeModuleState, any>;
}
