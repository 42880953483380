<template>
    <div v-if="shouldShowWebchatClient">
        <dx-chatbot-client />
    </div>
    <web-component-loader
        v-model:loading="loading"
        :source="dxChatbotUrl"
        @close="handleClose"
    />
</template>

<script lang="ts">
import { defineComponent, inject, PropType } from 'vue';
import WebComponentLoader from '@/components/webcomponent-loader/WebComponentLoader.vue';
import { DigitalExperienceProvider } from '@/open-api';

export default defineComponent({
    components: {
        WebComponentLoader
    },
    props: {
        botData: {
            type: Object as PropType<{
                botid: number;
                botname: string;
                channelid: string;
                providerId?: string | number;
            }>
        },
        closeChatbotDrawer: {
            type: Function as PropType<() => void>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    watch: {
        loading(newLoading, oldLoading) {
            if (oldLoading && !newLoading) {
                // @ts-ignore
                window.dxbot.publish('TEARDOWN_CHATBOT', {});

                localStorage.removeItem('customer.DEVICE_ID');
                // @ts-ignore
                window.dxbot.publish('SETUP_CHATBOT', {
                    channelId: this.botData?.channelid,
                    providerEmail: this.botData?.providerId,
                    isTryNow: true
                });
                document.addEventListener('SETUP_CHATBOT_FINISHED', () => {
                    // @ts-ignore
                    window.dxbot.publish('START_CHATBOT_SESSION', {});
                });
            }
        }
    },
    mounted() {
        // @ts-ignore
        window.dxe = {
            server: this.koopid?.url
        };
    },
    computed: {
        /* v8 ignore next 16 */
        zone(): string {
            return this.$store.getters[`${this.orgId}/zone`];
        },
        dxChatbotUrl(): string {
            //return 'http://localhost:8001/src/sdk/DXChatbotSDK.ts';
            return `${this.koopid.url}/dxclient/dist/dialpad-chatbot.es.js`;
        },
        koopid(): DigitalExperienceProvider {
            return this.$store.getters[`${this.orgId}/koopid`];
        },
        shouldShowWebchatClient(): boolean {
            return !this.loading;
        }
    },
    methods: {
        handleClose() {
            this.$emit('update:botData', undefined);
            this.closeChatbotDrawer?.();
        }
    },
    data() {
        return {
            loading: true
        };
    }
});
</script>
