<template>
    <div class="editor-container d-d-flex d-fd-column d-fl-grow1">
        <div
            class="d-d-flex d-w100p d-fd-column editor-inner d-bgc-primary d-bar4 d-gg2"
            ref="richTextEditorWrapper"
        >
            <div
                ref="toolbar"
                class="markdown-editor-toolbar d-bgc-secondary d-fl-grow1 d-d-flex d-ai-center d-py4 d-px8 d-box-border d-fw-wrap d-bb d-bc-subtle d-btr4 d-gg8"
                v-if="shouldShowToolbar"
            >
                <toolbar
                    :editor="editorView"
                    :items="toolbarItems"
                    :update-toolbar="updateToolbar"
                    :schema="mySchema as Schema"
                    v-model:is-savable="isSavable"
                />
            </div>

            <editor-block
                v-model:editor-view="editorView"
                v-model:current-length="currentLength"
                :should-show-footer="shouldShowFooter"
                :should-show-toolbar="shouldShowToolbar"
                :editable="editable"
                :is-already-editing="isAlreadyEditing"
                :schema="mySchema"
                :update-toolbar="updateToolbar"
                :content="content"
                :menu-plugin="menuPlugin"
            />
            <editor-footer
                v-if="shouldShowFooter"
                :is-savable="isSavable"
                @save="handleSave"
                @cancel-edit="handleCancelEdit"
                :markdown-char-count="currentLength"
            />
        </div>
    </div>
</template>

<script lang="ts">
import {
    defineComponent,
    inject,
    type PropType,
    toRaw,
    type UnwrapNestedRefs
} from 'vue';

import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Schema } from 'prosemirror-model';

import { schema } from './schema';
import { DtButton, DtIcon } from '@dialpad/dialtone/vue3';
import Toolbar, {
    toolbarItem
} from '@/components/rich-text-editor/Toolbar.vue';
import { Subject } from 'rxjs';
import {
    markdownParser,
    markdownSerializer
} from '@/components/rich-text-editor/markdown';
import type { ResponseBlockEditor } from '@/components/node-editor/responseBlockEditor';
import type { Block } from '@/components/node-editor/responseBlockEditor';
import type { Knowledgebase } from '@/open-api';
import type { ApiService } from '@/services/Api.service';
import { TEXT_BLOCK_LIMIT } from '@/utils/Constants';
import EditorBlock from '@/components/rich-text-editor/EditorBlock.vue';
import EditorFooter from '@/components/rich-text-editor/EditorFooter.vue';
import { buildInputRules } from '@/components/rich-text-editor/input-rules';
import { characterCount, menu } from '@/components/rich-text-editor/utils';
import { history } from 'prosemirror-history';
import { keymap } from 'prosemirror-keymap';
import { buildKeymap } from '@/components/rich-text-editor/keymap';
import { baseKeymap } from 'prosemirror-commands';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';

export default defineComponent({
    props: {
        markdown: {
            type: String as PropType<string>,
            default: ''
        },
        editable: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        block: {
            type: Object as PropType<Block>
        }
    },
    components: {
        EditorFooter,
        EditorBlock,
        Toolbar,
        DtButton,
        DtIcon
    },
    updated() {
        if (this.editable) {
            this.editorView?.focus();
        }
    },

    watch: {
        isEditable(editable) {
            this.editorView?.setProps({
                editable: () => !!editable
            });
        },
        isAlreadyEditing(isEditing: boolean) {
            if (isEditing) {
                this.editorView?.setProps({
                    editable: () => !isEditing
                });
            }
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        const updateToolbar = new Subject<any>();
        const menuPlugin = menu(updateToolbar as any);
        return {
            orgId,
            menuPlugin,
            updateToolbar
        };
    },
    computed: {
        Schema() {
            return Schema;
        },
        /* v8 ignore next 35 */
        toolbarItems(): string[] {
            return [
                toolbarItem.Bold,
                toolbarItem.Italic,
                toolbarItem.Strikethrough,
                toolbarItem.Link,
                toolbarItem.BulletList,
                toolbarItem.NumberList,
                toolbarItem.CodeBlock
                //toolbarItem.Code
            ];
        },
        content: {
            get(): string {
                return this.markdown;
            },
            set(content: string) {
                this.$emit('update:markdown', content);
            }
        },
        isEditable: {
            get(): boolean {
                return !!this.editable;
            },
            set(editable: boolean) {
                this.$emit('update:editable', editable);
            }
        },
        blocks(): UnwrapNestedRefs<Block[]> {
            return this.responseBlockEditor?.blocks || [];
        },
        isAlreadyEditing(): boolean {
            return this.blocks.some((block: any) => block.data.editing);
        },
        shouldShowToolbar(): boolean {
            return !!this.editorView && this.editable;
        },
        shouldShowFooter(): boolean {
            return this.editable;
        },
        responseBlockEditor(): ResponseBlockEditor | undefined {
            return this.$store.getters[`${this.orgId}/responseBlockEditor`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        rawEditor(): EditorView {
            return toRaw(this.editorView);
        },
        rawSchema(): Schema {
            return toRaw(this.mySchema);
        }
    },
    methods: {
        handleSave(e: Event) {
            e.stopPropagation();
            if (this.editorView) {
                this.content = markdownSerializer.serialize(
                    this.editorView.state.doc
                );
                this.isEditable = false;
                this.$emit('save');
            }
        },
        handleCancelEdit(e: Event) {
            e.stopPropagation();
            if (
                this.block?.new &&
                this.editorView &&
                !this.block?.data?.dirty
            ) {
                this.handleRemove();
            } else {
                const previousText = this.block?.data?.text_block?.text;
                const newState = EditorState.create({
                    schema,
                    doc: markdownParser.parse(previousText),
                    plugins: [
                        buildInputRules(this.mySchema),
                        this.menuPlugin,
                        history(),
                        keymap(buildKeymap(toRaw(this.mySchema))),
                        keymap(baseKeymap),
                        dropCursor(),
                        gapCursor(),
                        characterCount(this.orgId)
                    ]
                });
                this.rawEditor.updateState(newState);
                this.rawEditor.dispatch(this.rawEditor.state.tr);

                this.isEditable = false;
            }
        },
        handleRemove() {
            if (this.block) {
                this.responseBlockEditor?.removeBlock(this.block);
            }
        }
    },
    data() {
        return {
            editorView: undefined as EditorView | undefined,
            // updateToolbar: new Subject<any>(),
            isSavable: true,
            mySchema: new Schema({
                nodes: schema.spec.nodes,
                marks: schema.spec.marks
            }),
            maxLength: TEXT_BLOCK_LIMIT,
            currentLength: 0
        };
    }
});
</script>

<style lang="less">
.markdown-editor {
    .d-list-group {
        padding: 0 0 0 var(--dt-size-400) !important;
    }
    .d-list-group.d-lst-circle {
        margin-left: 10px !important;
        li {
            list-style: disc;
        }
    }

    .d-list-group.d-lst-decimal {
        margin-left: 10px !important;
        li {
            list-style: auto;
        }
    }

    h1,
    h2 {
        margin-top: 1.6rem;
    }
    h3,
    h4 {
        margin-top: 1.2rem;
    }
    h5,
    h6 {
        margin-top: 0.8rem;
    }
}

blockquote {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}
</style>
