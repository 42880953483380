<template>
    <div
        class="h:d-bgc-primary d-c-pointer d-t d-td300 d-h72 d-p16 d-ps-relative d-d-flex d-ai-center"
    >
        <div class="d-fd-column d-w90p d-d-flex">
            <div class="d-truncate d-h24 d-d-block d-w100p">
                {{ title }}
            </div>
            <label-tag :labels="labels" chip-size="xs" class="d-mt2" />
        </div>
        <dt-icon
            v-if="isHover"
            name="chevron-right"
            size="300"
            class="d-o50 d-t d-td300 d-ps-absolute d-r16 d-t24 d-mt4"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import { DtIcon } from '@dialpad/dialtone/vue3';
import LabelTag from '@/components/label-tag/LabelTag.vue';
import type { Knowledgebase } from '@/open-api';

export default defineComponent({
    props: {
        title: {
            type: String as PropType<string>,
            required: true
        },
        labels: {
            type: Array as PropType<string[]>
        },
        isHover: {
            type: Boolean as PropType<boolean>
        }
    },
    components: {
        LabelTag,
        DtIcon
    },
    computed: {
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        }
    }
});
</script>
