<template>
    <div class="d-d-flex d-ai-center d-fc-black-900 d-h24">
        <div
            class="d-tt-none d-pl2 d-ml1 d-fc-black-700 d-truncate d-fs-100"
            v-if="columnTitle"
        >
            {{ $t(columnTitle) }}
        </div>
        <dt-tooltip
            class="d-ml6 d-mt4"
            :placement="columnTooltip?.placement || 'top'"
            :message="columnTooltip?.message"
            :offset="[-10, 10]"
            v-if="columnTooltip && Object.keys(columnTooltip)?.length"
        >
            <template #anchor>
                <dt-icon
                    v-if="columnTooltip?.type"
                    :name="columnTooltip?.type"
                    size="200"
                />
            </template>
        </dt-tooltip>
        <dt-tooltip
            class="d-ml6"
            :placement="columnTooltip?.placement || 'top'"
            :message="tooltipMessage"
            v-if="columnSortable && columnTitle && columnType !== 'options'"
        >
            <template #anchor>
                <dt-button
                    v-if="
                        columnSortable &&
                        columnTitle &&
                        columnType !== 'options'
                    "
                    @click="sortList(columnData)"
                    kind="muted"
                    importance="clear"
                    class="d-fw-bold"
                    icon-position="right"
                    label-class="d-fs-100 d-tt-none"
                    :data-qa="`${columnKey || columnTitle}-filter-button`"
                >
                    <template #icon>
                        <dt-icon
                            size="200"
                            name="chevrons-up-down"
                            v-if="sortByVal?.sort !== columnSort"
                        />
                        <dt-icon
                            size="200"
                            name="chevrons-down"
                            v-if="
                                sortByVal?.sort === columnSort &&
                                !sortByVal?.asc
                            "
                        />
                        <dt-icon
                            size="200"
                            name="chevrons-up"
                            v-if="
                                sortByVal?.sort === columnSort && sortByVal?.asc
                            "
                        />
                    </template>
                </dt-button>
            </template>
        </dt-tooltip>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import type { IBaseTableColumn } from '@/components/base-table/BaseTable.types';
import {
    DtButton,
    DtCheckbox,
    DtIcon,
    DtTooltip
} from '@dialpad/dialtone/vue3';
import type { IBaseSortableTableColumn } from '@/components/base-table/BaseTable.types';

export default defineComponent({
    components: {
        DtCheckbox,
        DtButton,
        DtIcon,
        DtTooltip
    },
    props: {
        columnData: {
            type: Object as PropType<IBaseTableColumn<any>>,
            required: true
        },
        checkbox: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        sortBy: {
            type: Object as PropType<IBaseSortableTableColumn>
        },
        sortable: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        isDisabled: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        allChecked: {
            type: Boolean as PropType<boolean>,
            default: () => false
        }
    },
    computed: {
        IBaseTableColumn(): any {
            // @ts-ignore
            return IBaseTableColumn;
        },
        /* v8 ignore next 15 */
        allSelected: {
            get(): boolean {
                return this.allChecked;
            },
            set(value: boolean) {
                this.$emit('update:allChecked', value);
            }
        },
        columnTitle(): string | undefined {
            return this.columnData?.title;
        },
        columnKey(): string | undefined {
            return this.columnData?.key;
        },
        columnSortable(): boolean {
            return this.columnData?.sortable || false;
        },
        columnSort(): string | undefined {
            return this.columnData?.sort;
        },
        columnType(): string | undefined {
            return this.columnData?.type;
        },
        columnTooltip():
            | { type: string; message: string; placement: string }
            | undefined {
            return this.columnData?.tooltip;
        },
        sortByVal(): IBaseSortableTableColumn {
            return this.sortBy;
        },
        tooltipMessage(): string {
            if (this.sortByVal?.sort !== this.columnSort) {
                return this.$t('Sort');
            } else if (
                this.sortByVal?.sort === this.columnSort &&
                !this.sortByVal?.asc
            ) {
                return this.$t('Descending');
            } else if (
                this.sortByVal?.sort === this.columnSort &&
                this.sortByVal?.asc
            ) {
                return this.$t('Ascending');
            } else return '';
        }
    },
    methods: {
        /* v8 ignore next 15 */
        sortList(data?: IBaseSortableTableColumn) {
            let asc = false;
            if (data?.sort === this.sortBy?.sort) {
                asc =
                    this.sortBy?.asc === undefined
                        ? false
                        : !this.sortBy?.asc
                          ? true
                          : undefined;
            }
            this.$emit('update:sortBy', {
                sort: asc === undefined ? undefined : data?.sort,
                asc
            });
        },
        toggleAllRows() {
            this.$emit('toggleAllRows');
        }
    }
});
</script>
