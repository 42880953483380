/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClusterDetail } from '../model';
// @ts-ignore
import { ClusterQuery } from '../model';
// @ts-ignore
import { ListBrokenNodesResponse } from '../model';
// @ts-ignore
import { ListClusterQueriesResponse } from '../model';
// @ts-ignore
import { ListClustersResponse } from '../model';
// @ts-ignore
import { ListImproveQueriesResponse } from '../model';
// @ts-ignore
import { ListMutedQueriesResponse } from '../model';
// @ts-ignore
import { ListNodesResponse } from '../model';
// @ts-ignore
import { ModelError } from '../model';
// @ts-ignore
import { MuteQueriesParamsBody } from '../model';
// @ts-ignore
import { OptimizeQueriesResponse } from '../model';
// @ts-ignore
import { PatchClusterQueryRequest } from '../model';
// @ts-ignore
import { PatchClusterRequest } from '../model';
// @ts-ignore
import { UnmuteQueriesParamsBody } from '../model';
/**
 * ImproveApi - axios parameter creator
 * @export
 */
export const ImproveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Use this endpoint to delete a improve cluster
         * @summary Delete Cluster
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster: async (authorization: string, kbId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteCluster', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('deleteCluster', 'kbId', kbId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCluster', 'id', id)
            const localVarPath = `/improve/{kb_id}/clusters/{id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to delete a improve cluster query.
         * @summary Delete Cluster Query
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster Query ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterQuery: async (authorization: string, kbId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('deleteClusterQuery', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('deleteClusterQuery', 'kbId', kbId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteClusterQuery', 'id', id)
            const localVarPath = `/improve/{kb_id}/cluster-query/{id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get a cluster by id with more details about the card
         * @summary Get Cluster
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {'chattering' | 'response' | 'content'} [suggestNodeType] Filter suggested results by node type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCluster: async (authorization: string, kbId: string, id: string, suggestNodeType?: 'chattering' | 'response' | 'content', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getCluster', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getCluster', 'kbId', kbId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCluster', 'id', id)
            const localVarPath = `/improve/{kb_id}/clusters/{id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (suggestNodeType !== undefined) {
                localVarQueryParameter['suggest_node_type'] = suggestNodeType;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get a cluster query by it\'s ID.
         * @summary Get Cluster query
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster Query ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterQuery: async (authorization: string, kbId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getClusterQuery', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('getClusterQuery', 'kbId', kbId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClusterQuery', 'id', id)
            const localVarPath = `/improve/{kb_id}/cluster-query/{id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all nodes which are either quoting or linking an unpublished node. Ignores documents. 
         * @summary List broken nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBrokenNodes: async (authorization: string, kbId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listBrokenNodes', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listBrokenNodes', 'kbId', kbId)
            const localVarPath = `/improve/{kb_id}/nodes/broken`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List suggested nodes to be used as responses for the custer. 
         * @summary List cluster nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {number} [limit] 
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusterNodes: async (authorization: string, kbId: string, id: string, limit?: number, type?: 'chattering' | 'response' | 'content', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listClusterNodes', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listClusterNodes', 'kbId', kbId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listClusterNodes', 'id', id)
            const localVarPath = `/improve/{kb_id}/clusters/{id}/nodes`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List cluster queries 
         * @summary List cluster queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {string} [clusterId] The clusterID cluster queries belong to.
         * @param {'query_count' | 'created_at' | 'updated_at' | 'query'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusterQueries: async (authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, clusterId?: string, sortBy?: 'query_count' | 'created_at' | 'updated_at' | 'query', order?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listClusterQueries', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listClusterQueries', 'kbId', kbId)
            const localVarPath = `/improve/{kb_id}/cluster-queries`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['cluster_id'] = clusterId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to list all unapproved cards
         * @summary List clusters
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {'query_count' | 'created_at' | 'updated_at' | 'title'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters: async (authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, sortBy?: 'query_count' | 'created_at' | 'updated_at' | 'title', order?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listClusters', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listClusters', 'kbId', kbId)
            const localVarPath = `/improve/{kb_id}/clusters`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List improve queries 
         * @summary List improve queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {string} [clusterId] The clusterID improve queries belong to.
         * @param {string} [clusterQueryId] The clusterQueryID the improve queries belong to.
         * @param {string} [conversationId] The conversationID improve queries belong to.
         * @param {'created_at'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listImproveQueries: async (authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, clusterId?: string, clusterQueryId?: string, conversationId?: string, sortBy?: 'created_at', order?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listImproveQueries', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listImproveQueries', 'kbId', kbId)
            const localVarPath = `/improve/{kb_id}/queries`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (clusterId !== undefined) {
                localVarQueryParameter['cluster_id'] = clusterId;
            }

            if (clusterQueryId !== undefined) {
                localVarQueryParameter['cluster_query_id'] = clusterQueryId;
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['conversation_id'] = conversationId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all temporarely muted/ignored queries 
         * @summary List all muted queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {'query' | 'created_at'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMutedQueries: async (authorization: string, kbId: string, limit?: number, cursor?: string, sortBy?: 'query' | 'created_at', order?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listMutedQueries', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listMutedQueries', 'kbId', kbId)
            const localVarPath = `/improve/{kb_id}/muted-queries`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all nodes which have not been updatd recently. 
         * @summary List outdated nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOutdatedNodes: async (authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listOutdatedNodes', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listOutdatedNodes', 'kbId', kbId)
            const localVarPath = `/improve/{kb_id}/nodes/outdated`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (labels !== undefined) {
                localVarQueryParameter['labels'] = labels;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all nodes that have too many queries. 
         * @summary List over represented nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOverRepresentedNodes: async (authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listOverRepresentedNodes', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listOverRepresentedNodes', 'kbId', kbId)
            const localVarPath = `/improve/{kb_id}/nodes/over-represented`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (labels !== undefined) {
                localVarQueryParameter['labels'] = labels;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all nodes with at least 1 query which don\'t have enough queries. 
         * @summary List under represented nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnderRepresentedNodes: async (authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listUnderRepresentedNodes', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('listUnderRepresentedNodes', 'kbId', kbId)
            const localVarPath = `/improve/{kb_id}/nodes/under-represented`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (labels !== undefined) {
                localVarQueryParameter['labels'] = labels;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Temporarely ignore/dismiss queries until they re-appear. 
         * @summary Mutes a set of queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {MuteQueriesParamsBody} body List of queries ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        muteQueries: async (authorization: string, kbId: string, body: MuteQueriesParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('muteQueries', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('muteQueries', 'kbId', kbId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('muteQueries', 'body', body)
            const localVarPath = `/improve/{kb_id}/muted-queries`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Improve and expands the queries of a given node. 
         * @summary Optimize the queries of a node.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optimizeNodeQueries: async (authorization: string, kbId: string, nodeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('optimizeNodeQueries', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('optimizeNodeQueries', 'kbId', kbId)
            // verify required parameter 'nodeId' is not null or undefined
            assertParamExists('optimizeNodeQueries', 'nodeId', nodeId)
            const localVarPath = `/improve/{kb_id}/node/{node_id}/queries`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"node_id"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch a cluster by either approving it or changing its queries. Empty clusters are auto-approved. 
         * @summary Approve cluster assigning or dismissing queries.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {'chattering' | 'response' | 'content'} [suggestNodeType] Filter suggested results by node type
         * @param {PatchClusterRequest} [body] The updated cluster values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCluster: async (authorization: string, kbId: string, id: string, suggestNodeType?: 'chattering' | 'response' | 'content', body?: PatchClusterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('patchCluster', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('patchCluster', 'kbId', kbId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCluster', 'id', id)
            const localVarPath = `/improve/{kb_id}/clusters/{id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (suggestNodeType !== undefined) {
                localVarQueryParameter['suggest_node_type'] = suggestNodeType;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to patch a cluster query by it\'s ID.
         * @summary Patch Cluster query
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster Query ID
         * @param {PatchClusterQueryRequest} [body] The updated cluster query values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClusterQuery: async (authorization: string, kbId: string, id: string, body?: PatchClusterQueryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('patchClusterQuery', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('patchClusterQuery', 'kbId', kbId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchClusterQuery', 'id', id)
            const localVarPath = `/improve/{kb_id}/cluster-query/{id}`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove queries from the ignore/dismiss list. 
         * @summary Unmutes a set of queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {UnmuteQueriesParamsBody} body List of queries ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmuteQueries: async (authorization: string, kbId: string, body: UnmuteQueriesParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('unmuteQueries', 'authorization', authorization)
            // verify required parameter 'kbId' is not null or undefined
            assertParamExists('unmuteQueries', 'kbId', kbId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('unmuteQueries', 'body', body)
            const localVarPath = `/improve/{kb_id}/muted-queries`
                .replace(`{${"kb_id"}}`, encodeURIComponent(String(kbId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeImproveWrite required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImproveApi - functional programming interface
 * @export
 */
export const ImproveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImproveApiAxiosParamCreator(configuration)
    return {
        /**
         * Use this endpoint to delete a improve cluster
         * @summary Delete Cluster
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCluster(authorization: string, kbId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCluster(authorization, kbId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to delete a improve cluster query.
         * @summary Delete Cluster Query
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster Query ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClusterQuery(authorization: string, kbId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClusterQuery(authorization, kbId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get a cluster by id with more details about the card
         * @summary Get Cluster
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {'chattering' | 'response' | 'content'} [suggestNodeType] Filter suggested results by node type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCluster(authorization: string, kbId: string, id: string, suggestNodeType?: 'chattering' | 'response' | 'content', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCluster(authorization, kbId, id, suggestNodeType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get a cluster query by it\'s ID.
         * @summary Get Cluster query
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster Query ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClusterQuery(authorization: string, kbId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClusterQuery(authorization, kbId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all nodes which are either quoting or linking an unpublished node. Ignores documents. 
         * @summary List broken nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBrokenNodes(authorization: string, kbId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBrokenNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBrokenNodes(authorization, kbId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List suggested nodes to be used as responses for the custer. 
         * @summary List cluster nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {number} [limit] 
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClusterNodes(authorization: string, kbId: string, id: string, limit?: number, type?: 'chattering' | 'response' | 'content', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClusterNodes(authorization, kbId, id, limit, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List cluster queries 
         * @summary List cluster queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {string} [clusterId] The clusterID cluster queries belong to.
         * @param {'query_count' | 'created_at' | 'updated_at' | 'query'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClusterQueries(authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, clusterId?: string, sortBy?: 'query_count' | 'created_at' | 'updated_at' | 'query', order?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListClusterQueriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClusterQueries(authorization, kbId, query, limit, cursor, clusterId, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to list all unapproved cards
         * @summary List clusters
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {'query_count' | 'created_at' | 'updated_at' | 'title'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listClusters(authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, sortBy?: 'query_count' | 'created_at' | 'updated_at' | 'title', order?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListClustersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listClusters(authorization, kbId, query, limit, cursor, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List improve queries 
         * @summary List improve queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {string} [clusterId] The clusterID improve queries belong to.
         * @param {string} [clusterQueryId] The clusterQueryID the improve queries belong to.
         * @param {string} [conversationId] The conversationID improve queries belong to.
         * @param {'created_at'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listImproveQueries(authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, clusterId?: string, clusterQueryId?: string, conversationId?: string, sortBy?: 'created_at', order?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListImproveQueriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listImproveQueries(authorization, kbId, query, limit, cursor, clusterId, clusterQueryId, conversationId, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all temporarely muted/ignored queries 
         * @summary List all muted queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {'query' | 'created_at'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMutedQueries(authorization: string, kbId: string, limit?: number, cursor?: string, sortBy?: 'query' | 'created_at', order?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListMutedQueriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMutedQueries(authorization, kbId, limit, cursor, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all nodes which have not been updatd recently. 
         * @summary List outdated nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOutdatedNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOutdatedNodes(authorization, kbId, type, labels, sortBy, order, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all nodes that have too many queries. 
         * @summary List over represented nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOverRepresentedNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOverRepresentedNodes(authorization, kbId, type, labels, sortBy, order, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all nodes with at least 1 query which don\'t have enough queries. 
         * @summary List under represented nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUnderRepresentedNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUnderRepresentedNodes(authorization, kbId, type, labels, sortBy, order, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Temporarely ignore/dismiss queries until they re-appear. 
         * @summary Mutes a set of queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {MuteQueriesParamsBody} body List of queries ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async muteQueries(authorization: string, kbId: string, body: MuteQueriesParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.muteQueries(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Improve and expands the queries of a given node. 
         * @summary Optimize the queries of a node.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optimizeNodeQueries(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OptimizeQueriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optimizeNodeQueries(authorization, kbId, nodeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch a cluster by either approving it or changing its queries. Empty clusters are auto-approved. 
         * @summary Approve cluster assigning or dismissing queries.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {'chattering' | 'response' | 'content'} [suggestNodeType] Filter suggested results by node type
         * @param {PatchClusterRequest} [body] The updated cluster values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCluster(authorization: string, kbId: string, id: string, suggestNodeType?: 'chattering' | 'response' | 'content', body?: PatchClusterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCluster(authorization, kbId, id, suggestNodeType, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to patch a cluster query by it\'s ID.
         * @summary Patch Cluster query
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster Query ID
         * @param {PatchClusterQueryRequest} [body] The updated cluster query values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchClusterQuery(authorization: string, kbId: string, id: string, body?: PatchClusterQueryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchClusterQuery(authorization, kbId, id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove queries from the ignore/dismiss list. 
         * @summary Unmutes a set of queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {UnmuteQueriesParamsBody} body List of queries ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unmuteQueries(authorization: string, kbId: string, body: UnmuteQueriesParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unmuteQueries(authorization, kbId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImproveApi - factory interface
 * @export
 */
export const ImproveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImproveApiFp(configuration)
    return {
        /**
         * Use this endpoint to delete a improve cluster
         * @summary Delete Cluster
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster(authorization: string, kbId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCluster(authorization, kbId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to delete a improve cluster query.
         * @summary Delete Cluster Query
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster Query ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterQuery(authorization: string, kbId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteClusterQuery(authorization, kbId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get a cluster by id with more details about the card
         * @summary Get Cluster
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {'chattering' | 'response' | 'content'} [suggestNodeType] Filter suggested results by node type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCluster(authorization: string, kbId: string, id: string, suggestNodeType?: 'chattering' | 'response' | 'content', options?: any): AxiosPromise<ClusterDetail> {
            return localVarFp.getCluster(authorization, kbId, id, suggestNodeType, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get a cluster query by it\'s ID.
         * @summary Get Cluster query
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster Query ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterQuery(authorization: string, kbId: string, id: string, options?: any): AxiosPromise<ClusterQuery> {
            return localVarFp.getClusterQuery(authorization, kbId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * List all nodes which are either quoting or linking an unpublished node. Ignores documents. 
         * @summary List broken nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBrokenNodes(authorization: string, kbId: string, options?: any): AxiosPromise<ListBrokenNodesResponse> {
            return localVarFp.listBrokenNodes(authorization, kbId, options).then((request) => request(axios, basePath));
        },
        /**
         * List suggested nodes to be used as responses for the custer. 
         * @summary List cluster nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {number} [limit] 
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusterNodes(authorization: string, kbId: string, id: string, limit?: number, type?: 'chattering' | 'response' | 'content', options?: any): AxiosPromise<ListNodesResponse> {
            return localVarFp.listClusterNodes(authorization, kbId, id, limit, type, options).then((request) => request(axios, basePath));
        },
        /**
         * List cluster queries 
         * @summary List cluster queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {string} [clusterId] The clusterID cluster queries belong to.
         * @param {'query_count' | 'created_at' | 'updated_at' | 'query'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusterQueries(authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, clusterId?: string, sortBy?: 'query_count' | 'created_at' | 'updated_at' | 'query', order?: 'asc' | 'desc', options?: any): AxiosPromise<ListClusterQueriesResponse> {
            return localVarFp.listClusterQueries(authorization, kbId, query, limit, cursor, clusterId, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to list all unapproved cards
         * @summary List clusters
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {'query_count' | 'created_at' | 'updated_at' | 'title'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, sortBy?: 'query_count' | 'created_at' | 'updated_at' | 'title', order?: 'asc' | 'desc', options?: any): AxiosPromise<ListClustersResponse> {
            return localVarFp.listClusters(authorization, kbId, query, limit, cursor, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * List improve queries 
         * @summary List improve queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {string} [clusterId] The clusterID improve queries belong to.
         * @param {string} [clusterQueryId] The clusterQueryID the improve queries belong to.
         * @param {string} [conversationId] The conversationID improve queries belong to.
         * @param {'created_at'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listImproveQueries(authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, clusterId?: string, clusterQueryId?: string, conversationId?: string, sortBy?: 'created_at', order?: 'asc' | 'desc', options?: any): AxiosPromise<ListImproveQueriesResponse> {
            return localVarFp.listImproveQueries(authorization, kbId, query, limit, cursor, clusterId, clusterQueryId, conversationId, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * List all temporarely muted/ignored queries 
         * @summary List all muted queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {number} [limit] 
         * @param {string} [cursor] The query cursor
         * @param {'query' | 'created_at'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMutedQueries(authorization: string, kbId: string, limit?: number, cursor?: string, sortBy?: 'query' | 'created_at', order?: 'asc' | 'desc', options?: any): AxiosPromise<ListMutedQueriesResponse> {
            return localVarFp.listMutedQueries(authorization, kbId, limit, cursor, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * List all nodes which have not been updatd recently. 
         * @summary List outdated nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOutdatedNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: any): AxiosPromise<ListNodesResponse> {
            return localVarFp.listOutdatedNodes(authorization, kbId, type, labels, sortBy, order, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * List all nodes that have too many queries. 
         * @summary List over represented nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOverRepresentedNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: any): AxiosPromise<ListNodesResponse> {
            return localVarFp.listOverRepresentedNodes(authorization, kbId, type, labels, sortBy, order, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * List all nodes with at least 1 query which don\'t have enough queries. 
         * @summary List under represented nodes
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
         * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
         * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {number} [limit] 
         * @param {number} [offset] The query offset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUnderRepresentedNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: any): AxiosPromise<ListNodesResponse> {
            return localVarFp.listUnderRepresentedNodes(authorization, kbId, type, labels, sortBy, order, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * Temporarely ignore/dismiss queries until they re-appear. 
         * @summary Mutes a set of queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {MuteQueriesParamsBody} body List of queries ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        muteQueries(authorization: string, kbId: string, body: MuteQueriesParamsBody, options?: any): AxiosPromise<void> {
            return localVarFp.muteQueries(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Improve and expands the queries of a given node. 
         * @summary Optimize the queries of a node.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} nodeId Node ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optimizeNodeQueries(authorization: string, kbId: string, nodeId: string, options?: any): AxiosPromise<OptimizeQueriesResponse> {
            return localVarFp.optimizeNodeQueries(authorization, kbId, nodeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch a cluster by either approving it or changing its queries. Empty clusters are auto-approved. 
         * @summary Approve cluster assigning or dismissing queries.
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster ID
         * @param {'chattering' | 'response' | 'content'} [suggestNodeType] Filter suggested results by node type
         * @param {PatchClusterRequest} [body] The updated cluster values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCluster(authorization: string, kbId: string, id: string, suggestNodeType?: 'chattering' | 'response' | 'content', body?: PatchClusterRequest, options?: any): AxiosPromise<ClusterDetail> {
            return localVarFp.patchCluster(authorization, kbId, id, suggestNodeType, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to patch a cluster query by it\'s ID.
         * @summary Patch Cluster query
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {string} id Cluster Query ID
         * @param {PatchClusterQueryRequest} [body] The updated cluster query values.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClusterQuery(authorization: string, kbId: string, id: string, body?: PatchClusterQueryRequest, options?: any): AxiosPromise<ClusterQuery> {
            return localVarFp.patchClusterQuery(authorization, kbId, id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove queries from the ignore/dismiss list. 
         * @summary Unmutes a set of queries
         * @param {string} authorization Authorization Bearer header
         * @param {string} kbId Knowledgebase ID
         * @param {UnmuteQueriesParamsBody} body List of queries ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmuteQueries(authorization: string, kbId: string, body: UnmuteQueriesParamsBody, options?: any): AxiosPromise<void> {
            return localVarFp.unmuteQueries(authorization, kbId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImproveApi - object-oriented interface
 * @export
 * @class ImproveApi
 * @extends {BaseAPI}
 */
export class ImproveApi extends BaseAPI {
    /**
     * Use this endpoint to delete a improve cluster
     * @summary Delete Cluster
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} id Cluster ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public deleteCluster(authorization: string, kbId: string, id: string, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).deleteCluster(authorization, kbId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to delete a improve cluster query.
     * @summary Delete Cluster Query
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} id Cluster Query ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public deleteClusterQuery(authorization: string, kbId: string, id: string, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).deleteClusterQuery(authorization, kbId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get a cluster by id with more details about the card
     * @summary Get Cluster
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} id Cluster ID
     * @param {'chattering' | 'response' | 'content'} [suggestNodeType] Filter suggested results by node type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public getCluster(authorization: string, kbId: string, id: string, suggestNodeType?: 'chattering' | 'response' | 'content', options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).getCluster(authorization, kbId, id, suggestNodeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get a cluster query by it\'s ID.
     * @summary Get Cluster query
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} id Cluster Query ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public getClusterQuery(authorization: string, kbId: string, id: string, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).getClusterQuery(authorization, kbId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all nodes which are either quoting or linking an unpublished node. Ignores documents. 
     * @summary List broken nodes
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public listBrokenNodes(authorization: string, kbId: string, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).listBrokenNodes(authorization, kbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List suggested nodes to be used as responses for the custer. 
     * @summary List cluster nodes
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} id Cluster ID
     * @param {number} [limit] 
     * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public listClusterNodes(authorization: string, kbId: string, id: string, limit?: number, type?: 'chattering' | 'response' | 'content', options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).listClusterNodes(authorization, kbId, id, limit, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List cluster queries 
     * @summary List cluster queries
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} [query] 
     * @param {number} [limit] 
     * @param {string} [cursor] The query cursor
     * @param {string} [clusterId] The clusterID cluster queries belong to.
     * @param {'query_count' | 'created_at' | 'updated_at' | 'query'} [sortBy] Sort content by field
     * @param {'asc' | 'desc'} [order] Sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public listClusterQueries(authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, clusterId?: string, sortBy?: 'query_count' | 'created_at' | 'updated_at' | 'query', order?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).listClusterQueries(authorization, kbId, query, limit, cursor, clusterId, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to list all unapproved cards
     * @summary List clusters
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} [query] 
     * @param {number} [limit] 
     * @param {string} [cursor] The query cursor
     * @param {'query_count' | 'created_at' | 'updated_at' | 'title'} [sortBy] Sort content by field
     * @param {'asc' | 'desc'} [order] Sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public listClusters(authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, sortBy?: 'query_count' | 'created_at' | 'updated_at' | 'title', order?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).listClusters(authorization, kbId, query, limit, cursor, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List improve queries 
     * @summary List improve queries
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} [query] 
     * @param {number} [limit] 
     * @param {string} [cursor] The query cursor
     * @param {string} [clusterId] The clusterID improve queries belong to.
     * @param {string} [clusterQueryId] The clusterQueryID the improve queries belong to.
     * @param {string} [conversationId] The conversationID improve queries belong to.
     * @param {'created_at'} [sortBy] Sort content by field
     * @param {'asc' | 'desc'} [order] Sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public listImproveQueries(authorization: string, kbId: string, query?: string, limit?: number, cursor?: string, clusterId?: string, clusterQueryId?: string, conversationId?: string, sortBy?: 'created_at', order?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).listImproveQueries(authorization, kbId, query, limit, cursor, clusterId, clusterQueryId, conversationId, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all temporarely muted/ignored queries 
     * @summary List all muted queries
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {number} [limit] 
     * @param {string} [cursor] The query cursor
     * @param {'query' | 'created_at'} [sortBy] Sort content by field
     * @param {'asc' | 'desc'} [order] Sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public listMutedQueries(authorization: string, kbId: string, limit?: number, cursor?: string, sortBy?: 'query' | 'created_at', order?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).listMutedQueries(authorization, kbId, limit, cursor, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all nodes which have not been updatd recently. 
     * @summary List outdated nodes
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
     * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
     * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
     * @param {'asc' | 'desc'} [order] Sort order
     * @param {number} [limit] 
     * @param {number} [offset] The query offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public listOutdatedNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).listOutdatedNodes(authorization, kbId, type, labels, sortBy, order, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all nodes that have too many queries. 
     * @summary List over represented nodes
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
     * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
     * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
     * @param {'asc' | 'desc'} [order] Sort order
     * @param {number} [limit] 
     * @param {number} [offset] The query offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public listOverRepresentedNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).listOverRepresentedNodes(authorization, kbId, type, labels, sortBy, order, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all nodes with at least 1 query which don\'t have enough queries. 
     * @summary List under represented nodes
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {'chattering' | 'response' | 'content'} [type] Filter by node type
     * @param {string} [labels] Filter content by labels. Multiple labels are operated as a logical AND.
     * @param {'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count'} [sortBy] Sort content by field
     * @param {'asc' | 'desc'} [order] Sort order
     * @param {number} [limit] 
     * @param {number} [offset] The query offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public listUnderRepresentedNodes(authorization: string, kbId: string, type?: 'chattering' | 'response' | 'content', labels?: string, sortBy?: 'source_name' | 'title' | 'node_type' | 'created_at' | 'updated_at' | 'queries_count', order?: 'asc' | 'desc', limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).listUnderRepresentedNodes(authorization, kbId, type, labels, sortBy, order, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Temporarely ignore/dismiss queries until they re-appear. 
     * @summary Mutes a set of queries
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {MuteQueriesParamsBody} body List of queries ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public muteQueries(authorization: string, kbId: string, body: MuteQueriesParamsBody, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).muteQueries(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Improve and expands the queries of a given node. 
     * @summary Optimize the queries of a node.
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} nodeId Node ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public optimizeNodeQueries(authorization: string, kbId: string, nodeId: string, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).optimizeNodeQueries(authorization, kbId, nodeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch a cluster by either approving it or changing its queries. Empty clusters are auto-approved. 
     * @summary Approve cluster assigning or dismissing queries.
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} id Cluster ID
     * @param {'chattering' | 'response' | 'content'} [suggestNodeType] Filter suggested results by node type
     * @param {PatchClusterRequest} [body] The updated cluster values.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public patchCluster(authorization: string, kbId: string, id: string, suggestNodeType?: 'chattering' | 'response' | 'content', body?: PatchClusterRequest, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).patchCluster(authorization, kbId, id, suggestNodeType, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to patch a cluster query by it\'s ID.
     * @summary Patch Cluster query
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {string} id Cluster Query ID
     * @param {PatchClusterQueryRequest} [body] The updated cluster query values.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public patchClusterQuery(authorization: string, kbId: string, id: string, body?: PatchClusterQueryRequest, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).patchClusterQuery(authorization, kbId, id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove queries from the ignore/dismiss list. 
     * @summary Unmutes a set of queries
     * @param {string} authorization Authorization Bearer header
     * @param {string} kbId Knowledgebase ID
     * @param {UnmuteQueriesParamsBody} body List of queries ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImproveApi
     */
    public unmuteQueries(authorization: string, kbId: string, body: UnmuteQueriesParamsBody, options?: AxiosRequestConfig) {
        return ImproveApiFp(this.configuration).unmuteQueries(authorization, kbId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
