<template>
    <drawer-template
        @drawer-template-close="close"
        content-class="d-p16 d-of-y-auto d-box-border d-fl-grow1 d-bgc-secondary"
    >
        <template #header>
            <div class="d-d-flex d-ai-center d-w100p">
                <div class="d-d-flex d-fd-column d-w90p">
                    <dt-skeleton
                        v-if="isFetching"
                        :arial-label="$t('Loading')"
                        :paragraphOption="{
                            rows: 1,
                            width: ['80%'],
                            rowClass: 'd-h12 d-mt12 d-mb8'
                        }"
                    />
                    <div class="d-d-flex d-ai-center d-fw-wrap" v-else>
                        {{ responseData?.title }}
                    </div>
                    <div
                        class="d-d-flex d-ai-center d-pt4"
                        v-if="isFetching || responseData.labels.length"
                    >
                        <div class="d-w100p" v-if="isFetching">
                            <dt-skeleton
                                :arial-label="$t('Loading')"
                                :paragraphOption="{
                                    rows: 1,
                                    width: ['80%'],
                                    rowClass: 'd-h24'
                                }"
                            />
                        </div>
                        <div class="d-w100p" v-else>
                            <div class="d-w100p">
                                <div v-if="responseData.labels.length">
                                    <dt-chip
                                        :interactive="false"
                                        class="d-mr4 d-mb2 d-mt2"
                                        content-class="d-fs-100"
                                        v-for="label in responseData.labels"
                                        :key="label"
                                        hide-close
                                        size="xs"
                                    >
                                        {{ label }}
                                    </dt-chip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-ml-auto d-mr2">
                    <dt-button
                        kind="muted"
                        importance="clear"
                        @click="handleEditResponse"
                    >
                        {{ $t('Edit') }}
                    </dt-button>
                </div>
            </div>
        </template>
        <template #default>
            <dt-skeleton
                v-if="isFetching"
                :arial-label="$t('Loading')"
                :paragraphOption="{
                    rows: 10,
                    rowClass: 'd-h16'
                }"
            />
            <response-block-renderer
                v-if="!isFetching"
                :raw-blocks="responseData.response?.blocks"
                view-mode
                is-drawer
            />
        </template>
    </drawer-template>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import {
    DtButton,
    DtLink,
    DtIcon,
    DtChip,
    DtSkeleton
} from '@dialpad/dialtone/vue3';

import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import RichTextEditor from '@/components/rich-text-editor/RichTextEditor.vue';
import ResponseBlockRenderer from '@/components/node-editor/ResponseBlockRenderer.vue';

import type { ApiService } from '@/services/Api.service';
import { handleRequest } from '@/utils/Common';
import { type Knowledgebase, NodeType } from '@/open-api';

export default defineComponent({
    props: {
        responseId: {
            type: String as PropType<string>,
            required: true
        }
    },
    components: {
        DtButton,
        DtLink,
        DtIcon,
        DtChip,
        DtSkeleton,
        DrawerTemplate,
        RichTextEditor,
        ResponseBlockRenderer
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    async mounted() {
        await this.fetchResponse();
    },
    watch: {
        responseId(newId) {
            if (!newId) return;
            this.fetchResponse();
        }
    },
    computed: {
        /* v8 ignore next 12 */
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        apiService(): ApiService | undefined {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async fetchResponse() {
            this.isFetching = true;
            const res = await handleRequest<string>(
                this.apiService?.knowledge.getNode(
                    this.authToken,
                    this.knowledgebase?.id,
                    this.responseId
                ),
                this.orgId
            );
            this.isFetching = false;
            if (res.data) {
                this.responseData = res.data;
            }
        },
        handleEditResponse() {
            this.$router.push({
                name: 'node_editor',
                params: {
                    nodeId: this.responseData!.id
                },
                query: {
                    nodeType: NodeType.Response,
                    tab: 'responses'
                }
            });
        }
    },
    data() {
        return {
            isFetching: true,
            responseData: undefined
        };
    }
});
</script>
