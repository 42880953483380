<template>
    <div class="d-d-flex d-h100p">
        <div class="d-fl-grow1 d-h100p">
            <div class="d-m24 d-h100p">
                <div class="d-d-flex d-ai-center d-h32 d-mb48">
                    <h1 v-if="isFetching" class="d-w50p">
                        <dt-skeleton
                            arial-label="Loading"
                            :paragraphOption="{ rows: 1 }"
                        />
                    </h1>
                    <template v-else>
                        <h1 class="d-gc12 d-fc-black-800 d-fw-medium">
                            {{ automations.length }}
                            {{ $t('Automations') }}
                        </h1>
                    </template>
                </div>
                <template v-if="!isFetching && rawData.length">
                    <div class="d-d-flex d-ai-center d-mb16">
                        <dt-input
                            class="d-w332"
                            v-model="search"
                            :placeholder="$t('Search automations')"
                            icon-size="md"
                            size="sm"
                            @input="debounce(handleAutomationsSearch)"
                        >
                            <template #leftIcon>
                                <dt-icon name="search" size="200" />
                            </template>
                            <template #rightIcon>
                                <dt-button
                                    v-if="search"
                                    kind="muted"
                                    importance="clear"
                                    size="xs"
                                    circle
                                    aria-label="Clear search"
                                    @click="handleClearInput"
                                >
                                    <template #icon>
                                        <dt-icon name="close" size="200" />
                                    </template>
                                </dt-button>
                            </template>
                        </dt-input>
                    </div>
                    <div class="d-d-flex d-h100p d-w100p" style="flex: 1 1">
                        <base-table
                            :is-fetching="isFetching"
                            id="AutomationsTable"
                            :columns="columnsByState"
                            :data="automations"
                            :has-error="hasError"
                            @row-click="onRowClick"
                            hide-checkbox
                            item-type="automation"
                            :has-active-filters="hasFilters"
                        />
                    </div>
                </template>
                <div
                    class="d-jc-center d-d-flex d-ai-center d-fd-column d-mt128"
                    v-if="!isFetching && !rawData.length"
                >
                    <h3 class="d-fs-300 d-mb8">
                        {{ $t('No automations found') }}
                    </h3>
                    <p class="d-mb24">
                        {{
                            $t(
                                'Please set up an environment to sync automations'
                            )
                        }}
                    </p>
                    <dt-button @click="handleContactUs">
                        {{ $t('Contact us to add automations') }}
                    </dt-button>
                </div>
            </div>
        </div>
    </div>
    <dt-modal
        content-class="d-h100p"
        :copy="selectedAutomation.description"
        :title="selectedAutomation.title"
        :close-button-props="{ ariaLabel: `${$t('Close the dialog')}` }"
        v-model:show="showModal"
        :key="selectedAutomation.title"
    >
        <iframe
            height="600"
            :src="selectedWebExtUrl"
            width="100%"
            class="d-ba d-baw0"
        />
    </dt-modal>
</template>

<script lang="ts">
import {
    DtIcon,
    DtInput,
    DtSkeleton,
    DtModal,
    DtButton
} from '@dialpad/dialtone/vue3';
import { defineComponent, inject } from 'vue';
import BaseTable from '@/components/base-table/BaseTable.vue';
import type { IBaseTableColumn } from '@/components/base-table/BaseTable.types';
import { createDebounce, handleRequest } from '@/utils/Common';
import type { App, Widget } from '@/open-api';
import type { WebExtensionDetail } from '@/open-api';
import type { ApiService } from '@/services/Api.service';

export default defineComponent({
    components: {
        DtInput,
        DtIcon,
        DtSkeleton,
        DtButton,
        DtModal,
        BaseTable
    },
    computed: {
        hasFilters(): boolean {
            return !!this.search.length;
        },
        columnsByState(): IBaseTableColumn<Widget>[] {
            return this.columns;
        },
        selectedWebExtUrl(): string {
            return this.getWebExtUrl();
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        }
    },
    mounted() {
        this.fetchAutomations();
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            debounce: createDebounce(),
            orgId
        };
    },
    methods: {
        async fetchAutomations() {
            this.isFetching = true;
            this.rawData = [];
            const res = await handleRequest<App[]>(
                this.apiService?.knowledge.listWebExt(
                    this.$store.getters[`${this.orgId}/authToken`]
                ),
                this.orgId
            );
            this.isFetching = false;
            this.hasError = !!res.error;

            if (res?.data) {
                this.rawData = res.data;
                this.automations = res.data;
            }
        },
        onRowClick(rowData: any) {
            this.isLoading = true;
            this.selectedAutomation = rowData?.data;
            this.showModal = true;
        },
        getWebExtUrl() {
            const url = new URL(
                `${
                    this.$store.getters[`${this.orgId}/currentOrg`]
                        .webext_hostname
                }${this.selectedAutomation.url}`
            );

            // No need to pass token for now
            //url.searchParams.set('token', this.$store.getters.authToken);
            return url.toString();
        },
        handleAutomationsSearch() {
            if (this.search) {
                this.automations = this.rawData.filter(
                    (automation: WebExtensionDetail) =>
                        automation.title
                            ?.toLocaleLowerCase()
                            .includes(this.search.toLocaleLowerCase()) ||
                        automation.description
                            ?.toLocaleLowerCase()
                            .includes(this.search.toLocaleLowerCase())
                );
            } else {
                this.automations = this.rawData;
            }
        },
        handleClearInput() {
            this.search = '';
            this.automations = this.rawData;
        },
        handleContactUs() {
            //TODO
        }
    },
    data() {
        return {
            rowData: undefined as Widget | undefined,
            search: '',
            columns: [
                {
                    key: 'title',
                    type: 'string',
                    title: 'Title',
                    sort: 'title'
                },
                {
                    key: 'description',
                    type: 'string',
                    title: 'Description',
                    sort: 'description'
                }
            ] as IBaseTableColumn<Widget>[],
            isFetching: false,
            hasError: false,
            rawData: [] as WebExtensionDetail[],
            automations: [] as WebExtensionDetail[],
            showModal: false,
            selectedAutomation: {} as WebExtensionDetail,
            isLoading: false
        };
    }
});
</script>
