<template>
    <div
        class="d-d-flex d-jc-space-between d-mt16 d-ai-center d-p16 d-ba d-bc-subtle d-btr8"
    >
        <div class="d-headline--sm">
            {{ title }}
        </div>
        <slot name="header" v-if="$slots.header" />
    </div>
    <ul class="d-bl d-br d-bb d-bc-subtle d-bbr8">
        <li
            v-for="(node, i) in nodes"
            :key="node.id"
            @mouseenter="hoverCard(`${node.id}-${i}`)"
            @mouseleave="hideHoverCard"
            class="d-bb d-bc-subtle d-t d-td300"
            :class="{
                'd-bbw0': i === nodes.length - 1
            }"
        >
            <optimize-card
                :title="node.title"
                :labels="node.labels"
                :is-hover="`${node.id}-${i}` === actionCard"
                @click="handleOptimizeCardClick(node)"
                :class="{
                    'd-bbr8': i === nodes.length - 1
                }"
            />
        </li>
    </ul>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

import { DtIcon, DtCard } from '@dialpad/dialtone/vue3';
import LabelTag from '@/components/label-tag/LabelTag.vue';
import OptimizeCard from '@/components/node-editor/OptimizeCard.vue';

export default defineComponent({
    props: {
        title: {
            type: String as PropType<string>,
            required: true
        },
        nodes: {
            type: Array as PropType<any[]>
        },
        isHover: {
            type: Boolean as PropType<boolean>
        }
    },
    components: {
        OptimizeCard,
        LabelTag,
        DtIcon,
        DtCard
    },
    methods: {
        hoverCard(i: string) {
            this.actionCard = i;
        },
        hideHoverCard() {
            this.actionCard = undefined;
        },
        handleOptimizeCardClick(node: any) {
            this.$emit('nodeClick', node);
        }
    },
    emits: ['nodeClick'],
    data() {
        return {
            actionCard: undefined as undefined | string
        };
    }
});
</script>
