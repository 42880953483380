<template>
    <dt-card
        class="d-w100p"
        header-class="d-px16 d-py4 d-bgc-secondary d-bb d-bc-subtle d-btr4"
        content-class="d-px12 d-py8"
        :container-class="{
            'd-bs-sm': editable,
            'd-bs-none h:d-bs-sm': !editable
        }"
    >
        <template #header v-if="editable">
            <span
                class="d-fs-100 d-fw-bold d-h16 d-py4 d-my2 d-d-flex d-ai-center"
            >
                {{ $t('Add choice') }}
            </span>
        </template>
        <template #content>
            <div v-if="isEditable" class="d-d-flex d-fw-wrap d-w100p">
                <div class="d-w100p d-pb12 d-px8">
                    <dt-input
                        size="xs"
                        type="text"
                        :label="$t('Prompt text')"
                        v-model="promptText"
                    />
                </div>
                <template
                    v-for="(choice, index) in choices"
                    :key="`choice-${index}`"
                >
                    <div
                        class="d-d-flex d-w100p d-px8"
                        :class="{
                            'd-c-grab': !dragging,
                            'd-c-grabbing': dragging
                        }"
                        :draggable="true"
                        @dragstart="handleDragStart($event, index)"
                        @dragend.prevent="handleDragEnd"
                        @dragover.prevent="handleDragHover(index)"
                        @drop.prevent="handleDrop(index, uid)"
                    >
                        <div
                            class="d-d-flex d-fw-wrap d-w100p d-py12 d-bt d-btw1 d-bc-subtle"
                            :class="{
                                'd-bs-card':
                                    draggedPosition !== index &&
                                    positionHover === index
                            }"
                        >
                            <div class="d-w100p d-d-flex">
                                <div
                                    class="d-d-flex d-ai-flex-end d-jc-flex-end"
                                >
                                    <dt-button
                                        size="xs"
                                        importance="clear"
                                        kind="muted"
                                    >
                                        <template #icon>
                                            <dt-icon
                                                size="200"
                                                name="grip-vertical"
                                            />
                                        </template>
                                    </dt-button>
                                </div>
                                <div class="d-w100p d-ml4">
                                    <div class="d-label-small d-mb4">
                                        {{ $t('Choice') + ` #${index + 1}` }}
                                    </div>
                                    <filter-popover
                                        :key="`choice-${choice.uid}`"
                                        id="`choice-choice.node`"
                                        :fetch="
                                            (e: Event) =>
                                                handleChoiceChange(e, choice)
                                        "
                                        @search="handleSearch"
                                        @open="handleOpen"
                                        :selected-options="[choice?.node || '']"
                                        :options="responsesOptions"
                                        append-to="body"
                                        dialog-style=""
                                        size-style="xs"
                                        size-icon="200"
                                        hide-checkboxes
                                        is-normal-select-style
                                        close-on-select
                                        :is-disabled="isFetching"
                                        :aria-label="`choice-select-${choice.node}-${index}`"
                                        with-search
                                        search-placeholder="Search responses"
                                        title="responses"
                                        :raw-selected-option="choice"
                                        :search-should-filter="false"
                                        :show-search-validation-messages="
                                            !canSearch
                                        "
                                        :search-validation-messages="[
                                            $t(
                                                'Search should be at least 4 characters'
                                            )
                                        ]"
                                    />
                                </div>
                                <div
                                    class="d-d-flex d-ai-flex-end d-jc-flex-end d-ml4"
                                    v-if="choices.length > 1"
                                >
                                    <dt-button
                                        size="xs"
                                        importance="clear"
                                        kind="muted"
                                        @click="removeChoice(choice)"
                                    >
                                        <template #icon>
                                            <dt-icon size="200" name="close" />
                                        </template>
                                    </dt-button>
                                </div>
                            </div>
                            <div class="d-w100p d-ml32 d-pt12">
                                <dt-input
                                    size="xs"
                                    type="text"
                                    :label="
                                        $t('Display title') +
                                        ' (' +
                                        $t('Optional') +
                                        ')'
                                    "
                                    v-model="choice.text"
                                />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="d-d-flex d-fw-wrap d-px4" v-else>
                <span class="d-w100p d-mb8 d-fs-200" v-if="promptText">
                    {{ promptText }}
                </span>
                <ul class="d-w100p">
                    <li
                        v-for="(choice, index) in choices"
                        :key="`choice-${choice.uid}`"
                        class="d-py4"
                        :class="{
                            'd-bb d-bbw1 d-bc-subtle d-mb8':
                                choices.length > 1 && !isLast(index)
                        }"
                    >
                        <dt-button
                            link
                            class="d-td-none"
                            :class="{ 'd-fc-warning': choice.broken }"
                        >
                            {{ choice.text || choice.title }}
                        </dt-button>
                        <div
                            v-if="choice.broken"
                            class="d-fs-100 d-d-flex d-ai-center d-fc-warning"
                        >
                            <dt-icon name="alert-triangle" size="200" />
                            <span class="d-ml4">
                                {{ `${$t('Response')} ${$t('archived')}` }}
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </template>
        <template #footer v-if="editable">
            <div
                class="d-d-flex d-jc-space-between d-w100p d-bt d-btw1 d-bc-subtle d-pt12"
            >
                <dt-button class="d-fs-100" link @click="addChoice">
                    {{ $t('Add new choice') }}
                </dt-button>
                <div class="d-d-flex d-jc-flex-end d-gg8">
                    <dt-button
                        importance="clear"
                        @click="handleCancel"
                        size="xs"
                    >
                        {{ $t('Cancel') }}
                    </dt-button>
                    <dt-button
                        @click="handleSave"
                        size="xs"
                        :disabled="!shouldSave"
                    >
                        {{ $t('Add') }}
                    </dt-button>
                </div>
            </div>
        </template>
    </dt-card>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import type { Block } from '@/components/node-editor/responseBlockEditor';
import {
    DtButton,
    DtCard,
    DtIcon,
    DtInput,
    DtSelectMenu
} from '@dialpad/dialtone/vue3';
import type { ApiService } from '@/services/Api.service';
import type { ResponseBlockEditor } from '@/components/node-editor/responseBlockEditor';
import type {
    NodeDetail,
    ResponseNodeBlockChoicesBlockChoicesItems
} from '@/open-api';
import { createDebounce, handleRequest, IFilter, uuidv4 } from '@/utils/Common';
import {
    type Knowledgebase,
    type ListNodesResponse,
    NodeStatus
} from '@/open-api';
import { NODE_TYPES } from '@/utils/types/Response';
import { EMPTY_CHOICE } from '@/components/node-editor/responseBlockEditor';
import FilterPopover from '@/components/filter-popover/FilterPopover.vue';
import { useRoute } from 'vue-router';

export interface IChoice extends ResponseNodeBlockChoicesBlockChoicesItems {
    uid: string;
}

export default defineComponent({
    setup() {
        const orgId: string = inject('orgId')!;
        const $route = useRoute();
        const currentNodeId: string = $route.params.nodeId?.toString();

        return {
            orgId,
            debounce: createDebounce(),
            currentNodeId
        };
    },
    mounted() {
        this.fetchResponses();
    },
    props: {
        block: {
            type: Object as PropType<Block>,
            required: true
        },
        editable: {
            type: Boolean as PropType<boolean>,
            required: true
        }
    },
    components: {
        FilterPopover,
        DtButton,
        DtCard,
        DtIcon,
        DtInput,
        DtSelectMenu
    },
    computed: {
        /* v8 ignore next 22 */
        isEditable: {
            get(): boolean {
                return this.editable;
            },
            set(editable: boolean) {
                this.$emit('edit', editable);
            }
        },
        shouldSave(): boolean {
            return !this.choices.some(
                (choice: IChoice) => !choice.node?.length
            );
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        responseBlockEditor(): ResponseBlockEditor | undefined {
            return this.$store.getters[`${this.orgId}/responseBlockEditor`];
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        responsesOptions(): IFilter[] {
            return this.responses
                ?.filter(
                    (response: NodeDetail) => response.id !== this.currentNodeId
                )
                ?.map((response: NodeDetail) => ({
                    label: response.title,
                    value: response.id
                }));
        }
    },
    methods: {
        /* v8 ignore next 120 */
        addChoice() {
            this.choices.push(EMPTY_CHOICE());
        },
        removeChoice(choice: IChoice) {
            this.choices = this.choices.filter((c) => c.uid !== choice.uid);
        },
        isLast(index: number) {
            return this.choices.length === index + 1;
        },
        handleCancel(e: Event) {
            e.stopPropagation();
            // If block has never been added then it will remove it.
            // If was added, even if it was temporary, it won't be removed.
            if (this.block.new && !this.block.data.dirty) {
                this.resetData();
                this.handleRemove();
            } else {
                this.resetData();
                this.isEditable = false;
            }
        },
        handleSearch(searchText: string) {
            this.canSearch = searchText?.length >= 4;
            this.debounce(() => {
                this.fetchResponses(this.canSearch ? searchText : undefined);
            }, 300);
        },
        handleOpen() {
            this.handleSearch();
        },
        handleSave(e: any) {
            e.stopPropagation();
            /* v8 ignore next 4 */
            this.block?.setBlockData({
                text: this.promptText,
                choices: this.choices
            });
            this.isEditable = false;
        },
        handleRemove() {
            this.responseBlockEditor?.removeBlock(this.block);
        },
        handleChoiceChange(nodeId: any, choice: IChoice) {
            if (choice) {
                choice.node = nodeId;
                const response = this.responses.find(
                    (response: NodeDetail) => response.id === nodeId
                );
                if (response && response.title) {
                    choice.text = response.title;
                }
            }
        },
        async fetchResponses(searchText?: string) {
            this.isFetching = true;

            const res = await handleRequest<ListNodesResponse>(
                this.apiService.knowledge.listNodes(
                    this.authToken,
                    this.knowledgebase?.id!,
                    NODE_TYPES.RESPONSE,
                    NodeStatus.Published,
                    undefined,
                    searchText
                ),
                this.orgId
            );
            this.isFetching = false;

            if (res?.data) {
                this.responses = res.data.nodes || [];
            }
        },
        resetData() {
            this.promptText = this.block?.data.choices_block?.text || '';
            this.choices =
                !this.block.dirty && this.block?.data.choices_block?.choices
                    ? (this.block?.data.choices_block?.choices.map((c) => ({
                          ...c,
                          uid: uuidv4()
                      })) as IChoice[])
                    : [EMPTY_CHOICE()];
        },
        handleDragStart(e: DragEvent, position?: number) {
            if (e.dataTransfer) {
                e.dataTransfer.setData('text/plain', '');
                e.dataTransfer!.effectAllowed = 'move';
            }

            this.dragging = true;
            this.draggedPosition = position;
        },
        handleDragEnd() {
            document.documentElement.style.cursor = 'default';
            this.dragging = false;
            this.draggedPosition = undefined;
            this.positionHover = undefined;
        },
        handleDrop(position: number, uid: string) {
            if (
                this.draggedPosition === undefined ||
                position === this.draggedPosition ||
                this.uid !== uid
            )
                return;

            this.moveChoice(this.choices[this.draggedPosition], position);
        },
        findChoiceIndex(choiceToFind: IChoice): number {
            return this.choices.findIndex(
                (choice: IChoice) => choice.uid == choiceToFind.uid
            );
        },
        moveChoice(choiceToMove: IChoice, to: number) {
            const index = this.findChoiceIndex(choiceToMove);
            if (index === -1) return;
            this.choices.splice(to, 0, this.choices.splice(index, 1)[0]);
        },
        handleDragHover(position: number) {
            document.documentElement.style.cursor = 'grabbing';
            this.positionHover = position;
        }
    },
    data() {
        return {
            isFetching: false,
            responses: [] as NodeDetail[],
            promptText: this.block?.data.choices_block?.text || '',
            choices: [
                ...(this.block?.data.choices_block?.choices.map((c) => ({
                    ...c,
                    uid: uuidv4()
                })) || [EMPTY_CHOICE()])
            ] as IChoice[],
            dragging: false,
            draggedPosition: undefined as number | undefined,
            uid: uuidv4(),
            positionHover: undefined as undefined | number,
            canSearch: true
        };
    }
});
</script>
