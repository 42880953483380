<template>
    <Transition name="slide" v-bind="$attrs">
        <drawer-template
            v-if="showDocument360ConnectPage"
            @drawer-template-close="handleClose"
            @back="handleBackClick({})"
            is-secondary-page
        >
            <template #header>
                <h3 class="d-d-flex d-jc-flex-start d-py8 d-my2 d-ml32 d-pl8">
                    {{
                        `${isConnected ? $t('Configure') : $t('Connect')}
                            Document360
                        `
                    }}
                </h3>
            </template>
            <template #default>
                <div class="d-mb16">
                    <dt-input
                        :label="$t('APIs base url')"
                        v-model="apiBaseUrl"
                        :disabled="isConnected"
                        name="api-base-url-input"
                    />
                </div>
                <div class="d-mb16">
                    <dt-input
                        :label="$t('Domain')"
                        v-model="domain"
                        :disabled="isConnected"
                        name="domain-input"
                    />
                </div>
                <div class="d-mb16">
                    <dt-input
                        :label="$t('API key')"
                        v-model="apiKey"
                        name="api-key-input"
                    />
                </div>
                <div class="d-mb16">
                    <dt-input
                        :label="$t('Project version')"
                        v-model="projectVersion"
                        name="project-version-input"
                    />
                </div>
            </template>
            <template #footer>
                <div class="d-d-flex d-py16">
                    <dt-button
                        class="d-mr16"
                        importance="clear"
                        @click="handleBackClick({})"
                        :disabled="isRequesting"
                    >
                        {{ $t('Cancel') }}
                    </dt-button>
                    <dt-button
                        v-if="!isConnected"
                        @click="handleConnectDocument360"
                        :disabled="isRequesting || !(apiKey && projectVersion)"
                    >
                        {{ $t('Connect') }}
                    </dt-button>
                    <dt-button
                        class="d-mr16"
                        v-if="isConnected"
                        @click="handleUpdateDocument360"
                        :disabled="isRequesting || !(apiKey && projectVersion)"
                    >
                        {{ $t('Update') }}
                    </dt-button>
                    <dt-button
                        v-if="isConnected"
                        id="open-child-window"
                        kind="danger"
                        importance="clear"
                        @click="handleShowConfirmationModal"
                        :disabled="isRequesting"
                    >
                        {{ $t('Disconnect') }}
                    </dt-button>
                </div>
            </template>
        </drawer-template>
    </Transition>
    <confirmation-modal
        type="Document360"
        v-model:show-prompt="showConfirmationModal"
        @confirm="handleDisconnectDocument360"
    />
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import {
    DtButton,
    DtInput,
    DtIcon,
    DtCheckbox,
    DtChip
} from '@dialpad/dialtone/vue3';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import BaseCombobox from '@/components/base-combobox/BaseCombobox.vue';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal.vue';
import type {
    IExternalSource,
    INotice
} from '@/components/sources-drawer/Sources.types';

import type { Knowledgebase, GetDocument360Response } from '@/open-api';
import type { RequestArgs } from '@/open-api/base';

import { handleRequest } from '@/utils/Common';
import { NOTICE_KINDS } from '@/utils/Constants';

function initialState() {
    return {
        apiBaseUrl: '' as string | undefined,
        domain: '' as string | undefined,
        apiKey: '' as string | undefined,
        projectVersion: '' as string | undefined,
        isFetching: false,
        isRequesting: false,
        oauthWindow: null as Window | null
    };
}

export default defineComponent({
    props: {
        showDocument360ConnectPage: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        data: {
            type: Object as PropType<IExternalSource>
        },
        showDisconnect: {
            type: Boolean as PropType<boolean>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    components: {
        ConfirmationModal,
        BaseCombobox,
        DtButton,
        DtInput,
        DtIcon,
        DtCheckbox,
        DtChip,
        DrawerTemplate
    },
    watch: {
        data(newData, oldData) {
            if (newData?.name !== oldData?.name) {
                if (this.isConnected) {
                    this.getDocument360Settings();
                }
            }
        },
        showDisconnect(show) {
            if (show) {
                this.handleShowConfirmationModal();
            }
        },
        showConfirmationModal(newShow) {
            this.$emit('update:showDisconnect', newShow);
        }
    },
    emits: ['back', 'connectClose', 'update:showDisconnect'],
    computed: {
        /* v8 ignore next 10 */
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        isConnected(): boolean {
            return this.data?.status === 'Connected';
        }
    },
    methods: {
        handleClose() {
            this.$emit('connectClose');
        },
        handleBackClick(resp: INotice) {
            this.$emit(
                'back',
                Object.keys(resp).length
                    ? {
                          title: resp.title,
                          message: resp.message,
                          kind: NOTICE_KINDS.SUCCESS
                      }
                    : {}
            );
            this.resetValues();
        },
        async handleConnectDocument360() {
            this.isRequesting = true;

            const resKb = await handleRequest<RequestArgs>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.createDocument360Settings(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    {
                        api_base_url: this.apiBaseUrl,
                        api_token: this.apiKey,
                        domain: this.domain,
                        project_version: this.projectVersion
                    }
                ),
                this.orgId
            );

            if (resKb.error) {
                this.showErrorMsg('Could not connect this Document360 account');
            } else {
                this.handleBackClick({
                    title: `Document360 ${this.$t('connected')}`,
                    message: this.$t(
                        'Your documents are now importing. View import status in the Configure sources sidebar.'
                    )
                });
            }
        },
        async getDocument360Settings() {
            this.isFetching = true;

            const res = await handleRequest<GetDocument360Response>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.getDocument360Settings(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id
                ),
                this.orgId
            );
            this.isFetching = false;

            if (res?.data) {
                this.apiBaseUrl = res.data.api_base_url;
                this.domain = res.data.domain;
                this.apiKey = res.data.api_token;
                this.projectVersion = res.data.project_version;
            }
        },
        async handleUpdateDocument360() {
            this.isRequesting = true;

            const resKb = await handleRequest<RequestArgs>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.updateDocument360Settings(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    {
                        domain: this.domain,
                        project_version: this.projectVersion
                    }
                ),
                this.orgId
            );

            if (resKb.error) {
                this.showErrorMsg('Could not update this Document360 account');
            } else {
                this.handleBackClick({
                    title: `Document360 ${this.$t(
                        'account updated successfully'
                    )}`
                });
            }
        },
        /* v8 ignore next 25 */
        async handleDisconnectDocument360() {
            this.isRequesting = true;

            const resKb = await handleRequest(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.disconnectDocument360(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id
                ),
                this.orgId
            );
            this.isRequesting = false;

            if (resKb.error) {
                this.showErrorMsg(
                    'Could not disconnect this Document360 account'
                );
            } else {
                this.handleBackClick({
                    title: `Document360 ${this.$t(
                        'account disconnected successfully'
                    )}`
                });
            }
        },
        resetValues() {
            Object.assign(this.$data, initialState());
        },
        /* v8 ignore next 5 */
        showErrorMsg(title: string) {
            this.$store.commit(`${this.orgId}/addNotification`, {
                kind: NOTICE_KINDS.ERROR,
                title: this.$t(title)
            });
        },
        handleShowConfirmationModal() {
            this.showConfirmationModal = true;
        }
    },
    data() {
        return {
            ...initialState(),
            showConfirmationModal: false
        };
    }
});
</script>
