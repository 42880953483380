<template>
    <div
        v-bind="$attrs"
        class="d-d-flex d-ai-center d-gg4 d-pt2 d-fc-tertiary"
        v-if="importInProgress"
    >
        <div class="d-body-small">
            {{ $t('Import in progress') }}
        </div>
        <loading />
    </div>
    <div
        v-bind="$attrs"
        class="d-d-flex d-ai-center d-gg4 d-pt2 d-fc-tertiary"
        v-else-if="importInQueue"
    >
        <div class="d-body-small">
            {{ $t('Import in queue') }}
        </div>
        <dt-icon name="clock" size="100" />
    </div>
    <div
        class="d-ps-absolute d-b8 d-r8 d-bgc-purple-100 d-p16 d-zi-modal d-c-grab d-ba d-bc-subtle d-bar4 d-d-flex d-fd-column d-gg4"
        v-draggable
        v-if="isLocalDevelopment"
    >
        <h4 class="d-ta-center">Import status</h4>
        <div class="d-d-flex d-gg4 d-ai-center">
            <div class="d-fl-grow1 d-d-flex d-jc-space-between d-gg4">
                <span>Box: </span>
                <span v-if="boxStatus && boxStatus !== FetchingEnum.Fetching">
                    {{ boxStatus }}
                </span>
                <span v-else>
                    <dt-skeleton
                        arial-label="Loading"
                        :paragraphOption="{
                            rows: 1,
                            rowClass: 'd-h24 d-w64'
                        }"
                    />
                </span>
            </div>
            <dt-presence
                v-if="boxStatus && boxStatus !== FetchingEnum.Fetching"
                :presence="getPresenceByImportStatus(boxStatus)"
            />
        </div>
        <div class="d-d-flex d-gg4 d-ai-center">
            <div class="d-fl-grow1 d-d-flex d-jc-space-between d-gg4">
                <span>Document360:</span>
                <span
                    v-if="
                        doc360Status && doc360Status !== FetchingEnum.Fetching
                    "
                >
                    {{ doc360Status }}
                </span>
                <span v-else>
                    <dt-skeleton
                        arial-label="Loading"
                        :paragraphOption="{
                            rows: 1,
                            rowClass: 'd-h24 d-w64'
                        }"
                    />
                </span>
            </div>

            <dt-presence
                v-if="doc360Status && doc360Status !== FetchingEnum.Fetching"
                :presence="getPresenceByImportStatus(doc360Status)"
            />
        </div>
        <div class="d-d-flex d-gg4 d-ai-center">
            <div class="d-fl-grow1 d-d-flex d-jc-space-between d-gg4">
                <span>Google Drive:</span>
                <span
                    v-if="
                        gDriveStatus && gDriveStatus !== FetchingEnum.Fetching
                    "
                >
                    {{ gDriveStatus }}
                </span>
                <span v-else>
                    <dt-skeleton
                        arial-label="Loading"
                        :paragraphOption="{
                            rows: 1,
                            rowClass: 'd-h24 d-w64'
                        }"
                    />
                </span>
            </div>

            <dt-presence
                v-if="gDriveStatus && gDriveStatus !== FetchingEnum.Fetching"
                :presence="getPresenceByImportStatus(gDriveStatus)"
            />
        </div>
        <div
            v-for="guruAccountId in guruSettingsIds"
            :key="guruAccountId"
            class="d-d-flex d-gg4 d-ai-center"
        >
            <div class="d-fl-grow1 d-d-flex d-jc-space-between d-gg4">
                <span>Guru - {{ guruAccountId }}: </span>
                <span
                    v-if="
                        guruStatus &&
                        guruStatus?.[guruAccountId!] !== FetchingEnum.Fetching
                    "
                >
                    {{ guruStatus?.[guruAccountId!] }}
                </span>
                <span v-else>
                    <dt-skeleton
                        arial-label="Loading"
                        :paragraphOption="{
                            rows: 1,
                            rowClass: 'd-h24 d-w64'
                        }"
                    />
                </span>
            </div>
            <dt-presence
                v-if="
                    guruStatus &&
                    guruStatus?.[guruAccountId!] !== FetchingEnum.Fetching
                "
                :presence="
                    getPresenceByImportStatus(guruStatus?.[guruAccountId!])
                "
            />
        </div>
        <div
            :key="host.host"
            v-for="host in hosts"
            class="d-d-flex d-gg4 d-ai-center"
        >
            <div class="d-fl-grow1 d-d-flex d-jc-space-between d-gg4">
                <span>Webcrawler - {{ host.host }}: </span>
                <span
                    v-if="
                        webcrawlerStatus &&
                        webcrawlerStatus?.[host.host!] !== FetchingEnum.Fetching
                    "
                >
                    {{ webcrawlerStatus?.[host.host!] }}
                </span>
                <span v-else>
                    <dt-skeleton
                        arial-label="Loading"
                        :paragraphOption="{
                            rows: 1,
                            rowClass: 'd-h24 d-w64'
                        }"
                    />
                </span>
            </div>
            <dt-presence
                v-if="
                    webcrawlerStatus &&
                    webcrawlerStatus?.[host.host!] !== FetchingEnum.Fetching
                "
                :presence="
                    getPresenceByImportStatus(webcrawlerStatus?.[host.host!])
                "
            />
        </div>
        <div class="d-d-flex d-gg4 d-ai-center">
            <div class="d-fl-grow1 d-d-flex d-jc-space-between d-gg4">
                <span>Zendesk:</span>
                <span
                    v-if="
                        zendeskStatus && zendeskStatus !== FetchingEnum.Fetching
                    "
                >
                    {{ zendeskStatus }}
                </span>
                <span v-else>
                    <dt-skeleton
                        arial-label="Loading"
                        :paragraphOption="{
                            rows: 1,
                            rowClass: 'd-h24 d-w64'
                        }"
                    />
                </span>
            </div>

            <dt-presence
                v-if="zendeskStatus && zendeskStatus !== FetchingEnum.Fetching"
                :presence="getPresenceByImportStatus(zendeskStatus)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import {
    DtIcon,
    DtPresence,
    DtSkeleton,
    PRESENCE_STATES
} from '@dialpad/dialtone/vue3';
import type { ApiService } from '@/services/Api.service';
import {
    GetStatusResponseStatusEnum,
    type GuruSettings,
    type Knowledgebase,
    type ListGuruSettingsResponse,
    type ListWebcrawlerDomainsResponse,
    type WebcrawlerDomain
} from '@/open-api';
import { handleRequest } from '@/utils/Common';
import type { Response } from '@/utils/Common';
import { FetchingEnum, ImportStatusType } from '@/store/ImportStatusModule';
import Loading from '@/components/loading/Loading.vue';
import { EventBusService } from '@/services/EventBus.service';
import { ImportStatus, SOURCES, Topic } from '../../../backend/pubsub/index';

export default defineComponent({
    components: {
        DtIcon,
        DtSkeleton,
        DtPresence,
        Loading
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    async mounted() {
        const res = await this.fetchWebcrawlerHosts();
        if (res.data?.domains) this.hosts = res.data.domains;

        const guruSettingsRes = await this.fetchGuruAccounts();
        if (guruSettingsRes.data?.settings)
            this.guruSettingsIds = guruSettingsRes.data.settings.map(
                (guruAccount: GuruSettings) => guruAccount.id
            );
        this.fetch();

        this.importStatusEventBus = new EventBusService(
            this.orgId,
            Topic.import_status_event,
            undefined,
            this.knowledgebase.id,
            {
                updated: this.handleUpdateImportStatusEvent,
                created: this.handleUpdateImportStatusEvent,
                deleted: this.handleUpdateImportStatusEvent
            },
            this.fetch
        );
    },
    unmounted() {
        this.importStatusEventBus?.destroy();
    },
    props: {
        shouldFetch: {
            type: Boolean as PropType<boolean>,
            default: true
        }
    },
    /* v8 ignore next 1000 */
    computed: {
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        isLocalDevelopment(): boolean {
            return window.location.hostname === 'localhost';
        },
        importInProgress(): boolean {
            return (
                this.zendeskStatus === GetStatusResponseStatusEnum.Running ||
                this.boxStatus === GetStatusResponseStatusEnum.Running ||
                this.doc360Status === GetStatusResponseStatusEnum.Running ||
                this.gDriveStatus === GetStatusResponseStatusEnum.Running ||
                this.guruStatus === GetStatusResponseStatusEnum.Running ||
                Object.values(this.webcrawlerStatus || {}).some(
                    (wc: ImportStatusType) =>
                        wc === GetStatusResponseStatusEnum.Running
                )
            );
        },
        importInQueue(): boolean {
            return (
                this.zendeskStatus === GetStatusResponseStatusEnum.Scheduled ||
                this.boxStatus === GetStatusResponseStatusEnum.Scheduled ||
                this.doc360Status === GetStatusResponseStatusEnum.Scheduled ||
                this.gDriveStatus === GetStatusResponseStatusEnum.Scheduled ||
                this.guruStatus === GetStatusResponseStatusEnum.Scheduled ||
                Object.values(this.webcrawlerStatus || {}).some(
                    (wc: ImportStatusType) =>
                        wc === GetStatusResponseStatusEnum.Scheduled
                )
            );
        },
        webcrawlerStatus(): { [x: string]: ImportStatusType } {
            return this.$store.getters[`${this.orgId}/importStatus/webcrawler`];
        },
        doc360Status(): ImportStatusType {
            return this.$store.getters[
                `${this.orgId}/importStatus/document360`
            ];
        },
        gDriveStatus(): ImportStatusType {
            return this.$store.getters[`${this.orgId}/importStatus/gdrive`];
        },
        guruStatus(): ImportStatusType {
            return this.$store.getters[`${this.orgId}/importStatus/guru`];
        },
        zendeskStatus(): ImportStatusType {
            return this.$store.getters[`${this.orgId}/importStatus/zendesk`];
        },
        boxStatus(): ImportStatusType {
            return this.$store.getters[`${this.orgId}/importStatus/box`];
        }
    },
    methods: {
        getPresenceByImportStatus(status: ImportStatusType): string {
            switch (status?.toString()) {
                case GetStatusResponseStatusEnum.Finished:
                    return PRESENCE_STATES.ACTIVE;
                case GetStatusResponseStatusEnum.Failed:
                    return PRESENCE_STATES.BUSY;
                case GetStatusResponseStatusEnum.Running:
                    return PRESENCE_STATES.AWAY;
                default:
                    return PRESENCE_STATES.OFFLINE;
            }
        },
        fetchWebcrawlerHosts(): Promise<
            Response<ListWebcrawlerDomainsResponse>
        > {
            return handleRequest(
                this.apiService.ingestion.listWebcrawlerDomains(
                    this.authToken,
                    this.knowledgebase.id!
                ),
                this.orgId,
                true
            );
        },
        fetchGuruAccounts(): Promise<Response<ListGuruSettingsResponse>> {
            return handleRequest(
                this.apiService.ingestion.listGuruSettings(
                    this.authToken,
                    this.knowledgebase.id!
                ),
                this.orgId,
                true
            );
        },
        async fetch() {
            await this.$store.dispatch(`${this.orgId}/importStatus/fetch`, {
                kbId: this.knowledgebase.id,
                hosts: this.hosts,
                guruSettingsIds: this.guruSettingsIds
            });
        },
        handleUpdateImportStatusEvent(event: ImportStatus) {
            switch (event.ingestion_source) {
                case SOURCES.BOX: {
                    this.$store.commit(
                        `${this.orgId}/importStatus/setBox`,
                        event.status
                    );
                    break;
                }
                case SOURCES.GURU: {
                    this.$store.commit(`${this.orgId}/importStatus/setGuru`, {
                        guruSettingsId: event.source_id,
                        status: event.status
                    });
                    break;
                }
                case SOURCES.GDRIVE: {
                    this.$store.commit(
                        `${this.orgId}/importStatus/setGDrive`,
                        event.status
                    );
                    break;
                }
                case SOURCES.DOC360: {
                    this.$store.commit(
                        `${this.orgId}/importStatus/setDocument360`,
                        event.status
                    );
                    break;
                }
                case SOURCES.ZENDESK: {
                    this.$store.commit(
                        `${this.orgId}/importStatus/setZendesk`,
                        event.status
                    );
                    break;
                }
                case SOURCES.WEBCRAWLER: {
                    this.$store.commit(
                        `${this.orgId}/importStatus/setWebcrawlerHost`,
                        { host: event.source_id, status: event.status }
                    );
                    break;
                }
            }
        }
    },
    data: function () {
        return {
            hosts: [] as WebcrawlerDomain[],
            guruSettingsIds: [] as string[],
            FetchingEnum,
            importStatusEventBus: undefined as EventBusService | undefined
        };
    }
});
</script>
