<template>
    <drawer-template
        @drawer-template-close="close"
        v-bind="$attrs"
        force-min-width
    >
        <template #header>
            <div class="d-w100p d-headline-medium">
                {{
                    chatbotData
                        ? $t(`Configure ${chatbotName}`)
                        : $t(`Create a new ${chatbotName}`)
                }}
            </div>
        </template>
        <template #default>
            <div class="d-mb16">
                <span>{{ $t('All fields are required.') }}</span>
            </div>
            <div class="d-mb16">
                <base-form-toggle
                    :is-saving="isLoading"
                    v-model="isGenerative"
                    :label="$t('Use Generative Ai Assistance')"
                    :description="
                        $t(
                            `Allow the ${chatbotName} to respond with natural sounding responses using information in your knowledge base. Only available in English.`
                        )
                    "
                />
            </div>
            <div class="d-mb16">
                <dt-input
                    v-model="title"
                    id="chatbot-title-input"
                    aria-required="true"
                    :label="`${$t(`${chatbotName} title`)}`"
                    :disabled="isLoading"
                    :description="`
                        ${$t('For internal use only, will not be surfaced to your customers')}.
                        ${$t('Between 3 and 50 characters')}`"
                    :show-messages="!!validate.title.length && hasSaved"
                    :messages="validate.title"
                />
            </div>
            <div class="d-mb16">
                <div class="d-label" data-qa="dt-select-label">
                    {{ $t('Supported languages') }}
                </div>
                <div class="d-description">
                    {{ $t('A knowledge base is created for each language') }}
                </div>
                <div
                    :key="selectedLanguage.id"
                    v-for="selectedLanguage in selectedLanguages"
                    class="selected-languages d-d-flex d-jc-space-between d-ai-center d-mb4"
                >
                    <template v-if="selectedLanguage?.created">
                        <dt-toggle
                            label-class="d-fl1 d-fw-semibold"
                            :disabled="
                                defaultLanguage === selectedLanguage.locale
                            "
                            :checked="selectedLanguage.enabled"
                            @change="handleToggle(selectedLanguage)"
                        >
                            {{ getLabel(selectedLanguage) }}
                        </dt-toggle>
                    </template>
                    <template v-else>
                        <dt-select-menu
                            ref="selectedLanguage"
                            class="supportedLanguages"
                            :value="selectedLanguage.locale"
                            :disabled="isLoading"
                            @change="
                                debounce(() =>
                                    onLanguageSelect(
                                        $event,
                                        selectedLanguage.id
                                    )
                                )
                            "
                            :messages="languageSelectValidationMessages"
                        >
                            <option selected :value="selectedLanguage.locale">
                                {{
                                    selectedLanguage.locale ||
                                    $t('Please select')
                                }}
                                {{ renderBetaText(selectedLanguage.locale) }}
                            </option>
                            <option
                                :key="supportedLanguage"
                                :value="supportedLanguage"
                                v-for="supportedLanguage in supportedLanguages"
                            >
                                {{ supportedLanguage }}
                                {{ renderBetaText(supportedLanguage) }}
                            </option>
                        </dt-select-menu>
                        <dt-button
                            circle
                            importance="clear"
                            size="xs"
                            @click="onLanguageRemove(selectedLanguage)"
                            v-if="
                                defaultLanguage !== selectedLanguage.locale &&
                                selectedLanguages.length > 1
                            "
                            class="d-as-flex-start d-ml8"
                        >
                            <template #icon>
                                <dt-icon class="d-h16 d-w16" name="close" />
                            </template>
                        </dt-button>
                    </template>
                </div>
                <dt-button
                    link
                    class="d-fs-100"
                    v-if="supportedLanguages.length"
                    @click="addLanguage"
                >
                    {{ $t('Add another language') }}
                </dt-button>
            </div>
            <div class="d-mb16" v-show="filteredSelectedLanguages.length > 1">
                <dt-select-menu
                    id="defaultLanguage"
                    v-model="defaultLanguage"
                    @change="handleDefaultLanguageChange($event)"
                    aria-required="true"
                    :label="$t('Default language')"
                >
                    <option selected v-if="!filteredSelectedLanguages.length">
                        {{ $t('Please select') }}
                    </option>
                    <option
                        v-for="option in filteredSelectedLanguages"
                        :selected="defaultLanguage === option.locale"
                        :key="`option-${option}`"
                    >
                        {{ option.locale }} {{ renderBetaText(option.locale) }}
                    </option>
                </dt-select-menu>
            </div>
            <div class="d-mb16" v-if="isGenerative">
                <base-form-toggle
                    class=""
                    :is-saving="isLoading"
                    v-model="hasSourceLinks"
                    :label="$t('Provide source links')"
                    :description="
                        $t(
                            `Allow customers to click on source links for Ai generated answers.`
                        )
                    "
                />
            </div>
            <div class="d-mb16">
                <div class="d-label" data-qa="dt-select-label">
                    <span>{{ $t('Company name') }} </span>
                </div>
                <div class="d-description">
                    <span>{{ $t('Maximum 50 characters') }} </span>
                </div>
                <dt-input
                    v-model="generativeCompany"
                    :disabled="isSaving"
                    :show-messages="!generativeCompany?.length && hasSaved"
                    :messages="[
                        {
                            message: $t('This field is required'),
                            type: VALIDATION_MESSAGE_TYPES.ERROR
                        }
                    ]"
                />
            </div>
            <div class="d-mb16">
                <div class="d-label" data-qa="dt-select-label">
                    <span>{{ $t('Name') }}</span>
                </div>
                <div class="d-description">
                    <span>
                        {{
                            $t(
                                `Customize how your ${chatbotName} refers to itself`
                            )
                        }}.
                        {{ $t('Between 3 and 20 characters') }}
                    </span>
                </div>
                <div class="d-d-flex d-fd-column d-gg4">
                    <div class="d-w100p">
                        <dt-input
                            v-model="generativeName"
                            :disabled="isSaving"
                            :show-messages="
                                !!validate.generativeName.length && hasSaved
                            "
                            :messages="validate.generativeName"
                        />
                    </div>
                    <div class="d-mln8" v-if="isNewChatbotFlowFeature">
                        <dt-button
                            @click="generateName"
                            importance="clear"
                            size="sm"
                        >
                            <template #icon>
                                <dt-icon name="sparkle" size="100" />
                            </template>
                            {{ $t('Generate') }}
                        </dt-button>
                    </div>
                </div>
            </div>
            <div class="d-mb16">
                <div
                    class="d-d-flex d-fd-column d-gg4"
                    v-if="isNewChatbotFlowFeature"
                >
                    <div class="d-w100p">
                        <dt-input
                            v-model="generativeIndustry"
                            :label="$t('Industry')"
                            :description="
                                $t(
                                    'Describe your business and the purpose of the chatbot.',
                                    { chatbotName }
                                )
                            "
                            type="textarea"
                            placeholder=""
                        />
                    </div>
                    <div class="d-mln8">
                        <dt-button
                            @click="generateIndustry"
                            importance="clear"
                            size="sm"
                        >
                            <template #icon>
                                <dt-icon name="sparkle" size="100" />
                            </template>
                            {{ $t('Generate') }}
                        </dt-button>
                    </div>
                </div>
                <dt-select-menu
                    v-else
                    id="industry"
                    :value="generativeIndustry"
                    v-model="generativeIndustry"
                    :disabled="isSaving"
                    :aria-required="isGenerative"
                    :label="$t('Industry')"
                    @change="handleIndustryChange"
                    :messages="industrySelectValidationMessages"
                >
                    <option
                        v-if="generativeIndustry === 'please_select'"
                        selected
                        :value="generativeIndustry"
                    >
                        {{ $t('Please select') }}
                    </option>
                    <option
                        :key="chatbotBusinessVertical.value"
                        :value="chatbotBusinessVertical.value"
                        v-for="chatbotBusinessVertical in chatbotBusinessVerticals"
                    >
                        {{ chatbotBusinessVertical.label }}
                    </option>
                </dt-select-menu>
            </div>
        </template>
        <template #footer>
            <div class="d-d-flex d-fd-column d-py16">
                <div class="d-d-flex d-gg16 d-as-flex-end">
                    <dt-button
                        importance="clear"
                        @click="close"
                        :disabled="isLoading"
                    >
                        {{ $t('Cancel') }}
                    </dt-button>
                    <dt-button :disabled="isLoading" @click="handleSave">
                        {{ buttonText }}
                    </dt-button>
                </div>
            </div>
        </template>
    </drawer-template>
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import {
    DtButton,
    DtCheckbox,
    DtInput,
    DtIcon,
    DtModal,
    DtListItem,
    DtRecipeComboboxMultiSelect,
    DtSelectMenu,
    DtToggle,
    VALIDATION_MESSAGE_TYPES,
    DtBanner
} from '@dialpad/dialtone/vue3';
import DropFile from '@/components/upload-drawer/DropFile.vue';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import { ALPHANUMERIC_REGEX, LOCALES, NOTICE_KINDS } from '@/utils/Constants';
import BaseCombobox from '@/components/base-combobox/BaseCombobox.vue';
import {
    getKey,
    handleRequest,
    requestWaterfall,
    uuidv4,
    type Response,
    enabledLocales,
    createDebounce
} from '@/utils/Common';
import type { ApiService } from '@/services/Api.service';
import store from '@/store';
import type {
    PostKnowledgebaseRequest,
    PostWidgetRequest,
    Widget,
    Knowledgebase,
    ListKnowledgebasesResponse
} from '@/open-api';
import {
    KnowledgebaseStatus,
    LicenseType,
    WidgetStatus,
    WidgetVertical
} from '@/open-api';
import type { AxiosResponse } from 'axios';
import BaseFormToggle from '@/components/chatbot-drawer/BaseFormToggle.vue';
import { APP_BY_LICENSE_TYPE } from '@/utils/types/App';
import { featureIsEnabled } from '@/directives/feature.directive';

interface SelectedLanguage {
    id: string;
    locale: string;
    created: boolean;
    enabled: boolean;
}
export default defineComponent({
    props: {
        chatbotType: {
            type: String as PropType<LicenseType>
        },
        chatbotData: {
            type: Object as PropType<Widget>
        },
        callback: {
            type: Function as PropType<any>
        }
    },
    mounted() {
        this.chatbotData ? this.resetData(this.chatbotData!, true) : undefined;
        if (this.callback) this.callback();
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId,
            debounce: createDebounce()
        };
    },
    components: {
        DtCheckbox,
        BaseFormToggle,
        BaseCombobox,
        DtBanner,
        DtButton,
        DtInput,
        DtIcon,
        DtSelectMenu,
        DtModal,
        DtListItem,
        DropFile,
        DrawerTemplate,
        DtRecipeComboboxMultiSelect,
        DtToggle
    },
    watch: {
        chatbotData: {
            handler(newChatbotData: Widget) {
                if (newChatbotData) {
                    this.resetData(newChatbotData);
                }
            },
            deep: true
        },
        selectedLanguages: {
            handler(newSelectedLanguages: SelectedLanguage[]) {
                if (
                    newSelectedLanguages.some(
                        (lang) =>
                            lang.enabled &&
                            lang.locale.length &&
                            !lang.locale.startsWith('English')
                    )
                ) {
                    this.isGenerative = false;
                }
            },
            deep: true
        }
    },
    computed: {
        VALIDATION_MESSAGE_TYPES() {
            return VALIDATION_MESSAGE_TYPES;
        },
        // This is done so that the function coverage isn't affected by computed props
        /* v8 ignore next 93 */
        disableUpsert(): boolean {
            return (
                !this.title?.length ||
                !this.filteredSelectedLanguages.length ||
                !this.defaultLanguage?.length ||
                this.generativeIndustry === 'please_select' ||
                !this.generativeIndustry?.length ||
                !this.generativeCompany?.length
            );
        },
        availableLanguages(): string[] {
            return enabledLocales(this.isGenerative)
                .map(
                    (localeKey: string) =>
                        LOCALES[localeKey as keyof typeof LOCALES]
                )
                .sort();
        },
        apiService(): ApiService {
            return store.getters[`${this.orgId}/apiService`];
        },
        authToken(): string {
            return store.getters[`${this.orgId}/authToken`];
        },
        supportedLanguages(): string[] {
            return this.availableLanguages.filter(
                (l: string) =>
                    !this.selectedLanguages.some(
                        (selectedLang: SelectedLanguage) =>
                            selectedLang.locale === l
                    )
            );
        },
        filteredSelectedLanguages(): { id: string; locale: string }[] {
            return this.selectedLanguages.filter(
                (selectedLanguage: SelectedLanguage) =>
                    !!selectedLanguage.locale.length &&
                    selectedLanguage.enabled !== false
            );
        },
        isLoading(): boolean {
            return this.loading || this.isSaving;
        },
        buttonText(): string {
            return this.isSaving
                ? `${this.$t('Saving')}...`
                : this.$t(this.chatbotData ? 'Save' : 'Create');
        },
        chatbotBusinessVerticals(): {
            label: string;
            value: string;
        }[] {
            return Object.keys(WidgetVertical).map((key) => ({
                label: key.replace(/([A-Z])/g, ' $1').trim(),
                value: WidgetVertical[key as keyof typeof WidgetVertical]
            }));
        },
        languageSelectValidationMessages(): {
            message: any;
            type: string;
        }[] {
            return this.selectedLanguages.some(
                (selectedLanguage: SelectedLanguage) =>
                    !!selectedLanguage?.locale.length
            ) || !this.hasSaved
                ? []
                : [
                      {
                          message: this.$t(
                              'You need to select at least 1 supported language'
                          ),
                          type: VALIDATION_MESSAGE_TYPES.ERROR
                      }
                  ];
        },
        industrySelectValidationMessages(): {
            message: any;
            type: string;
        }[] {
            return (this.generativeIndustry?.length &&
                this.generativeIndustry !== 'please_select') ||
                !this.hasSaved
                ? []
                : [
                      {
                          message: this.$t('This field is required'),
                          type: VALIDATION_MESSAGE_TYPES.ERROR
                      }
                  ];
        },
        chatbotName(): string {
            return APP_BY_LICENSE_TYPE.SINGULAR[this.chatbotType];
        },
        isNewChatbotFlowFeature() {
            return featureIsEnabled('NEW_CHATBOT_CREATION_FLOW');
        }
    },
    methods: {
        async fetchKbs(): Promise<Knowledgebase[]> {
            this.loading = true;
            if (!this.chatbotData) return [];
            const res = await handleRequest(
                this.apiService?.knowledge.listKnowledgebases(
                    this.authToken,
                    this.chatbotData.id!
                ),
                this.orgId
            );
            this.loading = false;
            const kbs: ListKnowledgebasesResponse = res.data;
            return kbs?.knowledgebases || [];
        },
        close(event: any, shouldFetch?: boolean) {
            this.$emit('close', shouldFetch);
            this.resetData();
        },
        renderBetaText(locale: string) {
            if (!locale) return '';
            return locale.startsWith('English') || !this.isGenerative
                ? ''
                : '(Beta)';
        },
        async resetData(chatbotData?: Widget, onInitiliasation = false) {
            if (!chatbotData) return;
            if (chatbotData) {
                this.knowledgebases = await this.fetchKbs();

                if (this.chatbotData?.default_locale) {
                    this.defaultLanguage =
                        LOCALES[
                            this.chatbotData
                                ?.default_locale as keyof typeof LOCALES
                        ] || '';
                } else this.defaultLanguage = '';

                if (this.knowledgebases) {
                    this.selectedLanguages = this.knowledgebases.map((kb) => {
                        const isDefaultLanguage =
                            this.defaultLanguage ===
                            LOCALES[kb.locale as keyof typeof LOCALES];
                        return {
                            id: kb.id!,
                            locale: LOCALES[kb.locale as keyof typeof LOCALES],
                            created: true,
                            enabled: isDefaultLanguage
                                ? true
                                : kb.status === WidgetStatus.Published
                        };
                    });
                } else {
                    this.selectedLanguages = [];
                }
                this.title = chatbotData?.name || '';

                this.isGenerative = this.chatbotData?.generative || false;

                this.generativeCompany = this.chatbotData?.company_name || '';
                this.generativeName = this.chatbotData?.bot_name || '';
                this.generativeIndustry =
                    this.chatbotData?.vertical || undefined;

                // If source links property is undefined set it to true, otherwise used value from BE
                this.hasSourceLinks =
                    this.chatbotData?.enable_source_link === undefined ||
                    this.chatbotData?.enable_source_link;
            } else {
                this.selectedLanguages = [];
                this.title = '';
                this.defaultLanguage = '';
                this.hasSourceLinks = true;
            }
        },
        handleDefaultLanguageChange(newDefaultLanguage: any) {
            this.defaultLanguage = newDefaultLanguage;
        },
        handleToggle(selectedLanguage: SelectedLanguage) {
            selectedLanguage.enabled = !selectedLanguage.enabled;
        },
        getLabel(selectedLanguage: {
            id: string;
            locale: string;
            created: boolean;
        }) {
            const defaultLabel =
                this.defaultLanguage === selectedLanguage.locale
                    ? `(${this.$t('Default')})`
                    : '';

            return `${selectedLanguage.locale} ${defaultLabel}`;
        },
        async createChatbot(defaultLocale: string) {
            const widgetBody: PostWidgetRequest = {
                name: this.title,
                license_type: this.chatbotType!,
                default_locale: defaultLocale,
                generative: this.isGenerative,
                status: WidgetStatus.Published,
                vertical: this.generativeIndustry as WidgetVertical,
                company_name: this.generativeCompany,
                bot_name: this.generativeName
            };

            const appCreatedResponse = await handleRequest<Widget>(
                this.apiService?.knowledge.postWidget(
                    this.authToken,
                    widgetBody
                ),
                this.orgId,
                true
            );

            if (appCreatedResponse.error || !appCreatedResponse?.data)
                return appCreatedResponse;

            return appCreatedResponse;
        },
        async updateChatbot(chatbot: Widget, defaultLocale: string) {
            const widgetBody: Widget = {
                name: this.title,
                default_locale: defaultLocale,
                generative: this.isGenerative,
                vertical: this.generativeIndustry as WidgetVertical,
                company_name: this.generativeCompany,
                bot_name: this.generativeName
            };

            this.loading = true;
            const appUpdatedResponse = await handleRequest<Widget>(
                this.apiService?.knowledge.patchWidget(
                    this.authToken,
                    chatbot.id,
                    widgetBody
                ),
                this.orgId,
                true
            );

            if (appUpdatedResponse.error || !appUpdatedResponse?.data)
                return appUpdatedResponse;

            this.loading = false;
            return appUpdatedResponse;
        },
        /* v8 ignore next 24 */
        async createKnowledgebases(app: Widget, kbLocales: SelectedLanguage[]) {
            return await requestWaterfall(
                kbLocales.map((locale) => {
                    const lang = getKey(locale.locale, LOCALES) || '';
                    return () =>
                        handleRequest(
                            this.apiService.knowledge.postKnowledgebase(
                                this.authToken,
                                {
                                    app_id: app.id,
                                    license_type: app.license_type,
                                    locale: lang,
                                    status: KnowledgebaseStatus.Published
                                } as PostKnowledgebaseRequest
                            ),
                            this.orgId,
                            true
                        );
                })
            );
        },
        addLanguage() {
            if (
                !this.selectedLanguages.some(
                    (lang: SelectedLanguage) => !lang.locale.length
                )
            )
                this.selectedLanguages.push({
                    id: uuidv4(),
                    locale: '',
                    created: false,
                    enabled: true
                });
        },
        onLanguageSelect(option: Event, id: string) {
            const targetLanguage = this.selectedLanguages.find(
                (a) => a.id === id
            );
            if (targetLanguage) {
                targetLanguage.locale = option.toString();
            }

            if (this.selectedLanguages.length === 1 && option) {
                this.defaultLanguage = option.toString();
            }
        },
        onLanguageRemove(option: { id: string; locale: string }) {
            const targetLanguageIndex = this.selectedLanguages.findIndex(
                (selectedLang: SelectedLanguage) =>
                    selectedLang.id === option.id
            );

            this.selectedLanguages.splice(targetLanguageIndex, 1);
        },
        /* v8 ignore next 21 */
        async publishKnowledgebases(
            kbResponse: AxiosResponse<Knowledgebase>[]
        ) {
            return await requestWaterfall(
                kbResponse.map((response) => {
                    const kb = response.data;
                    return () =>
                        handleRequest(
                            this.apiService?.knowledge.patchKnowledgebase(
                                this.authToken,
                                kb.id!,
                                {
                                    status: WidgetStatus.Published
                                } as Knowledgebase
                            ),
                            this.orgId
                        );
                })
            );
        },
        handleSave() {
            this.hasSaved = true;

            this.runValidations();
            if (
                this.validate.generativeName.length ||
                this.validate.title.length ||
                this.disableUpsert
            ) {
                return;
            }

            this.chatbotData ? this.handleUpdate() : this.handleCreate();
        },
        async handleUpdate() {
            this.isSaving = true;
            const knowledgeBaseLocalesToBeCreated: SelectedLanguage[] =
                this.selectedLanguages.filter(
                    (lang: SelectedLanguage) =>
                        !lang.created && !!lang?.locale.length
                );

            const defaultLocale = getKey(this.defaultLanguage, LOCALES);

            if (defaultLocale && this.chatbotData) {
                let error = false;
                const updatedApp = await this.updateChatbot(
                    this.chatbotData,
                    defaultLocale
                );
                if (!updatedApp?.error && updatedApp?.data) {
                    /* v8 ignore next 31 */
                    if (knowledgeBaseLocalesToBeCreated.length) {
                        // Make sure if gen AI is ON only english languages are created
                        const createdKnowledgebases =
                            await this.createKnowledgebases(
                                updatedApp.data,
                                knowledgeBaseLocalesToBeCreated.filter(
                                    (kb) =>
                                        (kb.locale.startsWith('English') &&
                                            this.isGenerative) ||
                                        !this.isGenerative
                                )
                            );
                        if (
                            createdKnowledgebases.some(
                                (response: Response<Knowledgebase>) =>
                                    response.error
                            )
                        ) {
                            this.showErrorMsg('Error creating knowledgebases');
                            error = true;
                        }
                    }

                    const knowledgebasesToBeUpdated =
                        this.selectedLanguages.filter(
                            (lang: SelectedLanguage) =>
                                lang.created && lang.locale.length
                        );

                    const updatedKnowledgebases =
                        await this.updateKnowledgebases(
                            knowledgebasesToBeUpdated
                        );
                    if (
                        updatedKnowledgebases.some(
                            (response: Response<Knowledgebase>) =>
                                response.error
                        )
                    ) {
                        this.showErrorMsg('Error updating knowledgebases');
                        error = true;
                    }
                } else if (updatedApp.error?.response?.status === 403) {
                    const message = updatedApp.error.response.data.message;
                    this.showErrorMsg(message);
                    error = true;
                } else {
                    this.showErrorMsg(`Error updating ${this.chatbotName}`);
                    error = true;
                }

                if (!error) {
                    this.close(undefined, true);
                    this.$emit('success', {
                        kind: NOTICE_KINDS.SUCCESS,
                        show: true,
                        message: this.$t(
                            `${updatedApp?.data?.name} has been updated successfully`
                        ),
                        title: this.$t(`${this.chatbotName} updated`),
                        chatbotData: updatedApp.data
                    });
                }
            }
            this.isSaving = false;
        },
        async handleCreate() {
            this.isSaving = true;
            const knowledgeBaseLocales: SelectedLanguage[] =
                this.selectedLanguages.filter(
                    (lang: SelectedLanguage) =>
                        !lang.created && !!lang?.locale.length
                );

            const defaultLocale = getKey(this.defaultLanguage, LOCALES);

            if (knowledgeBaseLocales.length && defaultLocale) {
                const app = await this.createChatbot(defaultLocale);
                if (!app?.error && app?.data) {
                    /* v8 ignore next 88 */
                    const createdKnowledgebases =
                        await this.createKnowledgebases(
                            app.data,
                            knowledgeBaseLocales as SelectedLanguage[]
                        );

                    if (
                        createdKnowledgebases.some(
                            (response: Response<Knowledgebase>) =>
                                response.error
                        )
                    ) {
                        this.$emit('knowledgebaseError', app.data);
                        this.showErrorMsg('Error creating knowledgebases');
                        this.close(undefined, true);
                    } else {
                        // To redirect to the new chatbot we need to get its knowledgebases
                        if (createdKnowledgebases?.length) {
                            this.redirectToCreatedChatbot(
                                createdKnowledgebases.map(
                                    (kb: Response<Knowledgebase>) => kb.data
                                ),
                                app.data
                            );

                            this.$emit('success');
                            this.$store.commit(
                                `${this.orgId}/addNotification`,
                                {
                                    kind: NOTICE_KINDS.SUCCESS,
                                    message: `${this.$t("Let's set up the rest of your")} ${this.chatbotName}. ${this.$t('Visit the Documents tab to begin')}`,
                                    title: `${app.data.name} ${this.$t('created')}`
                                }
                            );
                        }
                    }
                } else if (app.error.response.status === 403) {
                    const message = app.error.response.data.message;
                    this.showErrorMsg(message);
                } else this.showErrorMsg(`Error creating ${this.chatbotName}`);
            }
            this.isSaving = false;
        },
        redirectToCreatedChatbot(knowledgebases: Knowledgebase[], app: Widget) {
            const defaultKb = knowledgebases.find(
                (kb: Knowledgebase) => kb.locale === app.default_locale
            );
            this.$store.commit(
                `${this.orgId}/setCurrentKnowledgeBase`,
                defaultKb
            );
            this.$store.commit(`${this.orgId}/setCurrentChatbot`, app);
            this.$router.push({
                name: 'improve',
                params: {
                    licenseType:
                        app.license_type === LicenseType.Dss
                            ? 'self-service'
                            : app.license_type === LicenseType.Asa
                              ? 'sales-assist'
                              : 'agent-assist',
                    appId: app.id,
                    kbId: defaultKb.id
                }
            });
        },
        async updateKnowledgebases(kbLocales: SelectedLanguage[]) {
            return await requestWaterfall(
                kbLocales.map((locale) => {
                    return () =>
                        handleRequest(
                            this.apiService?.knowledge.patchKnowledgebase(
                                this.authToken,
                                locale.id,
                                {
                                    status: locale.enabled
                                        ? KnowledgebaseStatus.Published
                                        : KnowledgebaseStatus.Draft
                                } as Knowledgebase
                            ),
                            this.orgId,
                            true
                        );
                })
            );
        },
        showErrorMsg(title: string) {
            this.$store.commit(`${this.orgId}/addNotification`, {
                kind: NOTICE_KINDS.ERROR,
                title: this.$t(title)
            });
        },
        handleIndustryChange(newIndustry: any) {
            this.generativeIndustry = newIndustry;
        },
        openSelectMenuPicker() {
            const simulateMouseEvent = function (
                element: HTMLSelectElement,
                eventName: string,
                coordX: number,
                coordY: number
            ) {
                element.dispatchEvent(
                    new MouseEvent(eventName, {
                        view: window,
                        bubbles: true,
                        cancelable: true,
                        clientX: coordX,
                        clientY: coordY,
                        button: 0
                    })
                );
            };

            // The select menu's element
            const selectMenu: HTMLSelectElement =
                this.$refs.selectedLanguage[0].$el.querySelector(
                    '.supportedLanguages'
                );

            const box = selectMenu.getBoundingClientRect(),
                coordX = box.left + (box.right - box.left) / 2,
                coordY = box.top + (box.bottom - box.top) / 2;

            // According to MDN docs the showPicker function inside the HTMLSelectElement can only be activated by user gesture, so we simulate it here
            simulateMouseEvent(selectMenu, 'mousedown', coordX, coordY);
            simulateMouseEvent(selectMenu, 'mouseup', coordX, coordY);
            simulateMouseEvent(selectMenu, 'click', coordX, coordY);
            selectMenu.showPicker();
        },
        runValidations() {
            this.validateGenerativeName();
            this.validateTitle();
        },
        validateTitle() {
            this.title = this.title.trim();
            if (!this.title.length) {
                this.validate.title = [
                    {
                        message: this.$t('This field is required'),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
                return;
            } else if (this.title.length < 3) {
                this.validate.title = [
                    {
                        message: this.$t(
                            'Title should be at least 3 characters'
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
                return;
            } else if (this.title.length > 50) {
                this.validate.title = [
                    {
                        message: this.$t(
                            'Title should have a maximum of 50 characters'
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
                return;
            } else if (!ALPHANUMERIC_REGEX.test(this.title)) {
                this.validate.title = [
                    {
                        message: this.$t(
                            'Title should only have alphanumeric characters and spaces'
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
                return;
            } else {
                this.validate.title = [];
            }
        },
        validateGenerativeName() {
            this.generativeName = this.generativeName.trim();
            if (!this.generativeName.length) {
                this.validate.generativeName = [
                    {
                        message: this.$t('This field is required'),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
                return;
            } else if (this.generativeName.length < 3) {
                this.validate.generativeName = [
                    {
                        message: this.$t(
                            'Name should be at least 3 characters'
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
                return;
            } else if (this.generativeName.length > 20) {
                this.validate.generativeName = [
                    {
                        message: this.$t(
                            'Name should have a maximum of 20 characters'
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
                return;
            } else if (!ALPHANUMERIC_REGEX.test(this.generativeName)) {
                this.validate.generativeName = [
                    {
                        message: this.$t(
                            'Name should only have alphanumeric characters and spaces'
                        ),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
                return;
            } else {
                this.validate.generativeName = [];
            }
        },
        generateName() {
            console.log('Generating name!');
        },
        generateIndustry() {
            console.log('Generating industry!');
        }
    },
    data() {
        return {
            selectedLanguages: [
                {
                    id: uuidv4(),
                    locale: ''
                }
            ] as SelectedLanguage[],
            title: '',
            defaultLanguage: '' as string,
            loading: false,
            isSaving: false,
            isGenerative: true,
            hasSourceLinks: true,
            generativeName: '',
            generativeCompany: '',
            generativeIndustry: 'please_select' as
                | WidgetVertical
                | undefined
                | 'please_select',
            knowledgebases: [] as Knowledgebase[],
            hasSaved: false,
            validate: {
                generativeName: [],
                title: []
            }
        };
    }
});
</script>

<style lang="less">
.selected-languages {
    & > div {
        flex: 1;
    }
}
</style>
