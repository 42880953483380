<template>
    <div class="d-d-flex d-ai-center d-fc-black-900 d-h24">
        <dt-button
            v-if="columnSortable && columnTitle && columnType !== 'options'"
            @click="sortList(columnData)"
            kind="muted"
            importance="clear"
            class="d-p2 d-fw-bold"
            icon-position="right"
            label-class="d-fs-100 d-tt-none"
        >
            <template v-if="columnTitle">
                <span class="d-tt-none d-pl2 d-ml1 d-fc-black-700 d-truncate">
                    {{ $t(columnTitle) }}
                </span>
                <dt-tooltip
                    class="d-ml6 d-pt4"
                    placement="top-end"
                    :message="columnTooltip?.message"
                    v-if="columnTooltip && Object.keys(columnTooltip)?.length"
                >
                    <template #anchor>
                        <dt-icon :name="columnTooltip?.type" size="200" />
                    </template>
                </dt-tooltip>
            </template>
            <template #icon>
                <dt-icon
                    class="d-ml4"
                    size="200"
                    name="chevrons-up-down"
                    v-if="sortByVal?.sort !== columnSort"
                />
                <dt-icon
                    class="d-ml4"
                    size="200"
                    name="chevron-down"
                    v-if="sortByVal?.sort === columnSort && !sortByVal?.asc"
                />
                <dt-icon
                    class="d-ml4"
                    size="200"
                    name="chevron-up"
                    v-if="sortByVal?.sort === columnSort && sortByVal?.asc"
                />
            </template>
        </dt-button>
        <template v-else-if="columnTitle">
            <span
                class="d-tt-none d-pl2 d-ml1 d-fc-black-700 d-truncate d-fs-100"
            >
                {{ $t(columnTitle) }}
            </span>
            <dt-tooltip
                class="d-ml6 d-pt4"
                placement="top-end"
                :message="columnTooltip?.message"
                v-if="columnTooltip && Object.keys(columnTooltip)?.length"
            >
                <template #anchor>
                    <dt-icon :name="columnTooltip?.type" size="200" />
                </template>
            </dt-tooltip>
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import type { IBaseTableColumn } from '@/components/base-table/BaseTable.types';
import {
    DtButton,
    DtCheckbox,
    DtIcon,
    DtTooltip
} from '@dialpad/dialtone/vue3';
import type { IBaseSortableTableColumn } from '@/components/base-table/BaseTable.types';

export default defineComponent({
    components: {
        DtCheckbox,
        DtButton,
        DtIcon,
        DtTooltip
    },
    props: {
        columnData: {
            type: Object as PropType<IBaseTableColumn<any>>,
            required: true
        },
        checkbox: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        sortBy: {
            type: Object as PropType<IBaseSortableTableColumn>
        },
        sortable: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        isDisabled: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        allChecked: {
            type: Boolean as PropType<boolean>,
            default: () => false
        }
    },
    computed: {
        IBaseTableColumn(): any {
            // @ts-ignore
            return IBaseTableColumn;
        },
        /* v8 ignore next 15 */
        allSelected: {
            get(): boolean {
                return this.allChecked;
            },
            set(value: boolean) {
                this.$emit('update:allChecked', value);
            }
        },
        columnTitle(): string | undefined {
            return this.columnData?.title;
        },
        columnSortable(): boolean {
            return this.columnData?.sortable || false;
        },
        columnSort(): string | undefined {
            return this.columnData?.sort;
        },
        columnType(): string | undefined {
            return this.columnData?.type;
        },
        columnTooltip(): { type: string; message: string } | undefined {
            return this.columnData?.tooltip;
        },
        sortByVal(): IBaseSortableTableColumn {
            return this.sortBy;
        }
    },
    methods: {
        /* v8 ignore next 15 */
        sortList(data?: IBaseSortableTableColumn) {
            let asc = true;
            if (data?.sort === this.sortBy?.sort) {
                asc = !this.sortBy?.asc;
            }
            this.$emit('update:sortBy', {
                sort: data?.sort,
                asc
            });
        },
        toggleAllRows() {
            this.$emit('toggleAllRows');
        }
    }
});
</script>
