<template>
    <template v-for="option in items" :key="option">
        <div
            class="d-bl d-blw1 d-bc-subtle d-h24"
            v-if="shouldShowDivider(option)"
        ></div>
        <dt-popover
            v-if="isLink(option)"
            append-to="body"
            :modal="false"
            placement="bottom-start"
            padding="medium"
            class="link-popover"
            :open="linkDropdownOpen || isEditingLink"
            initial-focus-element="first"
            :fallback-placements="['bottom']"
            dialog-class="d-w264"
            sticky="popper"
            @opened="openChanged"
        >
            <template #anchor="{ attrs }">
                <dt-button
                    v-bind="attrs"
                    kind="muted"
                    size="xs"
                    importance="clear"
                    label-class="d-jc-flex-start"
                    @click="toggleLinkDropdown"
                    required
                    :active="linkDropdownOpen || isEditingLink"
                >
                    <template #icon>
                        <dt-icon
                            :name="convertOptionToIconName(option)"
                            size="200"
                        />
                    </template>
                </dt-button>
            </template>
            <template #content="{}">
                <div class="d-d-flex d-fw-wrap d-gg8">
                    <div class="d-w100p">
                        <div class="d-fs-100 d-pb4">URL</div>
                        <dt-input
                            v-model="linkText"
                            :placeholder="$t('Enter link')"
                            icon-size="sm"
                            size="sm"
                            type="text"
                            @keyup.enter="handleLinkAdd"
                            :messages="validate"
                        />
                    </div>
                    <div class="d-w100p d-mt4">
                        <div class="d-fs-100 d-pb4">
                            {{ $t('Display text') }}
                        </div>
                        <dt-input
                            v-model="linkDisplayText"
                            :placeholder="
                                $t('Display title') +
                                ' (' +
                                $t('Optional') +
                                ')'
                            "
                            icon-size="sm"
                            size="sm"
                            type="text"
                            @keyup.enter="handleLinkAdd"
                        />
                    </div>
                    <div class="d-d-flex d-jc-space-between d-w100p d-pt4">
                        <dt-button
                            size="sm"
                            importance="clear"
                            kind="muted"
                            @click="handleLinkClear"
                            :disabled="!isEditingLink"
                        >
                            <template #icon>
                                <dt-icon name="trash" size="200" />
                            </template>
                            {{ $t('Remove link') }}
                        </dt-button>
                        <dt-button
                            size="sm"
                            class="d-ml8"
                            @click="handleLinkAdd"
                            :disabled="!linkText"
                        >
                            {{ $t('Save') }}
                        </dt-button>
                    </div>
                </div>
            </template>
        </dt-popover>
        <dt-button
            v-else
            kind="muted"
            size="xs"
            importance="clear"
            :active="isOptionActive(option)"
            @click="toggleToolbarItemAction($event, option)"
        >
            <template #icon>
                <dt-icon :name="convertOptionToIconName(option)" size="200" />
            </template>
        </dt-button>
    </template>
</template>

<script lang="ts">
import { defineComponent, type PropType, toRaw } from 'vue';
import { from, type Subject } from 'rxjs';

import type { EditorView } from 'prosemirror-view';
import { Selection } from 'prosemirror-state';
import type { Mark, Schema } from 'prosemirror-model';

import {
    DtButton,
    DtIcon,
    DtCheckbox,
    DtInput,
    DtListItem,
    DtPopover,
    DtSkeleton,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';

import {
    MENU_VIEW,
    toggleLink,
    updateLink,
    removeLink,
    type updateToolbarOptions
} from '@/components/rich-text-editor/utils';
import {
    TELEPHONE_REGEX,
    MAILTO_EMAIL_REGEX,
    URL_REGEX
} from '@/utils/Constants';
import { markdownSerializer } from '@/components/rich-text-editor/markdown';

export enum toolbarItem {
    Bold = 'strong',
    Italic = 'em',
    Strikethrough = 's',
    Link = 'link',
    BulletList = 'bullet_list',
    NumberList = 'ordered_list',
    Code = 'code',
    CodeBlock = 'code_block'
}

export default defineComponent({
    components: {
        DtPopover,
        DtSkeleton,
        DtInput,
        DtCheckbox,
        DtListItem,
        DtIcon,
        DtButton
    },
    props: {
        items: {
            type: Array as PropType<any[]>,
            required: true
        },
        editor: {
            type: Object as PropType<EditorView | any>,
            required: true
        },
        updateToolbar: {
            type: Object as PropType<Subject<any> | any>
        },
        isSavable: {
            type: Boolean as PropType<boolean>
        },
        schema: {
            type: Object as PropType<Schema>,
            required: true
        }
    },
    mounted() {
        this.onUpdateSubscription = this.onUpdate?.subscribe(
            (toolbarOptions: updateToolbarOptions) => {
                this.onEditorUpdate(toolbarOptions);
            }
        );

        // Focus at the end of the document
        const tr = this.rawEditor?.state.tr;
        const selection = Selection.atEnd(tr.doc);
        this.rawEditor.dispatch(tr.setSelection(selection));
        this.rawEditor.focus();

        MENU_VIEW.update();
    },
    unmounted() {
        this.onUpdateSubscription?.unsubscribe();
    },
    computed: {
        /* v8 ignore next 20 */
        rawEditor(): EditorView {
            return toRaw(this.editor);
        },
        rawSchema(): Schema {
            return toRaw(this.schema);
        },
        savable: {
            get(): boolean {
                return !!this.isSavable;
            },
            set(value: boolean) {
                this.$emit('update:isSavable', value);
            }
        }
    },
    methods: {
        /* v8 ignore next 90 */
        onEditorUpdate(toolbarOptions: updateToolbarOptions) {
            this.toolbarOptions = toolbarOptions;
            const linkMark: Mark = toolbarOptions?.content?.[0]?.marks.find(
                (mark: Mark) => mark.type.name === toolbarItem.Link
            );
            if (linkMark && Object.values(linkMark).length) {
                this.isEditingLink = true;
            }
            if (this.isEditingLink) {
                this.linkText = linkMark?.attrs?.href || '';
                this.linkDisplayText = linkMark?.attrs?.title || '';
            }
        },
        handleLinkAdd() {
            if (
                !this.linkText.includes('mailto:') &&
                !this.linkText.includes('tel:') &&
                !URL_REGEX.test(this.linkText)
            ) {
                this.validate = [
                    {
                        message: this.$t('Invalid URL'),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
            } else if (
                this.linkText.includes('mailto:') &&
                !MAILTO_EMAIL_REGEX.test(this.linkText)
            ) {
                this.validate = [
                    {
                        message: this.$t('Invalid email'),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
            } else if (
                this.linkText.includes('tel:') &&
                !TELEPHONE_REGEX.test(this.linkText)
            ) {
                this.validate = [
                    {
                        message: this.$t('Invalid telephone'),
                        type: VALIDATION_MESSAGE_TYPES.ERROR
                    }
                ];
            } else {
                this.validate = [];

                if (this.isEditingLink) {
                    updateLink(
                        this.rawEditor,
                        this.linkDisplayText,
                        this.linkText
                    );
                } else {
                    toggleLink(
                        this.rawEditor,
                        this.linkDisplayText,
                        this.linkText,
                        this.isSelectedText
                    );
                }
                this.toggleLinkDropdown();
                this.rawEditor.focus();
            }
        },
        handleLinkClear() {
            removeLink(this.rawEditor);
            this.toggleLinkDropdown();
        },
        toggleLinkDropdown() {
            this.linkDropdownOpen = !this.linkDropdownOpen;
            this.savable = !this.linkDropdownOpen;
            if (!this.linkDropdownOpen) {
                this.isEditingLink = false;
                this.isEditingLink = false;
                this.linkDropdownOpen = false;
                this.isSelectedText = false;
            }
        },
        isLink(option: toolbarItem): boolean {
            return option === toolbarItem.Link;
        },
        shouldShowDivider(option: toolbarItem) {
            return (
                option === toolbarItem.CodeBlock ||
                option === toolbarItem.BulletList ||
                option === toolbarItem.Link
            );
        },
        convertOptionToIconName(option: toolbarItem) {
            switch (option.toString()) {
                case toolbarItem.Bold:
                    return 'bold';
                case toolbarItem.Italic:
                    return 'italic';
                case toolbarItem.Strikethrough:
                    return 'strikethrough';
                case toolbarItem.Link:
                    return 'link-2';
                case toolbarItem.BulletList:
                    return 'list-bullet';
                case toolbarItem.NumberList:
                    return 'list-ordered';
                case toolbarItem.CodeBlock:
                    return 'code';
                default:
                    return option;
            }
        },
        isOptionActive(item: toolbarItem): boolean {
            switch (item.toString()) {
                case toolbarItem.Bold:
                    return this.toolbarOptions.active.bold;
                case toolbarItem.Italic:
                    return this.toolbarOptions.active.italic;
                case toolbarItem.Strikethrough:
                    return this.toolbarOptions.active.strikethrough;

                case toolbarItem.Link:
                    return this.toolbarOptions.active.link;

                case toolbarItem.BulletList:
                    return this.toolbarOptions.active.bulletList;
                case toolbarItem.NumberList:
                    return this.toolbarOptions.active.numberList;

                case toolbarItem.Code:
                    return this.toolbarOptions.active.code;

                case toolbarItem.CodeBlock:
                    return this.toolbarOptions.active.codeBlock;

                default:
                    return false;
            }
        },
        toggleToolbarItemAction(event: MouseEvent, item: toolbarItem) {
            event.preventDefault();
            this.rawEditor?.focus();
            const attrs =
                item === toolbarItem.Link
                    ? { title: this.linkDisplayText, href: this.linkText }
                    : undefined;
            this.toolbarOptions.toggleMark(item, attrs);
        },
        openChanged(isOpen: boolean) {
            this.linkDropdownOpen = isOpen;

            if (!isOpen) {
                this.isEditingLink = false;
                this.savable = !this.linkDropdownOpen;
            } else {
                this.validate = [];
                if (!this.isEditingLink) {
                    this.linkDisplayText = '';
                    this.linkText = '';
                }

                const selectedText = markdownSerializer.serialize(
                    this.rawEditor.state.doc.cut(
                        this.rawEditor.state.selection.from,
                        this.rawEditor.state.selection.to
                    )
                );

                // If text selection then use it as display text
                if (selectedText) {
                    this.isSelectedText = true;
                    this.linkDisplayText = selectedText;
                }
            }
        }
    },
    data() {
        return {
            toolbarOptions: {
                active: {
                    bold: false,
                    italic: false,
                    strikethrough: false,
                    code: false,
                    codeBlock: false,
                    link: false,
                    numberList: false,
                    bulletList: false
                },
                disabled: {
                    bold: false,
                    italic: false,
                    strikethrough: false,
                    code: false,
                    codeBlock: false,
                    link: false,
                    numberList: false,
                    bulletList: false
                },
                content: undefined,
                toggleMark: () => {}
            } as updateToolbarOptions,
            linkDropdownOpen: false,
            linkText: '',
            linkDisplayText: '',
            isEditingLink: false,
            onUpdate: from(this.updateToolbar as Subject<any>),
            onUpdateSubscription: undefined as any,
            validate: undefined as any,
            isSelectedText: false
        };
    }
});
</script>
