export interface IMenuPopoverOptions {
    title: string;
    action: any;
    kind?: POPOVER_TYPES;
    disabled?: any; //() => boolean
    subtitle?: string;
}

export enum POPOVER_TYPES {
    DEFAULT = 'default',
    MUTED = 'muted',
    DANGER = 'danger',
    INVERTED = 'inverted'
}
