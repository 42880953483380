<template>
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="1_Guru-G_Logo 1" clip-path="url(#clip0_11154_16039)">
            <g id="b">
                <g id="c">
                    <g id="Group">
                        <path
                            id="Vector"
                            d="M24.04 14.2643C24.1245 17.2582 22.8268 19.4966 20.2743 20.9335C16.7323 22.926 12.9355 24.123 8.8232 23.9911C6.50631 23.9171 4.65309 22.8045 3.43835 20.8669C1.11256 17.1575 -0.13921 13.0896 0.0118919 8.68689C0.0918869 6.37592 1.28589 4.55677 3.23836 3.36277C6.91369 1.11402 10.9164 -0.133311 15.2554 0.0118653C17.6064 0.090379 19.4211 1.30956 20.6565 3.27833C22.7616 6.6322 23.7438 10.349 24.04 14.2643Z"
                            fill="#080B0E"
                        />
                        <g id="Group_2">
                            <path
                                id="Vector_2"
                                d="M13.6792 16.5279C12.7518 16.8656 11.7593 16.9234 10.8823 16.6879C8.70317 16.1027 7.12105 14.0984 7.12105 11.9237C7.12105 10.1905 7.90026 9.05575 8.55504 8.40839C9.45572 7.51807 10.7075 6.98922 11.9133 6.98922C11.9282 6.98922 11.9445 6.98922 11.9593 6.98922C14.2584 7.0144 15.7146 8.75059 15.7279 8.76688C15.9279 9.01279 16.2879 9.04835 16.5323 8.84984C16.7767 8.65134 16.8153 8.28988 16.6153 8.04545C16.5427 7.95656 14.8184 5.87669 11.9711 5.84558C10.4334 5.83669 8.89131 6.46628 7.75064 7.59362C6.60701 8.72392 5.97742 10.2616 5.97742 11.9222C5.97742 14.605 7.91508 17.0745 10.586 17.7915C11.0378 17.913 11.5119 17.9722 11.9933 17.9722C12.6866 17.9722 13.3962 17.8478 14.0732 17.6004C15.8316 16.9575 17.55 15.2657 17.4878 13.3103C17.0997 13.5947 16.6775 13.854 16.2331 14.0999C15.8909 15.1739 14.8095 16.1146 13.6806 16.5264L13.6792 16.5279Z"
                                fill="white"
                            />
                            <path
                                id="Vector_3"
                                d="M17.9989 10.5697C17.9885 10.5801 17.4967 11.1075 16.7605 11.6363C15.9961 10.8912 14.7784 10.5372 13.8984 10.5372C13.177 10.5372 12.6259 10.7268 12.2585 11.0986C11.9697 11.3919 11.8215 11.7756 11.8274 12.2082C11.8393 12.9859 12.3978 13.8273 13.9251 13.8614C14.7769 13.8821 15.6109 13.6066 16.3383 13.2333C16.8464 12.9755 17.2123 12.7178 17.3767 12.6007C18.23 11.9978 18.7929 11.3964 18.8403 11.3445C19.0551 11.1119 19.0403 10.7505 18.8078 10.5357C18.5767 10.3224 18.2152 10.3357 17.9989 10.5668V10.5697ZM13.9488 12.7178C13.5059 12.7074 12.977 12.6081 12.9711 12.1904C12.9681 12.0082 13.0481 11.9267 13.0733 11.9015C13.1548 11.8186 13.3696 11.6808 13.8984 11.6808C14.4584 11.6808 15.2065 11.8956 15.725 12.2659C15.168 12.54 14.568 12.7311 13.9488 12.7178Z"
                                fill="white"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_11154_16039">
                <rect width="24.0444" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
