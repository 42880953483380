<template>
    <div
        class="d-zi-popover d-tp-transform d-fd-column d-d-flex d-bl d-bc-subtle d-h100p d-bgc-primary d-ps-relative d-w100p"
        :class="{
            'd-ps-absolute d-t0 d-r0 d-b0 d-blw0': isSecondaryPage
        }"
        :style="{
            minWidth:
                forceWidth || forceMinWidth
                    ? minWidth
                    : DEFAULT_MIN_DRAWER_WIDTH,
            maxWidth: forceWidth ? maxWidth : 'auto',
            maxHeight: `${maxContentHeight}px`
        }"
        ref="drawer"
    >
        <header
            class="d-d-flex d-ai-center d-bb d-bc-subtle d-pl16 d-py12 d-pr12"
            :class="{
                [headerClass]: !!headerClass?.length
            }"
            ref="drawerHeader"
        >
            <dt-button
                v-if="isSecondaryPage"
                class="d-ps-absolute d-l8 d-fc-black-900"
                kind="muted"
                importance="clear"
                @click="handleBackClick"
                size="sm"
            >
                <template #icon>
                    <dt-icon name="chevron-left" size="300" />
                </template>
            </dt-button>
            <div class="d-w100p d-pr32 d-d-flex d-fw-wrap">
                <slot name="header" />
            </div>
            <dt-button
                kind="muted"
                importance="clear"
                @click="closeDrawer"
                class="d-ml-auto d-ps-absolute d-r8 d-fc-black-900"
                size="sm"
                v-if="!hideClose"
            >
                <template #icon>
                    <dt-icon name="close" size="300" />
                </template>
            </dt-button>
        </header>
        <div v-if="$slots.subheader" ref="drawerSubheader">
            <slot name="subheader" />
        </div>
        <div
            :class="
                contentClass
                    ? contentClass
                    : 'd-p16 d-of-y-auto d-box-border d-fl-grow1'
            "
        >
            <slot />
        </div>
        <footer
            v-if="$slots.footer"
            class="d-d-flex d-ai-center d-px16 d-jc-flex-end d-bt d-bc-black-300"
            ref="drawerFooter"
        >
            <slot name="footer" />
        </footer>
        <slot name="secondaryPages" />
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, inject } from 'vue';
import { DtButton, DtIcon } from '@dialpad/dialtone/vue3';
import type { DrawerService } from '@/services/Drawer.service';
import { DEFAULT_MIN_DRAWER_WIDTH } from '@/utils/Constants';

export default defineComponent({
    props: {
        isOpen: {
            type: Boolean as PropType<boolean>,
            default: false
        },

        hideClose: {
            type: Boolean as PropType<boolean>,
            default: false
        },

        hasOverlay: {
            type: Boolean as PropType<boolean>,
            default: false
        },

        contentClass: {
            type: [String, Object, Array] as PropType<
                string | string[] | any[]
            >,
            default: ''
        },
        isSecondaryPage: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        drawerContentHeight: {
            type: Number as PropType<number>,
            default: 0
        },
        forceMinWidth: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        forceWidth: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        headerClass: {
            type: String as PropType<string>,
            default: ''
        }
    },
    components: {
        DtButton,
        DtIcon
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    computed: {
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        drawerWidth(): string {
            return this.$store.getters[`${this.orgId}/drawerWidth`];
        },
        minWidth(): string {
            if (this.drawerWidth) return `calc(${this.drawerWidth} - 1px)`;
            else return '';
        },
        maxWidth(): string {
            if (this.drawerWidth) return `calc(${this.drawerWidth} - 1px)`;
            else return '';
        },
        maxContentHeight(): number {
            return this.$store.getters[`${this.orgId}/maxContentHeight`];
        },
        DEFAULT_MIN_DRAWER_WIDTH(): string {
            return DEFAULT_MIN_DRAWER_WIDTH;
        }
    },
    updated() {
        this.calculateDrawerContentHeight();
    },
    mounted() {
        this.calculateDrawerContentHeight();
    },
    methods: {
        calculateDrawerContentHeight() {
            const contentMaxHeight =
                this.$refs.drawer?.clientHeight -
                (this.$refs.drawerHeader?.clientHeight || 0) -
                (this.$refs.drawerSubheader?.clientHeight || 0) -
                (this.$refs.drawerFooter?.clientHeight || 0);
            this.$emit('update:drawerContentHeight', contentMaxHeight);
        },
        closeDrawer() {
            this.drawerService?.closeDrawer();
            this.$emit('drawerTemplateClose');
        },
        handleBackClick() {
            this.$emit('back');
        }
    }
});
</script>
