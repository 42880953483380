<template>
    <div class="d-h100p d-d-flex d-fd-column">
        <div
            class="d-d-flex d-ai-flex-start d-px24 d-pt24 d-h84 d-box-border d-bgc-primary d-zi-navigation d-fl-shrink0"
            ref="title"
        >
            <div class="d-d-flex d-fw-wrap d-ai-center d-w100p">
                <div v-if="isFetching" class="d-w50p">
                    <dt-skeleton
                        arial-label="Loading"
                        :paragraphOption="{
                            rows: 1,
                            rowClass: 'd-h32 d-w332'
                        }"
                    />
                    <dt-skeleton
                        arial-label="Loading"
                        :paragraphOption="{
                            rows: 1,
                            rowClass: 'd-h12 d-w332 d-mt12'
                        }"
                    />
                </div>
                <div v-else>
                    <h1
                        class="d-headline-extra-large d-truncate d-tt-capitalize"
                    >
                        {{ $t(appType) }}
                    </h1>
                    <div class="d-w100p d-body-base d-fc-tertiary">
                        {{ bylineCopy }}
                    </div>
                </div>
            </div>
            <div class="d-ml-auto d-d-flex d-as-flex-start">
                <dt-button
                    class="d-truncate"
                    importance="primary"
                    size="xs"
                    @click="openCreateChatbotDrawer"
                >
                    {{ createChatbotCopy }}
                </dt-button>
            </div>
        </div>
        <div
            class="d-pl24 d-pt16 d-box-border d-fl-grow1 d-d-flex d-fd-column d-of-hidden"
        >
            <div
                class="d-d-flex d-ai-center d-pb16 d-box-border d-bgc-primary d-zi-navigation d-mt4"
            >
                <div class="d-w332">
                    <dt-input
                        class="d-fs-100"
                        v-model="search"
                        :placeholder="chatbotPlaceholder"
                        size="lg"
                        @input="doSearch"
                    >
                        <template #leftIcon>
                            <dt-icon name="search" size="200" />
                        </template>
                        <template #rightIcon>
                            <dt-button
                                v-if="search"
                                kind="muted"
                                importance="clear"
                                size="xs"
                                circle
                                aria-label="Clear search"
                                @click="handleClearInput"
                            >
                                <template #icon>
                                    <dt-icon name="close" size="200" />
                                </template>
                            </dt-button>
                        </template>
                    </dt-input>
                </div>

                <div class="d-ml8">
                    <filter-popover
                        :id="
                            isAiAgent
                                ? 'selfServiceFiltering'
                                : 'aiAssistFiltering'
                        "
                        title="status"
                        :fetch="fetchApps"
                        type="radio"
                        v-model:selected-options="statusSelectedFilters"
                        :options="filters"
                        :is-fetching="isFetching"
                        :total-hits="totalItems"
                        capitalize
                        hide-checkboxes
                        close-on-select
                        dialog-style="d-w164"
                    />
                </div>
            </div>
            <chatbot-list
                :app-type="appType"
                :is-fetching="isFetching"
                :chatbots="widgets"
                :edit-knowledge="editKnowledge"
                :fetch-apps="fetchApps"
                :get-chatbot-name="getChatbotName"
                :open-edit-chatbot-drawer="openEditChatbotDrawer"
                :open-widget-drawer="handleOpenDrawer"
                :update-app="updateApp"
                :dss-drawer-open="drawerOpen"
                :edit-drawer-open="editDrawerOpen"
                :selected-chatbot="selectedChatbot"
                :has-filters="hasFilters"
                :merge-client-open="isMergeClientOpen"
                :open-merge-client-drawer="
                    isAiAgent ? openMergeClientDrawer : undefined
                "
                :koopid-instances="isAiAgent ? koopidInstances : undefined"
            >
                <template #emptyResult>
                    <empty-state
                        v-if="shouldShowEmptyState"
                        :item-type="appType"
                        :has-filters="hasFilters"
                    >
                        <template #title>
                            <span>{{ `${$t(`No ${appType}`)}` }}</span>
                        </template>
                        <template #subtitle>
                            <dt-button
                                link
                                class="d-truncate d-td-none d-td-underline"
                                importance="primary"
                                size="xs"
                                @click="openCreateChatbotDrawer"
                            >
                                {{ $t('Create one') }}
                            </dt-button>
                        </template>
                    </empty-state>
                </template>
            </chatbot-list>
        </div>

        <div class="chatbot-content-loader">
            <template v-if="isAiAgent && !limited">
                <merge-client
                    :open="isMergeClientOpen"
                    :koopid-bot="selectedMergeClientData"
                    :close-chatbot-drawer="onCloseChatbotDrawer"
                />
            </template>
            <template v-else>
                <web-component-loader
                    v-model:loading="isLoading"
                    :source="aiAssistChatbotUrl"
                />
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, h, inject, type PropType } from 'vue';
import { useRoute } from 'vue-router';

import {
    DtButton,
    DtIcon,
    DtInput,
    DtSkeleton,
    DtTab,
    DtTabGroup,
    DtTabPanel,
    DtToast,
    DtTooltip
} from '@dialpad/dialtone/vue3';

import { APP_TYPE, APP_TYPES } from '@/utils/types/App';
import { DRAWER_WIDTH, WIDGET_DEFAULT_NAME } from '@/utils/Constants';
import {
    capitalizeString,
    convertAppTypeToLicenseType,
    createDebounce,
    type IFilter,
    replaceQueryFilterParams,
    uuidv4
} from '@/utils/Common';
import type { KoopidBot, KoopidInstance } from '@/utils/koopid';

import type { Knowledgebase } from '@/open-api';
import {
    KnowledgebaseStatus,
    LicenseType,
    NodeStatus,
    type Widget,
    WidgetStatus
} from '@/open-api';

import type { IBaseTableRow } from '@/components/base-table/BaseTable.types';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import ChatbotDrawer from '@/components/chatbot-drawer/ChatbotDrawer.vue';
import WebComponentLoader from '@/components/webcomponent-loader/WebComponentLoader.vue';
import AiAssistDrawer from '@/components/ai-assist-drawer/AiAssistDrawer.vue';
import type { Drawer, DrawerService } from '@/services/Drawer.service';
import ChatbotList from '@/components/chatbot-list/ChatbotList.vue';
import FilterPopover from '@/components/filter-popover/FilterPopover.vue';
import MergeClient from '@/components/merge-client/MergeClient.vue';
import EmptyState from '@/components/empty-state/EmptyState.vue';

import type { ApiService } from '@/services/Api.service';
import type { fetchPayload, WidgetInstance } from '@/store/WidgetsModule';
import { EventBusService } from '@/services/EventBus.service';
import { Topic } from '../../backend/pubsub';
import type { INotification } from '@/utils/types/Notification';
import { useStore } from 'vuex';

export default defineComponent({
    props: {
        appType: {
            type: String as PropType<APP_TYPES>,
            required: true
        }
    },
    components: {
        MergeClient,
        DtToast,
        DtTabGroup,
        DtTab,
        DtTabPanel,
        DtButton,
        DtInput,
        DtIcon,
        DtSkeleton,
        DtTooltip,
        FilterPopover,
        ChatbotList,
        AiAssistDrawer,
        WebComponentLoader,
        ChatbotDrawer,
        DrawerTemplate,
        EmptyState
    },
    setup() {
        const orgId: string = inject('orgId')!;
        const $route = useRoute();
        const selectedStatus = $route.query.status
            ? [$route.query.status?.toString()]
            : [NodeStatus.Published];
        const store = useStore();

        const aiAssistChatbotUrl = `https://virtual-assistant.${store.getters[`${orgId}/zone`]}.karehq.com/latest.js`;
        return {
            debounce: createDebounce(),
            orgId,
            drawer: ($route.query.drawer || undefined) as
                | 'edit'
                | 'create'
                | 'dss'
                | 'aiAssist'
                | undefined,
            selectedStatus,
            aiAssistChatbotUrl
        };
    },
    computed: {
        /* v8 ignore next 200 */
        limited(): boolean {
            return this.$store.getters[`${this.orgId}/limited`];
        },
        totalItems(): number {
            return this.selectedChatbots.length
                ? this.selectedChatbots.length
                : this.totalHits;
        },
        hasFilters(): boolean {
            return !!this.search.length;
        },
        APP_TYPES() {
            return APP_TYPES;
        },
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        companyId(): string | undefined {
            return this.$store.getters[`${this.orgId}/companyId`];
        },
        editDrawerOpen(): boolean {
            return this.drawerActive === 'edit';
        },
        drawerOpen(): boolean {
            return (
                this.drawerActive ===
                (this.isAiAgent
                    ? 'dss'
                    : this.isAiSalesAssistant
                      ? 'salesAssist'
                      : 'aiAssist')
            );
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        isAiAgent(): boolean {
            return this.appType === APP_TYPES.SELF_SERVICE;
        },
        isAiSalesAssistant(): boolean {
            return this.appType === APP_TYPES.SALES_ASSIST;
        },
        isAiAssistant(): boolean {
            return this.appType === APP_TYPES.AGENT_ASSIST;
        },
        drawerActive: {
            get():
                | 'edit'
                | 'create'
                | 'dss'
                | 'aiAssist'
                | 'salesAssist'
                | undefined {
                return this.drawer;
            },
            set(active: any) {
                this.drawer = active;
                let query = this.$route.query;
                if (active) {
                    query = {
                        ...this.$route.query,
                        drawer: active
                    };
                } else {
                    query = Object.assign({}, this.$route.query);
                    if (query.drawer) delete query.drawer;
                }
                this.$router.replace({ ...this.$route.query, query });
            }
        },
        statusSelectedFilters: {
            get(): string[] {
                return this.selectedStatus;
            },
            set(status: string[]) {
                this.selectedStatus.length = 0;
                if (status[0]) this.selectedStatus.push(status[0]);
                this.onStatusFilterChange();
            }
        },
        isMergeClientOpen(): boolean {
            return this.selectedMergeClientData !== undefined;
        },
        widgets(): WidgetInstance[] {
            return this.$store.getters[`${this.orgId}/widgets/list`];
        },
        isFetching(): boolean {
            return this.$store.getters[`${this.orgId}/widgets/fetching`];
        },
        hasError(): boolean {
            return this.$store.getters[`${this.orgId}/widgets/error`];
        },
        totalHits(): number {
            return this.$store.getters[`${this.orgId}/widgets/totalHits`];
        },
        shouldShowEmptyState(): boolean {
            return !this.widgets.length && !this.isFetching && !this.hasError;
        },
        chatbotPlaceholder(): string {
            switch (true) {
                case this.isAiAgent: {
                    return this.$t(`Search ${APP_TYPES.SELF_SERVICE}`);
                }
                case this.isAiAssistant:
                case this.isAiSalesAssistant: {
                    return this.$t(`Search ${APP_TYPES.AGENT_ASSIST}`);
                }
                default:
                    return this.$t('Search');
            }
        },
        bylineCopy(): string {
            switch (true) {
                case this.isAiAgent: {
                    return this.$t(
                        'Provide instant answers to customer questions using your support documents.'
                    );
                }
                case this.isAiAssistant:
                case this.isAiSalesAssistant: {
                    return this.$t(
                        'Deliver everything your agents need to resolve customer requests.'
                    );
                }
                default:
                    return '';
            }
        },
        createChatbotCopy(): string {
            switch (true) {
                case this.isAiAgent: {
                    return this.$t(`Create ${APP_TYPE.SELF_SERVICE}`);
                }
                case this.isAiAssistant:
                case this.isAiSalesAssistant: {
                    return this.$t(`Create ${APP_TYPE.AGENT_ASSIST}`);
                }
                default:
                    return '';
            }
        }
    },
    mounted() {
        this.drawerActive = this.drawer;
        this.fetchApps();

        this.widgetEventBus = new EventBusService(
            this.orgId,
            Topic.widget_event,
            convertAppTypeToLicenseType(this.appType)!,
            undefined,
            {
                created: this.handleCreateNodeEvent,
                updated: this.handleUpdateNodeEvent,
                deleted: this.handleDeleteNodeEvent
            }
        );
    },
    unmounted() {
        this.widgetEventBus?.destroy();
    },
    watch: {
        search() {
            this.selectedChatbots = [];
        },
        drawerActive(newDrawerActive) {
            if (newDrawerActive !== 'dss')
                this.selectedMergeClientData = undefined;
        },
        widgets(newWidgets) {
            if (!this.selectedChatbot) return;

            if (
                !newWidgets.find(
                    (widget: Widget) => this.selectedChatbot.id === widget.id
                )
            ) {
                this.drawerService?.closeDrawer();
                if (
                    this.drawerActive === 'dss' ||
                    this.drawerActive === 'aiAssist' ||
                    this.drawerActiver === 'salesAssist'
                ) {
                    this.onCloseChatbotDrawer();
                } else this.closeEditChatbotDrawer();

                if (!this.changeStatusClicked) {
                    this.$store.commit(`${this.orgId}/addNotification`, {
                        kind: 'error',
                        title: `${this.$t('This widget is no longer in this page')}`
                    } as INotification);
                }
                this.changeStatusClicked = false;
                return;
            }
        }
    },
    emits: ['toggleExpand', 'change'],
    methods: {
        getChatbotName(app: Widget): string {
            return app.name
                ? app.name
                : this.isAiAgent
                  ? 'Self service chatbot'
                  : WIDGET_DEFAULT_NAME;
        },
        async fetchApps() {
            await this.$store.dispatch(`${this.orgId}/widgets/fetch`, {
                licenseType: convertAppTypeToLicenseType(this.appType)!,
                search: this.search,
                status: this.statusSelectedFilters[0]
            } as fetchPayload);
        },
        async updateApp(app: Widget) {
            let appKey: Widget | undefined = this.widgets.find(
                (w: Widget) => w.id === app.id
            );
            if (!appKey) return;

            appKey = {
                name: app.name,
                status: app.status
            };

            await this.$store.dispatch(`${this.orgId}/widgets/update`, {
                appId: app.id!,
                widget: appKey
            });
        },
        closeEditChatbotDrawer(shouldFetch?: boolean) {
            this.drawerActive = undefined;
            this.selectedChatbot = undefined;
            this.drawerService?.closeDrawer();
            if (shouldFetch) {
                this.fetchApps();
            }

            this.chatbotData = undefined;
        },
        openEditChatbotDrawer(event?: any, chatbotData?: any) {
            this.drawerActive = 'edit';
            this.selectedChatbot = chatbotData;
            event?.stopPropagation();
            this.chatbotData = chatbotData;
            const editChatbotDrawer = h(ChatbotDrawer, {
                chatbotType: convertAppTypeToLicenseType(this.appType),
                chatbotData,
                onClose: this.closeEditChatbotDrawer,
                onSuccess: this.onSuccess
            });

            const drawer: Drawer = {
                id: uuidv4(),
                name: 'editChatbot',
                componentInstance: editChatbotDrawer
            };
            this.drawerService?.toggleDrawer(drawer);
        },
        openCreateChatbotDrawer(event?: any) {
            this.drawerActive = 'create';
            event?.stopPropagation();

            const createChatbotDrawer = h(ChatbotDrawer, {
                chatbotType: convertAppTypeToLicenseType(this.appType),
                onKnowledgebaseError: this.onKnowledgebaseError,
                onClose: this.onCloseChatbotDrawer,
                callback: this.onCreateChatbotDrawerOpen
            });

            const drawer: Drawer = {
                id: uuidv4(),
                name: 'createChatbot',
                componentInstance: createChatbotDrawer
            };
            this.drawerService?.toggleDrawer(drawer);
        },
        handleKnowledgeBaseClick(
            knowledgebases: Knowledgebase[],
            app: KoopidInstance
        ) {
            const defaultKb = knowledgebases.find(
                (kb: Knowledgebase) => kb.locale === app.default_locale
            );
            this.$store.commit(
                `${this.orgId}/setCurrentKnowledgeBase`,
                defaultKb
            );
            this.$store.commit(`${this.orgId}/setCurrentChatbot`, app);
            this.$router.push({
                name: 'improve',
                params: {
                    licenseType:
                        app.license_type === LicenseType.Dss
                            ? 'self-service'
                            : app.license_type === LicenseType.Asa
                              ? 'sales-assist'
                              : 'agent-assist',
                    appId: app.id,
                    kbId: defaultKb.id
                }
            });
        },
        handleOpenDrawer(event?: any, app?: WidgetInstance) {
            this.drawerActive = this.isAiAgent ? 'dss' : 'aiAssist';
            this.selectedChatbot = app;
            event?.stopPropagation();
            if ((this.isAiAgent && !this.limited) || !app) return;
            else {
                const aiAssistDrawer = h(AiAssistDrawer, {
                    appId: app.id,
                    appName: app.name!,
                    defaultLocale: app.default_locale,
                    aiAssistLoading: this.isLoading,
                    onClose: this.onCloseChatbotDrawer
                });

                const drawer: Drawer = {
                    id: uuidv4(),
                    name: 'aiAssist',
                    componentInstance: aiAssistDrawer,
                    width: DRAWER_WIDTH
                };
                this.drawerService?.toggleDrawer(drawer);
            }
        },
        openMergeClientDrawer(app: WidgetInstance, selectedEntry: KoopidBot) {
            this.drawerActive = 'dss';
            this.selectedChatbot = app;
            this.drawerService?.closeDrawer();
            if (!app) return;
            this.selectedMergeClientData = selectedEntry;
        },
        editKnowledge(
            e: Event,
            chatbotData: Widget,
            knowledgebases: Knowledgebase[]
        ) {
            e.stopPropagation();
            const hasPublishedKbs = knowledgebases.some(
                (kb) => kb.status === KnowledgebaseStatus.Published
            );
            if (!hasPublishedKbs) {
                this.$store.commit(`${this.orgId}/addNotification`, {
                    message:
                        'This chatbot has no published knowledgebases to edit.',
                    kind: 'error'
                });
                return;
            }
            this.selectedChatbot = undefined;
            if (knowledgebases?.length && chatbotData) {
                this.handleKnowledgeBaseClick(knowledgebases, chatbotData);
            }
        },
        onKnowledgebaseError(app: Widget) {
            this.openEditChatbotDrawer(undefined, app);
        },
        onSuccess(chatbotStatus: any) {
            this.drawerActive = undefined;
            this.selectedChatbot = undefined;
            this.drawerService?.closeDrawer();
            if (chatbotStatus)
                this.$store.commit(
                    `${this.orgId}/addNotification`,
                    chatbotStatus
                );
            this.fetchApps();
        },
        onCloseChatbotDrawer(shouldFetch?: boolean) {
            this.drawerActive = undefined;
            this.selectedChatbot = undefined;
            this.drawerService?.closeDrawer();
            if (shouldFetch) {
                this.fetchApps();
            }

            this.chatbotData = undefined;
            this.selectedMergeClientData = undefined;
        },
        handleClearInput() {
            this.search = '';
            this.fetchApps();
        },
        async doSearch() {
            this.debounce(this.fetchApps);
        },
        onStatusFilterChange() {
            replaceQueryFilterParams(
                'status',
                this.statusSelectedFilters,
                this.$route,
                this.$router
            );
        },
        onCreateChatbotDrawerOpen() {
            this.selectedChatbot = undefined;
        },
        handleCreateNodeEvent(message: Widget) {
            this.$store.dispatch(
                `${this.orgId}/widgets/handleWidgetCreate`,
                message
            );
        },
        handleUpdateNodeEvent(message: Widget) {
            this.$store.dispatch(
                `${this.orgId}/widgets/handleWidgetUpdate`,
                message
            );
        },
        handleDeleteNodeEvent(message: Widget) {
            this.$store.dispatch(
                `${this.orgId}/widgets/handleWidgetDelete`,
                message
            );
        }
    },
    data() {
        return {
            WidgetStatus,
            chatbotData: undefined as WidgetInstance | undefined,
            search: '',
            nodeStatus: NodeStatus,
            editMode: false,
            selectedChatbot: undefined as WidgetInstance | undefined,
            selectedChatbots: [] as IBaseTableRow[],
            isLoading: true,
            filters: [
                ...Object.values(WidgetStatus).map((status) => ({
                    label: this.$t(capitalizeString(status)),
                    value: status
                }))
            ] as IFilter[],
            selectedFilters: [WidgetStatus.Published] as WidgetStatus[],
            koopidInstances: [] as KoopidInstance[],
            selectedMergeClientData: undefined as KoopidBot | undefined,
            widgetEventBus: undefined as EventBusService | undefined
        };
    }
});
</script>
