<template>
    <div
        class="d-bgc-primary d-bb d-bbw1 d-bc-subtle d-d-flex d-fl-grow1 d-w100p d-fd-column"
    >
        <div class="d-d-flex d-pt12 d-pb4 d-px16 d-jc-space-between">
            <span class="d-headline-large d-w75p">
                {{ chatbotTitle }}
                <ai-badge
                    :chatbot="chatbot"
                    style-class="d-ml4 d-d-inline-block"
                />
            </span>
            <div class="d-d-flex d-ai-flex-start d-fl-shrink0">
                <dt-button
                    importance="clear"
                    kind="muted"
                    @click="goBack"
                    size="sm"
                    class="d-mr4"
                >
                    <template #icon>
                        <dt-icon name="chevron-left" size="200" />
                    </template>
                    {{ $t(`All ${chatbotNamePluralized}`) }}
                </dt-button>
                <dt-tooltip
                    class="d-d-flex"
                    placement="bottom"
                    :message="$t('View analytics')"
                >
                    <template #anchor>
                        <dt-button
                            importance="clear"
                            kind="muted"
                            size="sm"
                            class="d-mr4"
                            @click="handleOnClickAnalytics"
                        >
                            <template #icon>
                                <dt-icon name="trending-up" size="200" />
                            </template>
                            <template #default>
                                {{ $t('Analytics') }}
                            </template>
                        </dt-button>
                    </template>
                </dt-tooltip>

                <dt-tooltip
                    hidden
                    placement="bottom"
                    :message="`${$t('Try now')} ${limited ? '(' + $t('limited') + ')' : ''}`"
                >
                    <template #anchor>
                        <dt-button
                            class="d-mr4"
                            kind="muted"
                            importance="clear"
                            :disabled="
                                chatbot?.status !== NodeStatus?.Published
                            "
                            :active="isDssDrawerOpen"
                            @click="handleOpenTryNow($event)"
                        >
                            <template #icon>
                                <dt-icon name="play" size="200" />
                            </template>
                        </dt-button>
                    </template>
                </dt-tooltip>
                <dt-tooltip
                    hidden
                    :message="$t(`Configure ${chatbotName}`)"
                    placement="bottom"
                >
                    <template #anchor>
                        <dt-button
                            class="d-mr4"
                            kind="muted"
                            importance="clear"
                            :active="isEditDrawerOpen"
                            @click="openEditChatbotDrawer"
                        >
                            <template #icon>
                                <dt-icon name="settings" size="200" />
                            </template>
                        </dt-button>
                    </template>
                </dt-tooltip>
                <dt-tooltip
                    class="d-d-flex"
                    placement="bottom"
                    :message="
                        $t(
                            'Channels connect Ai Agents to contact centers. Configure and deploy it now.'
                        )
                    "
                    :show="shouldShowTooltip || shouldFirstShow"
                    @mouseenter="showHoverTooltip"
                    @mouseleave="hideHoverTooltip"
                >
                    <template #anchor>
                        <div class="d-d-flex d-ai-flex-start">
                            <dt-button
                                v-show="channelURLs?.length <= 1"
                                importance="clear"
                                kind="muted"
                                size="sm"
                                @click="handleOnClickChannel(0)"
                                :disabled="
                                    !channelURLs?.length && !isChannelsLoading
                                "
                            >
                                <template #icon>
                                    <dt-icon name="settings" size="200" />
                                </template>
                                <template #default>
                                    {{ $t('Configure channel') }}
                                </template>
                            </dt-button>
                            <menu-popover
                                v-show="channelURLs?.length > 1"
                                id="chatbotActionButtons"
                                :options="channels"
                                append-to="body"
                                icon-size="200"
                                placement="bottom-start"
                                :button-title="$t('Configure channel')"
                                button-size="sm"
                                icon-name="settings"
                            />
                            <div
                                v-if="showDot"
                                class="d-bar-circle d-w8 d-h8 d-bgc-purple-400"
                                style="margin-left: -4px; margin-top: -4px"
                            ></div>
                        </div>
                    </template>
                </dt-tooltip>
                <dt-select-menu
                    select-class="d-ml8"
                    :disabled="isFetching"
                    @change="onChangeKb"
                    :value="activeKb"
                    size="xs"
                    v-if="knowledgebases.length > 1"
                >
                    <option
                        v-for="option in knowledgebases"
                        :key="`select-menu-option-${option.id}`"
                        :value="option.id"
                        :disabled="isDisabled(option)"
                    >
                        {{ convertLocaleToLanguage(option.locale) }}
                    </option>
                </dt-select-menu>
                <div
                    v-else
                    class="d-body--sm d-fw-medium d-pr6 d-ml8 d-d-flex d-ai-center d-fc-secondary d-h100p"
                >
                    <span
                        class="d-w1 d-bl d-bc-subtle"
                        style="height: 1.8rem"
                    />
                    <span class="d-pl16">
                        {{
                            convertLocaleToLanguage(knowledgebases?.[0]?.locale)
                        }}
                    </span>
                </div>
            </div>
        </div>
        <knowledge-menu-tabs
            class="d-px16"
            v-model:active-tab="active"
            v-if="active"
        />
    </div>

    <!--    <div class="chatbot-content-loader">-->
    <!--        <template v-if="isSelfService && !limited">-->
    <!--            <merge-client-->
    <!--                :open="isMergeClientOpen"-->
    <!--                :koopid-bot="selectedMergeClientData"-->
    <!--                @close="handleCloseTryNow"-->
    <!--            />-->
    <!--        </template>-->
    <!--        <template v-else>-->
    <!--            <web-component-loader-->
    <!--                v-model:loading="isLoading"-->
    <!--                :source="aiAssistChatbotUrl"-->
    <!--            />-->
    <!--        </template>-->
    <!--    </div>-->
</template>

<script lang="ts">
import { defineComponent, h, inject } from 'vue';
import { useRoute } from 'vue-router';

import store from '@/store';

import {
    type Knowledgebase,
    type ListKnowledgebasesResponse,
    type Widget,
    NodeStatus,
    KnowledgebaseStatus,
    LicenseType,
    WidgetStatus
} from '@/open-api';

import KnowledgeMenuTabs from '@/components/knowledge-menu/KnowledgeMenuTabs.vue';
import AiBadge from '@/components/ai-badge/AiBadge.vue';
import ChatbotDrawer from '@/components/chatbot-drawer/ChatbotDrawer.vue';
import AiAssistDrawer from '@/components/ai-assist-drawer/AiAssistDrawer.vue';
import MergeClient from '@/components/merge-client/MergeClient.vue';
import WebComponentLoader from '@/components/webcomponent-loader/WebComponentLoader.vue';
import MenuPopover from '@/components/menu-popover/MenuPopover.vue';
import { IMenuPopoverOptions } from '@/components/menu-popover/MenuPopover.types';

import {
    ANALYTICS_PATH,
    DRAWER_WIDTH,
    KNOWLEDGE_ROUTES,
    WIDGET_DEFAULT_NAME,
    ZONES_ABBR
} from '@/utils/Constants';
import {
    convertLocaleToLanguage,
    createDebounce,
    handleRequest,
    uuidv4
} from '@/utils/Common';
import {
    getSocialChannels,
    KoopidBot,
    KoopidInstance,
    SocialChannelEntity
} from '@/utils/koopid';
import { APP_BY_LICENSE_TYPE } from '@/utils/types/App';

import type { ApiService } from '@/services/Api.service';
import type { Drawer, DrawerService } from '@/services/Drawer.service';

import {
    DtButton,
    DtIcon,
    DtSelectMenu,
    DtBadge,
    DtTooltip
} from '@dialpad/dialtone/vue3';

export default defineComponent({
    components: {
        WebComponentLoader,
        MergeClient,
        DtButton,
        DtIcon,
        DtSelectMenu,
        DtBadge,
        DtTooltip,
        KnowledgeMenuTabs,
        AiBadge,
        MenuPopover
    },
    mounted() {
        this.getEntitySocialChannels();

        if (
            this.currentUserInfo?.hasFetched &&
            !this.currentUserInfo?.hasClicked &&
            this.channelURLs?.length
        ) {
            this.showDot = true;
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        const route = useRoute();
        const active = route?.name?.toString();
        const activeKb = route?.params?.kbId;
        return {
            debounce: createDebounce(),
            orgId,
            active,
            activeKb
        };
    },
    watch: {
        /* v8 ignore next 15 */
        $route: {
            async handler() {
                if (!this.knowledgebases.length) {
                    this.knowledgebases = await this.fetchKbs(this.chatbot);
                }
                if (this.$route?.query?.knowledgebases) {
                    this.knowledgebases = JSON.parse(
                        this.$route.query.knowledgebases as any
                    );
                }
                await this.$nextTick();
                this.$forceUpdate();
            },
            immediate: true,
            deep: true
        },
        drawer(newDrawer) {
            if (newDrawer?.name === 'aiAssist') {
                this.isEditDrawerOpen = false;
                this.isDssDrawerOpen = true;
            } else if (newDrawer?.name === 'editChatbot') {
                this.isEditDrawerOpen = true;
                this.isDssDrawerOpen = false;
            } else {
                this.isEditDrawerOpen = false;
                this.isDssDrawerOpen = false;
            }
        },
        shouldFirstShow(newState) {
            if (newState) {
                this.showDot = true;
                this.showHoverTooltip();
                store.commit(`${this.orgId}/setHasFetchedSources`, {
                    ...this.hasFetchedSources,
                    [this.userId]: {
                        hasFetched: true,
                        hasShown: true,
                        hasClicked: false
                    }
                });
                setTimeout(() => {
                    this.hideHoverTooltip();
                }, 3000);
            }
        }
    },
    methods: {
        convertLocaleToLanguage,
        goBack() {
            if (this.chatbot?.license_type === LicenseType.Aaa) {
                this.$router.push('/agent-assist');
            } else if (this.chatbot?.license_type === LicenseType.Dss)
                this.$router.push('/self-service');
            else if (this.chatbot?.license_type === LicenseType.Asa)
                this.$router.push('/sales-assist');
        },
        onChangeKb(kbId: any) {
            if (!kbId || this.activeKb === kbId) return;

            const newKb = this.knowledgebases.find(
                (kb: Knowledgebase) => kb.id === kbId
            );
            if (!newKb) return;
            this.activeKb = newKb.id;
            this.drawerService?.closeDrawer();
            this.$store.commit(`${this.orgId}/setCurrentKnowledgeBase`, newKb);

            // If knowledgebase is changed when we're inside the response editor, navigate to responses view
            if (this.active === 'node_editor') {
                this.$router.push({
                    name: 'improve',
                    params: {
                        licenseType: this.$route.params.licenseType,
                        appId: this.$route.params.appId,
                        kbId
                    }
                });
            } else
                this.$router.push({
                    name: this.active || undefined,
                    params: {
                        ...this.$route.params,
                        kbId
                    }
                });
        },
        isDisabled(kb: Knowledgebase): boolean {
            return kb.status !== KnowledgebaseStatus.Published;
        },
        async fetchKbs(chatbot: Widget): Promise<Knowledgebase[]> {
            const res = await handleRequest(
                this.apiService?.knowledge.listKnowledgebases(
                    this.authToken,
                    chatbot.id!
                ),
                this.orgId
            );

            const listKb: ListKnowledgebasesResponse = res.data;
            return listKb.knowledgebases || [];
        },
        openEditChatbotDrawer() {
            if (this.isEditDrawerOpen) return;

            const editChatbotDrawer = h(ChatbotDrawer, {
                chatbotData: this.chatbot,
                onSuccess: this.onSuccess,
                onClose: this.handleCloseEditChatbotDrawer
            });

            const drawer: Drawer = {
                id: uuidv4(),
                name: 'editChatbot',
                componentInstance: editChatbotDrawer
            };
            this.drawerService?.toggleDrawer(drawer);
            this.isEditDrawerOpen = true;
        },
        async onSuccess(res: any) {
            this.drawerService?.closeDrawer();
            this.knowledgebases = await this.fetchKbs(this.chatbot);
            if (res) this.$store.commit(`${this.orgId}/addNotification`, res);
            this.chatbot = res.chatbotData;
        },
        handleCloseEditChatbotDrawer() {
            this.isEditDrawerOpen = false;
        },
        shouldShowAssistSDK(): boolean {
            return (
                this.chatbot.license_type === LicenseType.Aaa ||
                this.chatbot.license_type === LicenseType.Asa ||
                (this.chatbot.license_type === LicenseType.Dss &&
                    this.isCSROrLocalDevelopment)
            );
        },
        handleOpenTryNow(event?: any) {
            event?.stopPropagation();
            if (
                this.isDssDrawerOpen ||
                (this.chatbot.license_type === LicenseType.Dss && !this.limited)
            ) {
                return;
            } else {
                const aiAssistDrawer = h(AiAssistDrawer, {
                    appId: this.chatbot.id,
                    appName: this.chatbot.name!,
                    aiAssistLoading: this.isLoading,
                    onClose: this.handleCloseTryNow
                });

                const drawer: Drawer = {
                    id: uuidv4(),
                    name: 'aiAssist',
                    componentInstance: aiAssistDrawer,
                    width: DRAWER_WIDTH
                };
                this.drawerService?.toggleDrawer(drawer);
                this.isDssDrawerOpen = true;
            }
        },
        handleCloseTryNow() {
            this.isDssDrawerOpen = false;
        },
        handleOnClickAnalytics() {
            const origin = window.location.origin;
            let deepLinkAnalytics = undefined;

            const path = this.isSelfService
                ? ANALYTICS_PATH.AI_AGENT([
                      {
                          id: this.chatbot.id,
                          locale: this.knowledgebase.locale!
                      }
                  ])
                : ANALYTICS_PATH.AI_ASSISTANT([
                      {
                          id: this.chatbot.id,
                          locale: this.knowledgebase.locale!
                      }
                  ]);

            if (origin.startsWith('https://dialpad')) {
                deepLinkAnalytics = new URL(path, origin);
            } else {
                // Try to redirect to dialpad URL depending on kare zone
                const fallbackOrigin =
                    this.zone === ZONES_ABBR.DEVELOP ||
                    this.zone === ZONES_ABBR.STAGING
                        ? 'https://dialpadbeta.com'
                        : 'https://dialpad.com';
                deepLinkAnalytics = new URL(path, fallbackOrigin);
            }
            window.open(deepLinkAnalytics.toString(), '_blank').focus();
        },
        handleOnClickChannel(index: number): any {
            if (this.showDot && !this.hasFetchedSources.hasClicked) {
                store.commit(`${this.orgId}/setHasFetchedSources`, {
                    ...this.hasFetchedSources,
                    [this.userId]: {
                        hasFetched: true,
                        hasShown: true,
                        hasClicked: true
                    }
                });
                this.showDot = false;
            }
            window.open(
                `https://dialpadbeta.com/digitalchannels/edit/${this.channelURLs[index]}`,
                '_blank'
            );
        },
        async getEntitySocialChannels() {
            this.isChannelsLoading = true;
            try {
                const res = await getSocialChannels(
                    this.authToken,
                    this.koopid
                );

                if (res.data) {
                    const entryTags: SocialChannelEntity[] =
                        res.data.entity?.filter(
                            (entryTag: any) =>
                                entryTag.channelid ===
                                this.chatbot.bots.entrytags[0].channelid
                        );
                    if (entryTags.length > 1) {
                        this.channels = entryTags.map(
                            (entryTag: SocialChannelEntity, index: number) => ({
                                title: entryTag.name,
                                subtitle: entryTag.channeltypename,
                                action: () => this.handleOnClickChannel(index)
                            })
                        );
                    }
                    this.channelURLs = entryTags.map((entryTag) =>
                        btoa(
                            `${entryTag.channelid}|${entryTag.channeltype}|${entryTag.channelvendor}|${entryTag.name}`
                        )
                    );
                }
                this.isChannelsLoading = false;
            } catch (e) {
                this.isChannelsLoading = false;
                return false;
            }
        },
        showHoverTooltip() {
            this.shouldShowTooltip = true;
        },
        hideHoverTooltip() {
            this.shouldShowTooltip = false;
        }
    },
    /* v8 ignore next 33 */
    computed: {
        NodeStatus() {
            return NodeStatus;
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        chatbotTitle(): string {
            return this.chatbot?.name || WIDGET_DEFAULT_NAME;
        },
        isPublished(): boolean {
            return this.chatbot?.status === WidgetStatus.Published;
        },
        apiService(): ApiService {
            return store.getters[`${this.orgId}/apiService`];
        },
        authToken(): string {
            return store.getters[`${this.orgId}/authToken`];
        },
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        limited(): boolean {
            return this.$store.getters[`${this.orgId}/limited`];
        },
        isSelfService(): boolean {
            return this.chatbot?.license_type === LicenseType.Dss;
        },
        isMergeClientOpen(): boolean {
            return this.selectedMergeClientData !== undefined;
        },
        drawer() {
            return this.drawerService?.getOpenDrawer();
        },
        zone() {
            return this.$store.getters[`${this.orgId}/zone`];
        },
        aiAssistChatbotUrl(): string {
            return `https://virtual-assistant.${this.zone}.karehq.com/latest.js`;
        },
        chatbot(): KoopidInstance {
            return this.$store.getters[`${this.orgId}/currentChatbot`];
        },
        chatbotName(): string {
            return APP_BY_LICENSE_TYPE.SINGULAR[this.chatbot.license_type];
        },
        chatbotNamePluralized(): string {
            return APP_BY_LICENSE_TYPE.PLURAL[this.chatbot.license_type];
        },
        koopid(): string {
            return this.$store.getters[`${this.orgId}/koopid`];
        },
        hasFetchedSources: {
            get(): boolean {
                return this.$store.getters[`${this.orgId}/hasFetchedSources`];
            },
            set(value: any) {
                this.$store.commit(`${this.orgId}/setHasFetchedSources`, value);
            }
        },
        userId(): string {
            return this.$store.getters[`${this.orgId}/userId`];
        },
        shouldFirstShow(): boolean | null {
            return (
                this.currentUserInfo?.hasFetched &&
                !this.currentUserInfo?.hasShown
            );
        },
        currentUserInfo(): any {
            return this.hasFetchedSources[this.userId];
        }
    },
    data() {
        return {
            navigationItems: KNOWLEDGE_ROUTES,
            options: [] as { value: string; label: string }[],
            isFetching: false,
            knowledgebases: [] as Knowledgebase[],
            isEditDrawerOpen: false,
            isDssDrawerOpen: false,
            isLoading: true,
            selectedMergeClientData: undefined as KoopidBot | undefined,
            isChannelsLoading: false,
            channels: [] as IMenuPopoverOptions[],
            channelURLs: [] as string[],
            shouldShowTooltip: false as boolean,
            showDot: false as boolean
        };
    }
});
</script>
