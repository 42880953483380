import type {
    Knowledgebase,
    Widget,
    NodeDetail,
    GetStatusResponse
} from '../../src/open-api';

export enum CRUDEvent {
    created = 'created',
    updated = 'updated',
    deleted = 'deleted'
}

export enum Topic {
    node_event = 'mind.southbridge.v4dot0.node_event',
    org_event = 'mind.southbridge.v4dot0.org_event',
    widget_event = 'mind.southbridge.v4dot0.widget_event',
    knowledgebase_event = 'mind.southbridge.v4dot0.kb_event',
    import_status_event = 'mind.southbridge.v4dot0.import_status_event'
}

export type messageType =
    | WidgetEventMessage
    | KnowledgebaseEventMessage
    | NodeEventMessage
    | ImportStatusEventMessage;

export function getTopicLabel(topic: Topic) {
    switch (topic.toString()) {
        case Topic.node_event:
            return 'node_event';
        case Topic.widget_event:
            return 'widget_event';
        case Topic.knowledgebase_event:
            return 'knowledgebase_event';
        case Topic.import_status_event:
            return 'import_status_event';
        default:
            return 'empty';
    }
}

// Posted every time a theres a CRUD event on Widget
export interface WidgetEventMessage {
    // Which CRUD event took place on this widget
    event: CRUDEvent;

    // Organisation ID
    kare__org_id: string;

    // Kb before the crud action took place
    before?: Widget;

    // Final status of the widget after successfully
    // created and saved on the database
    after?: Widget;
}

// Posted every time a theres a CRUD event on knwoeldgebase
export interface KnowledgebaseEventMessage {
    // Which CRUD event took place on this knowledgbase
    event: CRUDEvent;

    // Organisation ID
    kare__org_id: string;

    // Kb before the crud action took place
    before?: Knowledgebase;

    // Final status of the knowledgebase after successfully
    // created and saved on the database
    after?: Knowledgebase;
}

// NodeEventMessage is used to notify pubsub subscribers that a response has been updated.
export interface NodeEventMessage {
    event: CRUDEvent;

    // Organisation ID
    kare__org_id: string;

    // Knowledgebase ID
    kare__kb_id: string;

    before?: NodeDetail;
    after?: NodeDetail;
}

export enum SOURCES {
    BOX = 'box',
    DOC360 = 'document360',
    GDRIVE = 'gdrive',
    ZENDESK = 'zendesk',
    WEBCRAWLER = 'webcrawler'
}

export interface ImportStatus extends GetStatusResponse {
    ingestion_source: SOURCES;
}

// ImportStatusMessage is used to notify pubsub subscribers of the status of an Import job
export interface ImportStatusEventMessage {
    event: CRUDEvent;

    // Organisation ID
    kare__org_id: string;

    // Knowledgebase ID
    kare__kb_id: string;

    // Source of the Status
    ingestion_source: string;

    before?: ImportStatus;
    after?: ImportStatus;
}
