/* tslint:disable */
/* eslint-disable */
/**
 * Mind
 * Kare API v4.0
 *
 * The version of the OpenAPI document: 4.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetConversationResponse } from '../model';
// @ts-ignore
import { ListConversationEvents } from '../model';
// @ts-ignore
import { ListConversationsResponse } from '../model';
// @ts-ignore
import { ListEvents } from '../model';
// @ts-ignore
import { ModelError } from '../model';
/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the details of a single conversation. 
         * @summary Get conversation detail
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} conversationId The conversation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation: async (authorization: string, appId: string, conversationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getConversation', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('getConversation', 'appId', appId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getConversation', 'conversationId', conversationId)
            const localVarPath = `/activity/{app_id}/conversations/{conversation_id}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeActivityRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all events for a given conversation. 
         * @summary List conversation events
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} conversationId The conversation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConversationEvents: async (authorization: string, appId: string, conversationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listConversationEvents', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('listConversationEvents', 'appId', appId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('listConversationEvents', 'conversationId', conversationId)
            const localVarPath = `/activity/{app_id}/conversations/{conversation_id}/events`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"conversation_id"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeActivityRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List conversations filtered by locale. Conversations are pagineted with a cursor. 
         * @summary List conversations
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} [userId] &#x60;DEPRECATED: Filter on the user ID.&#x60; 
         * @param {'any' | 'unresolved' | 'resolved' | 'handed-over' | 'escalated'} [status] &#x60;Filter on the conversation status.&#x60; 
         * @param {string} [referral] &#x60;DEPRECATED: Filter events using their referral page&#x60; 
         * @param {string} [labels] &#x60;Filter on labels&#x60; 
         * @param {number} [from] &#x60;Lower date bound on unix timestamp.&#x60; 
         * @param {number} [to] &#x60;Higher date bound on unix timestamp.&#x60; 
         * @param {string} [cursor] Pagination cursor to fetch additional pages
         * @param {number} [limit] \&#39;The maximum list size to be returned.\&#39; 
         * @param {string} [locale] &#x60;Filter on Locale&#x60; 
         * @param {string} [unansweredQuestions] Filter by unanswered questions. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [queries] Filter by queries. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [thumbsUp] Filter by the ammount of thumbs up feedback. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [thumbsDown] Filter by the ammount of thumbs down feedback. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [subject] Filter by subject
         * @param {'unanswered_questions' | 'queries' | 'thumbs_up' | 'thumbs_down' | 'created_at' | 'workflow' | 'last_message'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConversations: async (authorization: string, appId: string, userId?: string, status?: 'any' | 'unresolved' | 'resolved' | 'handed-over' | 'escalated', referral?: string, labels?: string, from?: number, to?: number, cursor?: string, limit?: number, locale?: string, unansweredQuestions?: string, queries?: string, thumbsUp?: string, thumbsDown?: string, subject?: string, sortBy?: 'unanswered_questions' | 'queries' | 'thumbs_up' | 'thumbs_down' | 'created_at' | 'workflow' | 'last_message', order?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listConversations', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('listConversations', 'appId', appId)
            const localVarPath = `/activity/{app_id}/conversations`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeActivityRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (referral !== undefined) {
                localVarQueryParameter['referral'] = referral;
            }

            if (labels !== undefined) {
                localVarQueryParameter['labels'] = labels;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }

            if (unansweredQuestions !== undefined) {
                localVarQueryParameter['unanswered_questions'] = unansweredQuestions;
            }

            if (queries !== undefined) {
                localVarQueryParameter['queries'] = queries;
            }

            if (thumbsUp !== undefined) {
                localVarQueryParameter['thumbs_up'] = thumbsUp;
            }

            if (thumbsDown !== undefined) {
                localVarQueryParameter['thumbs_down'] = thumbsDown;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * `List events with a given filter.` 
         * @summary List events
         * @param {string} authorization &#x60;Authorization Bearer header&#x60; 
         * @param {string} appId App ID
         * @param {string} locale Locale
         * @param {'meta/err' | 'meta/conversation' | 'meta/beacon' | 'in/hi' | 'in/bye' | 'in/query' | 'in/click' | 'in/message' | 'in/pull' | 'in/cx/answer' | 'in/cx/feedback' | 'out/cx/question' | 'out/text' | 'out/resources' | 'out/beacon' | 'out/not-found' | 'out/bye'} [uri] Message types are identified using a universal resource identifier (URI)  * &#x60;meta/XXX&#x60; are meta messages used to keep the communication between the client and the server.  *  &#x60;meta/XXX&#x60; are used to drive the conversation.  *  &#x60;in/XXX&#x60; are used for sending data to the engine.  *  &#x60;out/XXX&#x60; are used to receive data from the engine.  Clients should be able to send &#x60;in&#x60; messages and receive &#x60;out&#x60; messages. 
         * @param {string} [aspect] &#x60;The event Aspect, if any to filter on&#x60; 
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {string} [cursor] &#x60;Pagination cursor to fetch additional pages&#x60; 
         * @param {number} [limit] &#x60;Maximum page size&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvents: async (authorization: string, appId: string, locale: string, uri?: 'meta/err' | 'meta/conversation' | 'meta/beacon' | 'in/hi' | 'in/bye' | 'in/query' | 'in/click' | 'in/message' | 'in/pull' | 'in/cx/answer' | 'in/cx/feedback' | 'out/cx/question' | 'out/text' | 'out/resources' | 'out/beacon' | 'out/not-found' | 'out/bye', aspect?: string, from?: number, to?: number, cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('listEvents', 'authorization', authorization)
            // verify required parameter 'appId' is not null or undefined
            assertParamExists('listEvents', 'appId', appId)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('listEvents', 'locale', locale)
            const localVarPath = `/activity/{app_id}/events/{locale}`
                .replace(`{${"app_id"}}`, encodeURIComponent(String(appId)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerKnowledgeActivityRead required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (uri !== undefined) {
                localVarQueryParameter['uri'] = uri;
            }

            if (aspect !== undefined) {
                localVarQueryParameter['aspect'] = aspect;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the details of a single conversation. 
         * @summary Get conversation detail
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} conversationId The conversation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversation(authorization: string, appId: string, conversationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConversationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversation(authorization, appId, conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all events for a given conversation. 
         * @summary List conversation events
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} conversationId The conversation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConversationEvents(authorization: string, appId: string, conversationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConversationEvents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConversationEvents(authorization, appId, conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List conversations filtered by locale. Conversations are pagineted with a cursor. 
         * @summary List conversations
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} [userId] &#x60;DEPRECATED: Filter on the user ID.&#x60; 
         * @param {'any' | 'unresolved' | 'resolved' | 'handed-over' | 'escalated'} [status] &#x60;Filter on the conversation status.&#x60; 
         * @param {string} [referral] &#x60;DEPRECATED: Filter events using their referral page&#x60; 
         * @param {string} [labels] &#x60;Filter on labels&#x60; 
         * @param {number} [from] &#x60;Lower date bound on unix timestamp.&#x60; 
         * @param {number} [to] &#x60;Higher date bound on unix timestamp.&#x60; 
         * @param {string} [cursor] Pagination cursor to fetch additional pages
         * @param {number} [limit] \&#39;The maximum list size to be returned.\&#39; 
         * @param {string} [locale] &#x60;Filter on Locale&#x60; 
         * @param {string} [unansweredQuestions] Filter by unanswered questions. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [queries] Filter by queries. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [thumbsUp] Filter by the ammount of thumbs up feedback. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [thumbsDown] Filter by the ammount of thumbs down feedback. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [subject] Filter by subject
         * @param {'unanswered_questions' | 'queries' | 'thumbs_up' | 'thumbs_down' | 'created_at' | 'workflow' | 'last_message'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listConversations(authorization: string, appId: string, userId?: string, status?: 'any' | 'unresolved' | 'resolved' | 'handed-over' | 'escalated', referral?: string, labels?: string, from?: number, to?: number, cursor?: string, limit?: number, locale?: string, unansweredQuestions?: string, queries?: string, thumbsUp?: string, thumbsDown?: string, subject?: string, sortBy?: 'unanswered_questions' | 'queries' | 'thumbs_up' | 'thumbs_down' | 'created_at' | 'workflow' | 'last_message', order?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConversationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listConversations(authorization, appId, userId, status, referral, labels, from, to, cursor, limit, locale, unansweredQuestions, queries, thumbsUp, thumbsDown, subject, sortBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * `List events with a given filter.` 
         * @summary List events
         * @param {string} authorization &#x60;Authorization Bearer header&#x60; 
         * @param {string} appId App ID
         * @param {string} locale Locale
         * @param {'meta/err' | 'meta/conversation' | 'meta/beacon' | 'in/hi' | 'in/bye' | 'in/query' | 'in/click' | 'in/message' | 'in/pull' | 'in/cx/answer' | 'in/cx/feedback' | 'out/cx/question' | 'out/text' | 'out/resources' | 'out/beacon' | 'out/not-found' | 'out/bye'} [uri] Message types are identified using a universal resource identifier (URI)  * &#x60;meta/XXX&#x60; are meta messages used to keep the communication between the client and the server.  *  &#x60;meta/XXX&#x60; are used to drive the conversation.  *  &#x60;in/XXX&#x60; are used for sending data to the engine.  *  &#x60;out/XXX&#x60; are used to receive data from the engine.  Clients should be able to send &#x60;in&#x60; messages and receive &#x60;out&#x60; messages. 
         * @param {string} [aspect] &#x60;The event Aspect, if any to filter on&#x60; 
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {string} [cursor] &#x60;Pagination cursor to fetch additional pages&#x60; 
         * @param {number} [limit] &#x60;Maximum page size&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEvents(authorization: string, appId: string, locale: string, uri?: 'meta/err' | 'meta/conversation' | 'meta/beacon' | 'in/hi' | 'in/bye' | 'in/query' | 'in/click' | 'in/message' | 'in/pull' | 'in/cx/answer' | 'in/cx/feedback' | 'out/cx/question' | 'out/text' | 'out/resources' | 'out/beacon' | 'out/not-found' | 'out/bye', aspect?: string, from?: number, to?: number, cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEvents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEvents(authorization, appId, locale, uri, aspect, from, to, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * Get the details of a single conversation. 
         * @summary Get conversation detail
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} conversationId The conversation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation(authorization: string, appId: string, conversationId: string, options?: any): AxiosPromise<GetConversationResponse> {
            return localVarFp.getConversation(authorization, appId, conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all events for a given conversation. 
         * @summary List conversation events
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} conversationId The conversation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConversationEvents(authorization: string, appId: string, conversationId: string, options?: any): AxiosPromise<ListConversationEvents> {
            return localVarFp.listConversationEvents(authorization, appId, conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * List conversations filtered by locale. Conversations are pagineted with a cursor. 
         * @summary List conversations
         * @param {string} authorization Authorization Bearer header
         * @param {string} appId App ID
         * @param {string} [userId] &#x60;DEPRECATED: Filter on the user ID.&#x60; 
         * @param {'any' | 'unresolved' | 'resolved' | 'handed-over' | 'escalated'} [status] &#x60;Filter on the conversation status.&#x60; 
         * @param {string} [referral] &#x60;DEPRECATED: Filter events using their referral page&#x60; 
         * @param {string} [labels] &#x60;Filter on labels&#x60; 
         * @param {number} [from] &#x60;Lower date bound on unix timestamp.&#x60; 
         * @param {number} [to] &#x60;Higher date bound on unix timestamp.&#x60; 
         * @param {string} [cursor] Pagination cursor to fetch additional pages
         * @param {number} [limit] \&#39;The maximum list size to be returned.\&#39; 
         * @param {string} [locale] &#x60;Filter on Locale&#x60; 
         * @param {string} [unansweredQuestions] Filter by unanswered questions. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [queries] Filter by queries. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [thumbsUp] Filter by the ammount of thumbs up feedback. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [thumbsDown] Filter by the ammount of thumbs down feedback. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
         * @param {string} [subject] Filter by subject
         * @param {'unanswered_questions' | 'queries' | 'thumbs_up' | 'thumbs_down' | 'created_at' | 'workflow' | 'last_message'} [sortBy] Sort content by field
         * @param {'asc' | 'desc'} [order] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConversations(authorization: string, appId: string, userId?: string, status?: 'any' | 'unresolved' | 'resolved' | 'handed-over' | 'escalated', referral?: string, labels?: string, from?: number, to?: number, cursor?: string, limit?: number, locale?: string, unansweredQuestions?: string, queries?: string, thumbsUp?: string, thumbsDown?: string, subject?: string, sortBy?: 'unanswered_questions' | 'queries' | 'thumbs_up' | 'thumbs_down' | 'created_at' | 'workflow' | 'last_message', order?: 'asc' | 'desc', options?: any): AxiosPromise<ListConversationsResponse> {
            return localVarFp.listConversations(authorization, appId, userId, status, referral, labels, from, to, cursor, limit, locale, unansweredQuestions, queries, thumbsUp, thumbsDown, subject, sortBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * `List events with a given filter.` 
         * @summary List events
         * @param {string} authorization &#x60;Authorization Bearer header&#x60; 
         * @param {string} appId App ID
         * @param {string} locale Locale
         * @param {'meta/err' | 'meta/conversation' | 'meta/beacon' | 'in/hi' | 'in/bye' | 'in/query' | 'in/click' | 'in/message' | 'in/pull' | 'in/cx/answer' | 'in/cx/feedback' | 'out/cx/question' | 'out/text' | 'out/resources' | 'out/beacon' | 'out/not-found' | 'out/bye'} [uri] Message types are identified using a universal resource identifier (URI)  * &#x60;meta/XXX&#x60; are meta messages used to keep the communication between the client and the server.  *  &#x60;meta/XXX&#x60; are used to drive the conversation.  *  &#x60;in/XXX&#x60; are used for sending data to the engine.  *  &#x60;out/XXX&#x60; are used to receive data from the engine.  Clients should be able to send &#x60;in&#x60; messages and receive &#x60;out&#x60; messages. 
         * @param {string} [aspect] &#x60;The event Aspect, if any to filter on&#x60; 
         * @param {number} [from] Lower date bound on unix timestamp.
         * @param {number} [to] Higher date bound on unix timestamp.
         * @param {string} [cursor] &#x60;Pagination cursor to fetch additional pages&#x60; 
         * @param {number} [limit] &#x60;Maximum page size&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvents(authorization: string, appId: string, locale: string, uri?: 'meta/err' | 'meta/conversation' | 'meta/beacon' | 'in/hi' | 'in/bye' | 'in/query' | 'in/click' | 'in/message' | 'in/pull' | 'in/cx/answer' | 'in/cx/feedback' | 'out/cx/question' | 'out/text' | 'out/resources' | 'out/beacon' | 'out/not-found' | 'out/bye', aspect?: string, from?: number, to?: number, cursor?: string, limit?: number, options?: any): AxiosPromise<ListEvents> {
            return localVarFp.listEvents(authorization, appId, locale, uri, aspect, from, to, cursor, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * Get the details of a single conversation. 
     * @summary Get conversation detail
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId App ID
     * @param {string} conversationId The conversation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getConversation(authorization: string, appId: string, conversationId: string, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).getConversation(authorization, appId, conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all events for a given conversation. 
     * @summary List conversation events
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId App ID
     * @param {string} conversationId The conversation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public listConversationEvents(authorization: string, appId: string, conversationId: string, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).listConversationEvents(authorization, appId, conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List conversations filtered by locale. Conversations are pagineted with a cursor. 
     * @summary List conversations
     * @param {string} authorization Authorization Bearer header
     * @param {string} appId App ID
     * @param {string} [userId] &#x60;DEPRECATED: Filter on the user ID.&#x60; 
     * @param {'any' | 'unresolved' | 'resolved' | 'handed-over' | 'escalated'} [status] &#x60;Filter on the conversation status.&#x60; 
     * @param {string} [referral] &#x60;DEPRECATED: Filter events using their referral page&#x60; 
     * @param {string} [labels] &#x60;Filter on labels&#x60; 
     * @param {number} [from] &#x60;Lower date bound on unix timestamp.&#x60; 
     * @param {number} [to] &#x60;Higher date bound on unix timestamp.&#x60; 
     * @param {string} [cursor] Pagination cursor to fetch additional pages
     * @param {number} [limit] \&#39;The maximum list size to be returned.\&#39; 
     * @param {string} [locale] &#x60;Filter on Locale&#x60; 
     * @param {string} [unansweredQuestions] Filter by unanswered questions. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
     * @param {string} [queries] Filter by queries. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
     * @param {string} [thumbsUp] Filter by the ammount of thumbs up feedback. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
     * @param {string} [thumbsDown] Filter by the ammount of thumbs down feedback. Both equality and range([&lt;lower&gt; TO &lt;upper&gt;]) are supported.
     * @param {string} [subject] Filter by subject
     * @param {'unanswered_questions' | 'queries' | 'thumbs_up' | 'thumbs_down' | 'created_at' | 'workflow' | 'last_message'} [sortBy] Sort content by field
     * @param {'asc' | 'desc'} [order] Sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public listConversations(authorization: string, appId: string, userId?: string, status?: 'any' | 'unresolved' | 'resolved' | 'handed-over' | 'escalated', referral?: string, labels?: string, from?: number, to?: number, cursor?: string, limit?: number, locale?: string, unansweredQuestions?: string, queries?: string, thumbsUp?: string, thumbsDown?: string, subject?: string, sortBy?: 'unanswered_questions' | 'queries' | 'thumbs_up' | 'thumbs_down' | 'created_at' | 'workflow' | 'last_message', order?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).listConversations(authorization, appId, userId, status, referral, labels, from, to, cursor, limit, locale, unansweredQuestions, queries, thumbsUp, thumbsDown, subject, sortBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * `List events with a given filter.` 
     * @summary List events
     * @param {string} authorization &#x60;Authorization Bearer header&#x60; 
     * @param {string} appId App ID
     * @param {string} locale Locale
     * @param {'meta/err' | 'meta/conversation' | 'meta/beacon' | 'in/hi' | 'in/bye' | 'in/query' | 'in/click' | 'in/message' | 'in/pull' | 'in/cx/answer' | 'in/cx/feedback' | 'out/cx/question' | 'out/text' | 'out/resources' | 'out/beacon' | 'out/not-found' | 'out/bye'} [uri] Message types are identified using a universal resource identifier (URI)  * &#x60;meta/XXX&#x60; are meta messages used to keep the communication between the client and the server.  *  &#x60;meta/XXX&#x60; are used to drive the conversation.  *  &#x60;in/XXX&#x60; are used for sending data to the engine.  *  &#x60;out/XXX&#x60; are used to receive data from the engine.  Clients should be able to send &#x60;in&#x60; messages and receive &#x60;out&#x60; messages. 
     * @param {string} [aspect] &#x60;The event Aspect, if any to filter on&#x60; 
     * @param {number} [from] Lower date bound on unix timestamp.
     * @param {number} [to] Higher date bound on unix timestamp.
     * @param {string} [cursor] &#x60;Pagination cursor to fetch additional pages&#x60; 
     * @param {number} [limit] &#x60;Maximum page size&#x60; 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public listEvents(authorization: string, appId: string, locale: string, uri?: 'meta/err' | 'meta/conversation' | 'meta/beacon' | 'in/hi' | 'in/bye' | 'in/query' | 'in/click' | 'in/message' | 'in/pull' | 'in/cx/answer' | 'in/cx/feedback' | 'out/cx/question' | 'out/text' | 'out/resources' | 'out/beacon' | 'out/not-found' | 'out/bye', aspect?: string, from?: number, to?: number, cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).listEvents(authorization, appId, locale, uri, aspect, from, to, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }
}
