<template>
    <div v-if="shouldShowWebchatClient">
        <dx-chatbot-client
            :channel-id="botData?.channelid"
            :provider-email="botData?.providerId"
            :base-url="koopid?.url"
            fab-coordinates='{ "bottom": 16, "right": 16 }'
            try-now="true"
            is-hostless="false"
        />
    </div>
    <web-component-loader
        v-model:loading="loading"
        :source="dxChatbotUrl"
        @close="handleClose"
    />
</template>

<script lang="ts">
import { defineComponent, inject, PropType } from 'vue';
import WebComponentLoader from '@/components/webcomponent-loader/WebComponentLoader.vue';
import { DigitalExperienceProvider } from '@/open-api';

export default defineComponent({
    components: {
        WebComponentLoader
    },
    props: {
        botData: {
            type: Object as PropType<{
                botid: number;
                botname: string;
                channelid: string;
                providerId?: string | number;
            }>
        },
        closeChatbotDrawer: {
            type: Function as PropType<() => void>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;
        return {
            orgId
        };
    },
    mounted() {
        // @ts-ignore
        window.dxe = {
            server: this.koopid?.url
        };
    },
    computed: {
        /* v8 ignore next 16 */
        zone(): string {
            return this.$store.getters[`${this.orgId}/zone`];
        },
        dxChatbotUrl(): string {
            // TODO: change to actual deployed chatbot client endpoint
            // return `https://console.${this.zone}.karehq.com/webchatclient/dialpad-chatbot-trynow.es.js`;
            // return 'http://localhost:8001/src/temp/DXChatbotSDK-CE.ts';
            return `${this.koopid?.url}/dxclient/dist/trynow/dialpad-chatbot-trynow.es.js`;
        },
        koopid(): DigitalExperienceProvider {
            return this.$store.getters[`${this.orgId}/koopid`];
        },
        shouldShowWebchatClient(): boolean {
            return !this.loading;
        }
    },
    methods: {
        handleClose() {
            this.$emit('update:botData', undefined);
            this.closeChatbotDrawer?.();
        }
    },
    data() {
        return {
            loading: true
        };
    }
});
</script>
