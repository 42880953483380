<template>
    <Transition name="slide" v-bind="$attrs">
        <drawer-template
            v-if="showGuruConnectPage"
            @drawer-template-close="handleClose"
            @back="handleBackClick({})"
            is-secondary-page
        >
            <template #header>
                <h3 class="d-d-flex d-jc-flex-start d-py8 d-my2 d-ml32 d-pl8">
                    {{
                        `${isNotConnected ? $t('Connect') : $t('Configure')} Guru`
                    }}
                </h3>
            </template>
            <template #default>
                <div v-if="!isNotConnected">
                    <div class="d-mb16">
                        <p>{{ $t('You are authorized as') }}:</p>
                        <p class="d-fw-bold">
                            {{ email }}
                        </p>
                    </div>
                    <div class="d-mb16">
                        <p class="d-label--md-compact d-mb4">
                            {{ $t('Choose Guru collection') }}
                        </p>
                        <filter-popover
                            id="collectionPopover"
                            :title="$t('Please select one')"
                            :searchPlaceholder="$t('Search collections')"
                            :options="collection"
                            v-model:selected-options="collectionSelected"
                            :fetch="getGuruFolders"
                            :is-fetching="isRequesting"
                            with-search
                            hide-checkboxes
                            is-normal-select-style
                            size-style="md"
                            is-anchor
                            title-style="md"
                            close-on-select
                        />
                        <dt-validation-messages
                            :validationMessages="[
                                {
                                    message: collectionErrorMessage,
                                    type: VALIDATION_MESSAGE_TYPES.ERROR
                                }
                            ]"
                            :show-messages="!!collectionErrorMessage"
                        />
                    </div>
                    <div class="d-mb16">
                        <p class="d-label--md-compact d-mb4">
                            {{ $t('Select folder') }}
                        </p>
                        <filter-popover
                            id="foldersPopover"
                            :title="$t('All folders')"
                            :searchPlaceholder="$t('Search folders')"
                            :options="folders"
                            v-model:selected-options="folderSelected"
                            :is-fetching="isRequesting"
                            with-search
                            hide-checkboxes
                            is-normal-select-style
                            size-style="md"
                            is-anchor
                            placeholder="All folders"
                            title-style="d-fs-200"
                            close-on-select
                            :is-disabled="!folders.length"
                            :fetch="clearErrors"
                        />
                        <dt-validation-messages
                            :validationMessages="[
                                {
                                    message: folderErrorMessage,
                                    type: VALIDATION_MESSAGE_TYPES.ERROR
                                }
                            ]"
                            :show-messages="!!folderErrorMessage"
                        />
                    </div>
                </div>
                <div v-if="isNotConnected">
                    <div class="d-mb16">
                        <div class="d-headline--md d-mb4">
                            {{ $t('How does it work?') }}
                        </div>
                        <ul class="d-px16 d-mb16">
                            <li class="d-lst-disc">
                                {{ $t('Authorize with your credentials.') }}
                            </li>
                            <li class="d-lst-disc">
                                <dt-link
                                    target="_blank"
                                    href="https://help.getguru.com/docs/managing-assist-settings-and-custom-actions"
                                >
                                    {{
                                        $t(
                                            'Get a User token from Guru settings.'
                                        )
                                    }}
                                </dt-link>
                                {{ $t('You will need admin permissions.') }}
                            </li>
                            <li class="d-lst-disc">
                                {{
                                    $t(
                                        'Connect a folder from a Guru collection'
                                    )
                                }}
                            </li>
                        </ul>
                    </div>
                    <div class="d-mb16">
                        <dt-input
                            :label="$t('Email')"
                            type="text"
                            v-model="email"
                            :show-messages="showEmailError"
                            :messages="[
                                {
                                    message: $t('Invalid email'),
                                    type: VALIDATION_MESSAGE_TYPES.ERROR
                                }
                            ]"
                        />
                    </div>
                    <div class="d-mb16">
                        <dt-input
                            label="User token"
                            type="text"
                            v-model="userToken"
                            :show-messages="showUserTokenError"
                            :messages="[
                                {
                                    message: `${$t('Invalid')} User token`,
                                    type: VALIDATION_MESSAGE_TYPES.ERROR
                                }
                            ]"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="d-d-flex d-py16">
                    <dt-button
                        class="d-mr16"
                        importance="clear"
                        @click="handleBackClick({})"
                        :disabled="isRequesting"
                    >
                        {{ $t('Cancel') }}
                    </dt-button>
                    <dt-button
                        v-if="isNotConnected"
                        @click="handleConnectGuru"
                        :disabled="isRequesting"
                    >
                        {{ $t('Next') }}
                    </dt-button>
                    <dt-button
                        v-else
                        @click="handleConnectFolder"
                        id="open-child-window"
                        :disabled="isRequesting"
                    >
                        {{ $t('Connect') }}
                    </dt-button>
                </div>
            </template>
        </drawer-template>
    </Transition>
    <confirmation-modal
        type="Guru"
        v-model:show-prompt="showConfirmationModal"
        @confirm="handleDisconnectGuru"
    />
</template>

<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import {
    DtButton,
    DtInput,
    DtLink,
    DtValidationMessages,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal.vue';
import MenuPopover from '@/components/menu-popover/MenuPopover.vue';
import type { INotice } from '@/components/sources-drawer/Sources.types';

import { type GuruSettings, type Knowledgebase } from '@/open-api';

import { handleRequest, IFilter } from '@/utils/Common';
import {
    EMAIL_REGEX,
    NOTICE_KINDS,
    STATUS,
    ZONES_ABBR
} from '@/utils/Constants';
import FilterPopover from '@/components/filter-popover/FilterPopover.vue';

function initialState() {
    return {
        email: '',
        userToken: '',
        isRequesting: false,
        showEmailError: false,
        showUserTokenError: false,
        collectionErrorMessage: '',
        folderErrorMessage: '',
        collection: [] as IFilter[],
        folders: [] as IFilter[],
        collectionSelected: [],
        folderSelected: ['']
    };
}

export default defineComponent({
    props: {
        showGuruConnectPage: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        data: {
            type: Object as PropType<GuruSettings>
        },
        showDisconnect: {
            type: Boolean as PropType<boolean>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    components: {
        DtButton,
        DtInput,
        DtLink,
        DtValidationMessages,
        FilterPopover,
        ConfirmationModal,
        DrawerTemplate,
        MenuPopover
    },
    watch: {
        data(newData, oldData) {
            if (
                newData?.id &&
                newData?.id !== oldData?.id &&
                !this.showDisconnect
            ) {
                this.collectionSelected = [newData?.collection_id];
                this.folderSelected = [newData?.folder_id];
                this.email = newData?.user_email;
                this.getGuruCollection();

                if (this.folderSelected?.[0]) {
                    this.getGuruFolders();
                }
            }
        },
        showDisconnect(show) {
            if (show) {
                this.handleShowConfirmationModal();
            }
        },
        showConfirmationModal(newShow) {
            this.$emit('update:showDisconnect', newShow);
        },
        collectionSelected(newCollection, oldCollection) {
            // if oldCollection is not empty then it is not the first render
            // this is to clear the selected folder when a new collection is selected
            if (newCollection.length && oldCollection.length) {
                this.folderSelected = [];
            }
        }
    },
    emits: ['back', 'connectClose', 'update:data', 'update:showDisconnect'],
    computed: {
        VALIDATION_MESSAGE_TYPES() {
            return VALIDATION_MESSAGE_TYPES;
        },
        /* v8 ignore next 10 */
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        zone(): ZONES_ABBR {
            return this.$store.getters[`${this.orgId}/zone`];
        },
        isNotConnected(): boolean {
            return this.data?.status === STATUS.NOT_CONNECTED;
        }
    },
    methods: {
        /* v8 ignore next 100 */
        handleClose() {
            this.$emit('connectClose');
        },
        handleBackClick(resp: INotice) {
            this.$emit(
                'back',
                Object.keys(resp).length
                    ? {
                          title: resp.title,
                          message: resp.message,
                          kind: NOTICE_KINDS.SUCCESS
                      }
                    : {}
            );
            this.resetValues();
        },
        async handleConnectGuru() {
            if (!EMAIL_REGEX.test(this.email)) {
                this.showEmailError = true;
                return;
            }
            this.showEmailError = false;
            this.isRequesting = true;

            const connectGuruRes = await handleRequest<any>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.createGuruSettings(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    {
                        user_email: this.email,
                        user_token: this.userToken
                    }
                ),
                this.orgId
            );
            if (connectGuruRes?.data) {
                this.$emit('update:data', connectGuruRes.data);
            }
        },
        async getGuruCollection() {
            this.isRequesting = true;
            const guruAccountCollectionsRes = await handleRequest<any>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.listGuruCollections(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    this.data.id
                ),
                this.orgId
            );

            this.isRequesting = false;
            if (guruAccountCollectionsRes?.data) {
                this.collection =
                    guruAccountCollectionsRes?.data.collections.map(
                        (collection: any) => ({
                            value: collection.id,
                            label: collection.name
                        })
                    );
            }
        },
        async getGuruFolders() {
            this.isRequesting = true;
            this.collectionErrorMessage = '';
            const guruAccountFoldersRes = await handleRequest<any>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.listGuruFolders(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    this.data.id,
                    this.collectionSelected
                ),
                this.orgId
            );

            this.isRequesting = false;
            if (guruAccountFoldersRes?.data) {
                this.folders = guruAccountFoldersRes?.data.folders.map(
                    (folder: any) => ({
                        value: folder.id,
                        label: folder.name
                    })
                );
            }
        },
        clearErrors() {
            this.folderErrorMessage = '';
        },
        async handleConnectFolder() {
            if (!this.collectionSelected?.[0]) {
                this.collectionErrorMessage = this.$t(
                    'You need to choose a Guru collection'
                );
                return;
            }
            if (!this.folderSelected?.[0]) {
                this.folderErrorMessage = this.$t(
                    'You need to select a folder'
                );
                return;
            }
            this.isRequesting = true;
            const connectFolderRes = await handleRequest<any>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.patchGuruSettings(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    this.data.id,
                    this.folderSelected[0],
                    this.collectionSelected[0]
                ),
                this.orgId
            );

            this.isRequesting = false;
            if (!connectFolderRes?.error) {
                this.handleBackClick({
                    title: `Guru ${this.$t('account connected successfully')}`,
                    message: this.$t(
                        'Your documents are now importing. View import status in the Configure sources sidebar.'
                    )
                });
            }
        },
        /* v8 ignore next 25 */
        async handleDisconnectGuru() {
            this.isRequesting = true;

            const resKb = await handleRequest(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.disconnectGuruSettings(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    this.data.id
                ),
                this.orgId
            );
            this.isRequesting = false;

            if (resKb.error) {
                this.showErrorMsg('Could not disconnect this Guru account');
            } else {
                this.handleBackClick({
                    title: `Guru ${this.$t('account disconnected successfully')}`
                });
            }
        },
        resetValues() {
            Object.assign(this.$data, initialState());
        },
        showErrorMsg(title: string) {
            this.$store.commit(`${this.orgId}/addNotification`, {
                kind: NOTICE_KINDS.ERROR,
                title: this.$t(title)
            });
        },
        handleShowConfirmationModal() {
            this.showConfirmationModal = true;
        }
    },
    data() {
        return {
            ...initialState(),
            showConfirmationModal: false
        };
    }
});
</script>
