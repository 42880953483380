import type { Module } from 'vuex';
import type { NodeDetail, NodeStatus, Organisation } from '@/open-api';
import { NODE_TYPES } from '@/utils/types/Response';
import type { ApiService } from '@/services/Api.service';
import { DEFAULT_ITEMS_PER_PAGE } from '@/utils/Constants';
import {
    DEBOUNCE_INTERVAL_SECONDS,
    FORCE_FECTH_INTERVAL_SECONDS
} from '@/store';

export interface DocumentsModuleState {
    documents: NodeDetail[];
    fetching: boolean;
    error: boolean;
    totalHits: number;
    kbId?: string;
    status?: NodeStatus;
    search?: string;
    labels?: string[];
    sort?: DocumentSortBy;
    order?: 'desc' | 'asc';
    limit?: number;
    offset?: number;
    timer?: any;
    lastFetched?: number;
}

type DocumentSortBy =
    | 'source_name'
    | 'title'
    | 'node_type'
    | 'node_status'
    | 'created_at'
    | 'updated_at'
    | 'queries_count'
    | 'webexts_count'
    | 'workflows_count'
    | 'choices_count';

export interface fetchPayload {
    kbId: string;
    status: NodeStatus;
    search?: string;
    source?: string;
    labels: string[];
    sort?: DocumentSortBy;
    order?: 'desc' | 'asc';
    limit: number;
    offset: number;
    disableFetching?: boolean;
}

export default function (org: Organisation) {
    return {
        namespaced: true,
        state: {
            documents: [],
            fetching: false,
            error: false,
            totalHits: 0
        },
        getters: {
            list(state): NodeDetail[] {
                return state.documents;
            },
            fetching(state): boolean {
                return state.fetching;
            },
            totalHits(state): number {
                return state.totalHits;
            },
            error(state): boolean {
                return state.error;
            },
            status(state) {
                return state.status;
            },
            kbId(state) {
                return state.kbId;
            },
            search(state) {
                return state.search;
            },
            labels(state) {
                return state.labels;
            },
            sort(state) {
                return state.sort;
            },
            source(state) {
                return state.source;
            },
            order(state) {
                return state.order;
            },
            limit(state) {
                return state.limit;
            },
            offset(state) {
                return state.offset;
            },
            timer(state) {
                return state.timer;
            },
            lastFetched(state) {
                return state.lastFetched;
            }
        },
        actions: {
            async fetch(
                { commit, rootGetters },
                {
                    kbId,
                    status,
                    search,
                    labels,
                    sort,
                    source,
                    order,
                    limit,
                    offset,
                    disableFetching
                }: fetchPayload
            ) {
                commit('setLastFetched', Date.now());
                const apiService: ApiService =
                    rootGetters[`${org.id}/apiService`];
                const authToken: string = rootGetters[`${org.id}/authToken`];

                if (!disableFetching) commit('setFetching', true);
                commit('setError', false);
                commit('setStatus', status);
                commit('setKbId', kbId);
                commit('setSearch', search);
                commit('setLabels', labels);
                commit('setSort', sort);
                commit('setSource', source);
                commit('setOrder', order);
                commit('setLimit', limit);
                commit('setOffset', offset);

                try {
                    const res = await apiService.knowledge.listNodes(
                        authToken,
                        kbId,
                        NODE_TYPES.CONTENT,
                        status,
                        source,
                        undefined,
                        search ?? undefined,
                        labels?.length ? labels?.join(',') : undefined,
                        sort,
                        order,
                        limit,
                        offset
                    );
                    commit('setDocuments', res.data.nodes);
                    commit('setTotalHits', res.data.total_hits);
                } catch (e) {
                    commit('setError', true);
                }

                if (!disableFetching) commit('setFetching', false);
            },
            async fetchLast({ commit, dispatch, getters }) {
                if (getters.lastFetched) {
                    const lastFetchedInSeconds = getters.lastFetched / 1000;
                    const dateNowInSeconds = Date.now() / 1000;

                    // (jnoronha): If we have incoming events but none have been fetched in the 4 seconds, we force fetch
                    if (
                        dateNowInSeconds - lastFetchedInSeconds >=
                        FORCE_FECTH_INTERVAL_SECONDS
                    ) {
                        dispatch('fetch', {
                            kbId: getters.kbId,
                            status: getters.status,
                            search: getters.search,
                            labels: getters.labels,
                            source: getters.source,
                            sort: getters.sort,
                            order: getters.order,
                            limit: getters.limit,
                            offset: getters.offset,
                            disableFetching: true
                        });
                        return;
                    }
                }

                if (getters.timer) clearTimeout(getters.timer);
                commit(
                    'setTimer',
                    setTimeout(() => {
                        dispatch('fetch', {
                            kbId: getters.kbId,
                            status: getters.status,
                            search: getters.search,
                            labels: getters.labels,
                            sort: getters.sort,
                            source: getters.source,
                            order: getters.order,
                            limit: getters.limit,
                            offset: getters.offset,
                            disableFetching: true
                        });
                    }, DEBOUNCE_INTERVAL_SECONDS * 1000)
                );
            },
            async bulkUpdate(
                { commit, rootGetters },
                { kbId, nodesArray }: { kbId: string; nodesArray: NodeDetail[] }
            ) {
                const apiService: ApiService =
                    rootGetters[`${org.id}/apiService`];
                const authToken: string = rootGetters[`${org.id}/authToken`];

                try {
                    await apiService?.knowledge.patchNodes(authToken, kbId, {
                        nodes: nodesArray
                    });
                } catch (e) {
                    // Handle bulk update error
                }
            },
            handleDocumentCreate(
                { dispatch, commit, getters },
                document: NodeDetail
            ) {
                if (!getters.list || !getters.status) return;

                if (
                    getters.search?.length ||
                    getters.labels?.length ||
                    getters.sort !== 'title' ||
                    getters.source?.length
                ) {
                    dispatch('fetchLast');
                } else {
                    commit('addDocument', document);
                }
            },
            handleDocumentUpdate(
                { dispatch, commit, getters },
                document: NodeDetail
            ) {
                if (!getters.list || !getters.status) return;

                if (getters.status === document.status) {
                    const foundIndex = getters.list.findIndex(
                        (rsp: NodeDetail) => rsp.id === document.id
                    );
                    if (foundIndex > -1) {
                        commit('mutateDocument', document);
                    } else {
                        if (
                            getters.search?.length ||
                            getters.labels?.length ||
                            getters.sort !== 'title' ||
                            getters.source?.length
                        ) {
                            dispatch('fetchLast');
                        } else {
                            commit('addDocument', document);
                        }
                    }
                } else {
                    commit('removeDocument', document);
                    dispatch('fetchLast');
                }
            },
            handleDocumentDelete(
                { dispatch, commit, getters },
                document: NodeDetail
            ) {
                if (!getters.list || !getters.status) return;
                commit('removeDocument', document);
                dispatch('fetchLast');
            }
        },
        mutations: {
            setDocuments(state, documents: NodeDetail[]) {
                state.documents = documents;
            },
            setTotalHits(state, totalHits: number) {
                state.totalHits = totalHits;
            },
            setFetching(state, fetching: boolean) {
                state.fetching = fetching;
            },
            setError(state, error: boolean) {
                state.error = error;
            },
            setStatus(state, status: NodeStatus) {
                state.status = status;
            },
            setKbId(state, kbId: string) {
                state.kbId = kbId;
            },
            setSearch(state, search: string) {
                state.search = search;
            },
            setLabels(state, labels?: string[]) {
                state.labels = labels;
            },
            setSort(state, sort?: DocumentSortBy) {
                state.sort = sort;
            },
            setSource(state, source?: string) {
                state.source = source;
            },
            setOrder(state, order?: 'asc' | 'desc') {
                state.order = order;
            },
            setLimit(state, limit: number) {
                state.limit = limit;
            },
            setOffset(state, offset: number) {
                state.offset = offset;
            },
            setTimer(state, timer: any) {
                state.timer = timer;
            },
            setLastFetched(state, lastFetched: number) {
                state.lastFetched = lastFetched;
            },

            addDocument(state, document: NodeDetail) {
                if (
                    state.documents &&
                    !state.documents.includes(document) &&
                    state.status === document.status
                ) {
                    state.documents.push(document);
                    if (!state.order || state.order === 'asc') {
                        state.documents.sort((a, b) =>
                            a.title!.localeCompare(b.title!)
                        );
                    } else {
                        state.documents.sort((a, b) =>
                            b.title!.localeCompare(a.title!)
                        );
                    }
                    const removeExcess =
                        state.documents.length - DEFAULT_ITEMS_PER_PAGE;
                    if (removeExcess > 0) {
                        state.documents.splice(
                            state.documents.length - removeExcess,
                            removeExcess
                        );
                    }
                    if (!state.totalHits) state.totalHits = 1;
                    else state.totalHits += 1;
                }
            },
            mutateDocument(state, document: NodeDetail) {
                if (!state.documents || !state.status) return;

                const foundIndex = state.documents.findIndex(
                    (rsp) => rsp.id === document.id
                );
                if (foundIndex > -1) {
                    Object.assign(state.documents[foundIndex], document);
                }
            },
            removeDocument(state, document: NodeDetail) {
                if (!state.documents) return;
                const foundIndex = state.documents.findIndex(
                    (rsp) => rsp.id === document.id
                );
                if (foundIndex > -1) {
                    state.documents.splice(foundIndex, 1);
                    if (!state.totalHits) state.totalHits = 0;
                    else state.totalHits -= 1;
                }
            }
        }
    } as Module<DocumentsModuleState, any>;
}
