<template>
    <div class="d-d-flex d-ai-flex-start d-fl-grow0">
        <div class="d-d-flex d-fd-column">
            <span class="d-label-base">
                {{ label }}
            </span>
            <div class="d-description" v-if="description">
                {{ description }}
            </div>
        </div>
        <dt-toggle
            :checked="toggleValue"
            aria-label="Use Generative Ai Assistance"
            @change="onToggle"
        />
    </div>
</template>
<script lang="ts">
import { DtCheckbox, DtToggle } from '@dialpad/dialtone/vue3';
import { defineComponent, inject, type PropType } from 'vue';

export default defineComponent({
    props: {
        modelValue: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        isSaving: {
            type: Boolean as PropType<boolean>
        },
        label: {
            type: String as PropType<string>,
            required: true
        },
        description: {
            type: String as PropType<string>
        }
    },
    components: {
        DtCheckbox,
        DtToggle
    },
    setup() {
        const orgId = inject('orgId') as string;
        return {
            orgId
        };
    },
    computed: {
        // This is done so that the function coverage isn't affected by computed props
        /* v8 ignore next 90 */
        toggleValue: {
            get(): boolean {
                return this.modelValue;
            },
            set(value: boolean) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    methods: {
        onToggle(value: any) {
            this.toggleValue = value;
        }
    }
});
</script>
