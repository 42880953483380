<template>
    <div ref="labelsWrapper" class="d-w100p">
        <div
            ref="labelsContainer"
            class="d-of-y-auto d-h100p d-d-flex d-fw-nowrap"
        >
            <dt-chip
                ref="label"
                class="d-mr4 d-box-border"
                :class="{
                    'd-vi-hidden d-ps-absolute d-zi-hide':
                        shouldHideLabel(labelIndex)
                }"
                content-class="d-fs-100"
                :size="chipSize"
                v-for="(label, labelIndex) in labels"
                :key="label"
                hide-close
                @click="handleClick(label)"
                :interactive="interactive"
            >
                {{ label }}
            </dt-chip>
            <dt-tooltip v-if="shouldShowHiddenLabels">
                <ul>
                    <li :key="hiddenLabel" v-for="hiddenLabel in hiddenLabels">
                        {{ hiddenLabel }}
                    </li>
                </ul>
                <template #anchor>
                    <dt-chip
                        class="d-mr4 d-box-border"
                        content-class="d-fs-100"
                        hide-close
                        :size="chipSize"
                        @click="handleHiddenLabelsClick"
                    >
                        +{{ hiddenLabels.length }}
                    </dt-chip>
                </template>
            </dt-tooltip>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref } from 'vue';
import { DtChip, DtTooltip } from '@dialpad/dialtone/vue3';
import { createDebounce } from '@/utils/Common';

export default defineComponent({
    setup() {
        const debounce = createDebounce();
        return {
            debounce
        };
    },
    mounted() {
        this.renderMaximumLabels();
        this.ro = new ResizeObserver(this.renderMaximumLabels);

        this.ro.observe(this.$refs.labelsWrapper);
    },
    props: {
        labels: {
            type: Array as PropType<string[]>
        },
        interactive: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        onClick: {
            type: Function as PropType<any>,
            required: false
        },
        chipSize: {
            type: String as PropType<string>,
            default: 'sm'
        }
    },
    components: {
        DtChip,
        DtTooltip
    },
    computed: {
        hiddenLabels(): string[] {
            return this.labels.slice(this.maxLabels, this.labels?.length);
        },
        shouldShowHiddenLabels(): boolean {
            return !!this.hiddenLabels?.length;
        }
    },
    methods: {
        /* v8 ignore next 50 */
        handleClick(label: string) {
            if (this.onClick) {
                this.onClick(label);
            }
        },
        handleHiddenLabelsClick(event: MouseEvent) {
            event?.stopPropagation();
        },
        shouldHideLabel(labelIndex: number) {
            return labelIndex + 1 > this.maxLabels;
        },
        renderMaximumLabels() {
            const wrapper = this.$refs.labelsWrapper;
            const container = this.$refs.labelsContainer;
            const labels = this.$refs.label;

            if (wrapper && container && labels?.length) {
                // excess label chip counter 42 pixels
                const excessLabels = this.shouldShowHiddenLabels ? 42 : 0;
                const maxWidth = wrapper.clientWidth - excessLabels;
                const maxLabels = labels.reduce(
                    (
                        acc: {
                            count: number;
                            currentWidth: number;
                            stop: boolean;
                        },
                        label: any,
                        currentIndex: number
                    ) => {
                        // To break the loop in case a label cannot fit anymore in the container
                        if (acc.stop) return acc;

                        const labelWidth = label.$el.clientWidth;
                        if (
                            acc.currentWidth + labelWidth < maxWidth ||
                            currentIndex === 0
                        ) {
                            acc.currentWidth += labelWidth;
                            acc.count++;
                        } else {
                            acc.stop = true;
                        }
                        return acc;
                    },
                    { count: 0, currentWidth: 0, stop: false }
                );
                this.maxLabels = maxLabels.count;
            }
        }
    },
    data() {
        return {
            ro: undefined as ResizeObserver | undefined,
            maxLabels: ref<number | undefined>(null)
        };
    }
});
</script>
