<template>
    <div
        class="d-d-flex d-fd-column d-w80p d-mt8 d-gg4 d-bgc-transparent"
        v-if="blocks.length"
    >
        <template
            v-for="(block, blockIndex) in blocks as Block[]"
            :key="`${blockIndex}-${block.data.id}`"
        >
            <response-controls
                :view-mode="viewMode"
                :position="blockIndex"
                :isDragHover="
                    draggedPosition !== blockIndex &&
                    positionHover === blockIndex
                "
                v-if="block?.data?.type"
                @dragging="handleDragging"
                @position-hover="handleHover"
                :dragging="dragging"
                :dragged-position="draggedPosition"
                :block="block"
                :class="{
                    'd-d-none': block.hidden,
                    'd-c-grabbing': dragging,
                    'd-c-pointer': !dragging,
                    'd-c-default': viewMode
                }"
            >
                <template
                    v-slot:default="{
                        editable,
                        updateEditable,
                        setRef,
                        handleEdit
                    }"
                >
                    <component
                        v-if="!block.unsupported"
                        ref="blocks"
                        :is="`${block.data.type.replaceAll('_', '-')}-block`"
                        :block="block"
                        :workflows="workflows"
                        :workflows-error="workflowsError"
                        :fetching-workflows="fetchingWorkflows"
                        @edit="updateEditable"
                        @load="onLoad(setRef, block, blockIndex)"
                        @click="handleEditClick(editable, handleEdit)"
                        :editable="editable"
                        @vue:mounted="
                            onMountedComponent(setRef, block, blockIndex)
                        "
                    />
                    <component
                        v-if="block.unsupported"
                        ref="blocks"
                        :is="`unsupported-block`"
                        :block="block"
                    />
                </template>
            </response-controls>
        </template>
    </div>
</template>

<script lang="ts">
import {
    defineComponent,
    inject,
    type PropType,
    type UnwrapNestedRefs
} from 'vue';
import { useStore } from 'vuex';

import { type ResponseNodeBlock, ResponseNodeBlockTypeEnum } from '@/open-api';

import { featureIsEnabled } from '@/directives/feature.directive';
import type { KoopidWorkflow } from '@/utils/koopid';

import ResponseControls from '@/components/node-editor/ResponseControls.vue';
import TextBlock from '@/components/node-editor/blocks/text-block.vue';
import ImageBlock from '@/components/node-editor/blocks/image-block.vue';
import VideoBlock from '@/components/node-editor/blocks/video-block.vue';
import WebextBlock from '@/components/node-editor/blocks/webext-block.vue';
import WorkflowBlock from '@/components/node-editor/blocks/workflow-block.vue';
import UnsupportedBlock from '@/components/node-editor/blocks/unsupported-block.vue';
import ChoicesBlock from '@/components/node-editor/blocks/choices-block.vue';
import CallToActionBlock from '@/components/node-editor/blocks/call-to-action-block.vue';
import {
    Block,
    ResponseBlockEditor
} from '@/components/node-editor/responseBlockEditor';
import { SUPPORTED_BLOCKS } from '@/utils/Constants';

export default defineComponent({
    components: {
        ResponseControls,
        ChoicesBlock,
        TextBlock,
        ImageBlock,
        VideoBlock,
        WebextBlock,
        WorkflowBlock,
        UnsupportedBlock,
        CallToActionBlock
    },
    props: {
        rawBlocks: {
            type: Array as PropType<ResponseNodeBlock[]>,
            required: true
        },
        workflows: {
            type: Array as PropType<KoopidWorkflow[]>,
            default: () => []
        },
        workflowsError: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        fetchingWorkflows: {
            type: Boolean as PropType<boolean>,
            default: false
        },
        viewMode: {
            type: Boolean as PropType<boolean>
        }
    },
    setup(props) {
        const orgId: string = inject('orgId')!;
        const store = useStore();
        const rawBlocks = props.rawBlocks?.map((block) => {
            const isSupported = SUPPORTED_BLOCKS.some(
                (supportedBlock: any) => supportedBlock === block.type
            );

            return new Block(
                false,
                false,
                undefined,
                isSupported && !featureIsEnabled(`${block.type}_block`),
                block,
                !isSupported
            );
        });
        const responseBlockEditor = new ResponseBlockEditor(rawBlocks);
        if (store)
            store.commit(
                `${orgId}/setResponseBlockEditor`,
                responseBlockEditor
            );

        return {
            orgId,
            responseBlockEditor
        };
    },
    computed: {
        blocks(): UnwrapNestedRefs<Block[]> {
            return this.responseBlockEditor?.blocks || [];
        },
        isAlreadyEditing(): boolean {
            return this.blocks.some((block: any) => block.data.editing);
        }
    },
    methods: {
        /* v8 ignore next 10 */
        // This is responsible for letting the parent component know when a new block is inserted
        onMountedComponent(setRef: any, block: Block, index: number) {
            this.$nextTick(() => {
                if (
                    (block.data.type === ResponseNodeBlockTypeEnum.Image &&
                        !this.isLoaded) ||
                    block.hidden
                ) {
                    return;
                }
                const blocks = this.$refs.blocks as HTMLElement[] | undefined;
                if (!blocks || !blocks.length) return;
                const lastBlock = blocks[index];
                if (setRef) setRef(lastBlock);
                this.$emit('mountedBlock', lastBlock);
            });
        },
        onLoad(setRef: any, block: Block, i: number) {
            this.isLoaded = true;
            this.onMountedComponent(setRef, block, i);
        },
        handleDragging(payload: {
            block: Block;
            dragging: boolean;
            position?: number;
        }) {
            this.dragging = payload.dragging;
            this.draggedPosition = payload.position;

            if (!this.dragging) {
                this.positionHover = undefined;
            }
        },
        handleHover(pos: number) {
            this.positionHover = pos;
        },
        handleEditClick(editable: boolean, callback: any) {
            if (editable || this.viewMode || this.isAlreadyEditing) {
                return;
            }
            if (callback) {
                callback();
            }
        }
    },
    data() {
        return {
            Block: Block,
            isLoaded: false,
            dragging: false,
            draggedPosition: undefined as number | undefined,
            positionHover: undefined as number | undefined
        };
    }
});
</script>
