import App from '@/App.vue';
import {
    h,
    createApp,
    reactive,
    camelize,
    capitalize,
    type App as VueApp
} from 'vue';
import * as Sentry from '@sentry/vue';
import { version } from '../package.json';
import { onResizeDirective } from '@/directives/onResizeDirective';
import store from '@/store';
import AppModule from '@/store/AppModule';
import { type DXConsoleConfiguration, SDKInstance } from '../sdk/sdk';
import PersistModule from '@/store/PersistModule';
// eslint-disable-next-line
import { hyphenate } from '@vue/shared';
import { ZONES_ABBR } from '@/utils/Constants';
import { createRouter } from 'vue-router';
import router from '@/router';
import { i18n } from '@/main';
import { ApiService } from '@/services/Api.service';
import { Organisation, User } from '@/open-api';
import { UserCallbackServiceInstance } from '@/services/UserCallbacks.service';
import { clickOutsideDirective } from '@/directives/clickOutside.directive';
import { focusInput } from '@/directives/focusInput.directive';

import {
    featureDirective,
    featureIsEnabled
} from '@/directives/feature.directive';
import {
    convertAppTypeToLicenseType,
    convertLicenseTypeToRouteName,
    fetchKb,
    isKnowledgeRoute,
    validateOrgLicense
} from '@/utils/Common';
import { vDraggable } from '@neodrag/vue';
import { LicenseType } from '@/open-api';
import { getDSSInstances } from '@/utils/koopid';
import gapiPlugin from '@/plugins/gapi/install';
import axios from 'axios';
import { configureAxios } from '@/utils/Axios';
import type { fetchByIdPayload } from '@/store/WidgetsModule';
import { APP_TYPES } from '@/utils/types/App';

/* v8 ignore next 1000 */
const createCustomEvent = (name: string, args: any = []) => {
    return new CustomEvent(name, {
        bubbles: false,
        composed: true,
        cancelable: false,
        detail: !args.length ? self : args.length === 1 ? args[0] : args
    });
};

if (!(window as any).DialpadDX) {
    (window as any).DialpadDX = {
        OnKBMReady(callback: any) {
            UserCallbackServiceInstance.OnKBMReady(
                (org: Organisation | undefined) => callback(org)
            );
        }
    };
}

let appRouter = createRouter(router);

export function defineWebComponentElement(): any {
    class VueCustomElement extends HTMLElement {
        private _def: any;
        private _app?: VueApp<Element>;
        private _config?: DXConsoleConfiguration;
        private _props = reactive<Record<string, any>>({});
        private _numberProps: string[];
        private _orgId?: string;
        private _user?: User;
        private mutationObserver?: MutationObserver;
        static def: any;

        constructor() {
            super();

            this._numberProps = [];
            this._def = App;
        }

        // Helper function to set the props based on the element's attributes (for primitive values) or properties (for arrays & objects)
        private setAttr(attrName: string, hydrate = false) {
            if (attrName === 'class') return;
            let val: string | number | null =
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this[hyphenate(attrName)] ||
                this.getAttribute(hyphenate(attrName));

            if (
                val !== undefined &&
                this._numberProps.includes(hyphenate(attrName))
            ) {
                val = Number(val);
            }

            this._props[attrName] = val;
            if (camelize(attrName) === 'bodyStyle' && this._orgId) {
                store.dispatch(`${this._orgId}/setBodyStyle`, val);
            } else if (camelize(attrName) === 'currentPath') {
                if (appRouter.currentRoute.value.path !== val && val)
                    appRouter.push({ path: val.toString() });
            } else if (this._orgId && attrName && hydrate) {
                store.commit(
                    `${this._orgId}/set${capitalize(camelize(attrName))}`,
                    val
                );
            }
        }

        // Mutation observer to handle attribute changes, basically two-way binding
        private connectObserver() {
            return new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.type === 'attributes') {
                        const attrName = mutation.attributeName as string;

                        this.setAttr(attrName, true);
                    }
                });
            });
        }

        // Make emits available at the parent element
        private createEventProxies() {
            const eventNames = this._def.emits as string[];

            if (eventNames) {
                eventNames.forEach((evName) => {
                    const handlerName = `on${evName[0].toUpperCase()}${evName.substring(
                        1
                    )}`;

                    this._props[handlerName] = (...args: any[]) => {
                        this.dispatchEvent(createCustomEvent(evName, args));
                    };
                });
            }
        }

        private clearShadowRoot() {
            this.shadowRoot!.innerHTML = '';
        }

        private renderLoadingScreen() {
            this.shadowRoot!.innerHTML = `
            <style>
                .loading-wrapper {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center !important;
                }
                
                .loader {
                    width: 16px;
                    height: 16px;
                    border: 2px solid #7C52FF;
                    border-bottom-color: transparent;
                    border-radius: 50%;
                    display: inline-block;
                    box-sizing: border-box;
                    animation: rotation 1s linear infinite;
                }
            
                @keyframes rotation {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                } 
            </style>
            <div style="${this._props.bodyStyle}">
                <div class="loading-wrapper">
                    <span class="loader"></span>       
                </div>  
            </div>
            `;
        }

        private renderErrorScreen() {
            this.shadowRoot!.innerHTML = `
            <style>
                * {
                    font-family: -apple-system, "system-ui", "SF Pro", "Segoe UI Adjusted", "Segoe UI", SFMono, "Helvetica Neue", Cantarell, Ubuntu, Roboto, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                    font-size: 12px;
                }
                .error-wrapper {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center !important;
                    gap: 8px;
                    color: #815008;
                }
                
                .error-wrapper svg {
                    width: 14px;
                    height: 14px;
                }
              
            </style>
            <div style="${this._props.bodyStyle}">
                <div class="error-wrapper">
                    <svg data-qa="dt-icon" aria-hidden="true" role="img" data-name="Alert Triangle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" class="d-icon d-icon--alert-triangle d-icon--size-400"><path fill="currentColor" fill-rule="evenodd" d="M5.24 1.197a1.5 1.5 0 0 1 2.06.556l3.998 6.997a1.504 1.504 0 0 1 0 1.5 1.5 1.5 0 0 1-1.297.75H2.002a1.5 1.5 0 0 1-1.31-2.249V8.75L4.69 1.753a1.5 1.5 0 0 1 .55-.556Zm.755.796a.5.5 0 0 0-.435.254v.001L1.557 9.25a.5.5 0 0 0 .438.75H10a.5.5 0 0 0 .432-.75l-.001-.002-4-7-.001-.001a.5.5 0 0 0-.435-.254Z" clip-rule="evenodd"></path><path fill="currentColor" fill-rule="evenodd" d="M6 4a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 6 4Zm-.5 4.5A.5.5 0 0 1 6 8h.005a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5Z" clip-rule="evenodd"></path></svg>
                    <p>Failed to load knwoledge management</p>
                </div>  
            </div>
            
            `;
        }

        // Transforms the slots from the parent into render function slots
        createSlots(rootComponent: any) {
            const slotObject: { [x: string]: any } = {};
            [...rootComponent.children]
                .filter((child: HTMLElement) => child.nodeName === 'SLOT')
                .forEach((child: HTMLSlotElement) => {
                    slotObject[child?.name] = () =>
                        h('slot', {
                            name: child.name,
                            innerHTML: child?.innerHTML.trim()
                        });
                });
            return slotObject;
        }

        // This will inject all styles that are included in the project. It will go through all components and import their styles.
        recursiveStyleFetchVue(rootComponent: any, styles: any[] = []) {
            if (rootComponent?.styles) styles.push(rootComponent.styles);
            if (rootComponent?.components)
                styles.push(
                    Object.keys(rootComponent.components).map(
                        (componentKey: any) => {
                            return this.recursiveStyleFetchVue(
                                rootComponent.components[componentKey]
                            );
                        }
                    )
                );

            return styles;
        }
        // Create the application instance and render the component
        private createApp(org: Organisation, apiService: ApiService) {
            this._orgId = org.id;
            appRouter = createRouter(router);
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            const slots = this.createSlots(self);

            if (!self._props.zone) {
                /* eslint-disable no-console */
                console.log(
                    '%c[DX SDK]:',
                    'color:  #7C52FF; background-color: #FFF;',
                    'No zone was set, please bind it and refresh'
                );
                return;
            }
            if (
                !Object.values(ZONES_ABBR).some(
                    (zone) => zone === self._props.zone
                )
            ) {
                /* eslint-disable no-console */
                console.log(
                    '%c[DX SDK]:',
                    'color:  #7C52FF; background-color: #FFF;',
                    `Incorrect zone value set.\n\nCurrent: ${
                        self._props.zone
                    }\nExpected (one of the following): ${Object.values(
                        ZONES_ABBR
                    )?.join(', ')}`
                );
                return;
            }

            this._app = createApp({
                name: org.id,
                mounted() {
                    if (this.$refs.body) {
                        this.$store.commit(
                            `${org.id}/setBodyRef`,
                            this.$refs.body as HTMLElement
                        );
                    }
                    this.$store.commit(
                        `${org.id}/setLocale`,
                        self._props.locale || 'en'
                    );
                },
                render() {
                    return [
                        h(
                            'head',
                            self
                                .recursiveStyleFetchVue(App)
                                .map((s) => h('style', s))
                        ),
                        h(
                            'body',
                            {
                                id: 'root',
                                ref: 'body',
                                style: self._props.bodyStyle
                            },
                            [h(self._def, self._props, slots)]
                        )
                    ];
                }
            })
                .use(i18n)
                .use(appRouter)
                .use(gapiPlugin)
                .directive('on-resize', onResizeDirective)
                .directive('click-outside', clickOutsideDirective)
                .directive('feature', featureDirective)
                .directive('focus-input', focusInput)
                // @ts-ignore
                .directive('draggable', vDraggable)
                .provide('orgId', org.id);

            this._app.config.globalProperties.$store = store;
            Sentry.init({
                app: this._app,
                dsn: import.meta.env.VITE_APP_SENTRY_DSN,
                release: `dx-console@${version}`,
                tracesSampleRate: 0.5,
                parentSpanIsAlwaysRootSpan: false,
                integrations: [Sentry.browserTracingIntegration()],
                trackComponents: [
                    'HistoryView',
                    'AgentAssistView',
                    'AutomationsView',
                    'ChatteringView',
                    'ConsolidateView',
                    'DocumentsView',
                    'EngagementView',
                    'ExpandView',
                    'ImproveView',
                    'NodeEditorView',
                    'ResponsesView',
                    'SelfServiceView',
                    'StaticMessagesView'
                ],
                hooks: ['create', 'mount'],
                beforeSend(event: any) {
                    if (window.location.hostname === 'localhost') return null;
                    let filename = null;
                    const values = event?.exception?.values ?? [];
                    const latestValue =
                        values.length > 0 ? values[values.length - 1] : null;
                    const stackTrace = latestValue?.stacktrace?.frames ?? null;
                    const latestTrace =
                        stackTrace && stackTrace.length > 0
                            ? stackTrace[stackTrace.length - 1]
                            : null;
                    if (latestTrace) {
                        filename = latestTrace?.filename ?? null;
                    }
                    if (filename) {
                        if (
                            filename.includes('widget.bundle.js') ||
                            filename.includes('latest.js')
                        ) {
                            return event;
                        }
                    }
                    return null;
                },
                ignoreErrors: ['bmi_SafeAddOnload']
            });

            this._app.use(store);

            this._config = {
                apiService: apiService,
                organisationDetails: org,
                orgName: self._props.orgName,
                authToken: self._props.authToken,
                refreshToken: self._props.refreshToken,
                zone: self._props.zone,
                verbose: self._props.verbose,
                bodyStyle: self._props.bodyStyle,
                limited: self._props.limited,
                darkMode: self._props.darkMode,
                locale: self._props.locale,
                showSidebarMenu: self._props.showSidebarMenu,
                currentPath: self._props.currentPath,
                newWebchatClient: self._props.newWebchatClient,
                officeName: self._props.officeName,
                timezone: self._props.timezone
            };

            if (!store.hasModule(org.id!))
                store.registerModule(org.id!, AppModule(this._config), {
                    preserveState: false
                });

            PersistModule(store, org.id!, ['authToken', 'hasFetchedSources']);

            store.commit(`${org.id}/setUserId`, this._user.id);

            const hasFetchedSources =
                store.getters[`${org.id}/hasFetchedSources`];

            const isCurrentUserHasFetchedSources = Object.keys(
                hasFetchedSources
            ).some(
                (savedUserId: string) => atob(savedUserId) === this._user.id
            );
            if (!isCurrentUserHasFetchedSources) {
                store.commit(`${org.id}/setHasFetchedSources`, {
                    ...hasFetchedSources,
                    [btoa(this._user.id)]: {
                        hasFetched: false,
                        hasShown: false,
                        hasClicked: false
                    }
                });
            }

            appRouter.beforeEach((to, from, next) => {
                if (to.name === 'apps_integrations') {
                    featureIsEnabled('AUTOMATIONS') ? next() : next(from);
                    return;
                } else if (to.name.toString()?.length) {
                    featureIsEnabled(to.name.toString()) ? next() : next(from);
                    return;
                }

                const licenseNeeded = to.path.startsWith('/self-service')
                    ? LicenseType.Dss
                    : to.path.startsWith('/agent-assist') ||
                        to.path.startsWith('/apps-integrations')
                      ? LicenseType.Aaa
                      : to.path.startsWith('/sales-assist')
                        ? LicenseType.Asa
                        : undefined;

                if (licenseNeeded) {
                    const orgLicenses = store?.getters[`${org.id}/orgLicenses`];
                    const canAccessRoute = orgLicenses?.length
                        ? validateOrgLicense(orgLicenses, licenseNeeded)
                        : undefined;

                    if (canAccessRoute) next();
                    else {
                        // Defensive programming: if we don't have licenses here, do not let route through
                        if (!orgLicenses?.length) return next();
                        if (!from.name)
                            next({
                                name: convertLicenseTypeToRouteName(
                                    orgLicenses[0]
                                )
                            });
                        else next(from);
                    }
                } else next();
            });

            appRouter.beforeEach(async (to, from, next) => {
                if (!!from.name && isKnowledgeRoute(from.name)) {
                    next();
                    return;
                } else if (
                    isKnowledgeRoute(to.name || undefined) ||
                    to.name === 'node_editor'
                ) {
                    const apiService: ApiService =
                            store.getters[`${org.id}/apiService`],
                        authToken: string =
                            store.getters[`${org.id}/authToken`];
                    const appId = to.params.appId.toString();

                    const licenseNeeded = to.path.startsWith('/self-service')
                        ? APP_TYPES.SELF_SERVICE
                        : to.path.startsWith('/agent-assist') ||
                            to.path.startsWith('/apps-integrations')
                          ? APP_TYPES.AGENT_ASSIST
                          : to.path.startsWith('/sales-assist')
                            ? APP_TYPES.AGENT_ASSIST
                            : undefined;

                    const chatbot = await store.dispatch(
                        `${org.id}/widgets/fetchById`,
                        {
                            id: appId,
                            licenseType:
                                convertAppTypeToLicenseType(licenseNeeded)!
                        } as fetchByIdPayload
                    );

                    const licenseTypeToRouteName = (
                        to.params.licenseType as string
                    )?.replace('-', '_');
                    if (!chatbot) {
                        next({ name: licenseTypeToRouteName });
                        return;
                    }
                    store.commit(`${org.id}/setCurrentChatbot`, chatbot);

                    const kbId = to.params.kbId.toString();
                    const knowledgebase = await fetchKb(
                        kbId,
                        apiService,
                        authToken,
                        org.id!
                    );
                    if (!knowledgebase) {
                        next({ name: licenseTypeToRouteName });
                        return;
                    }
                    store.commit(
                        `${org.id}/setCurrentKnowledgeBase`,
                        knowledgebase
                    );
                }
                next();
            });

            store.dispatch(`${org.id}/initialize`, self._props.authToken);

            const SDKinstance = new SDKInstance(appRouter, this._config);

            if (
                !Object.prototype.hasOwnProperty.call(
                    (window as any).DialpadDX,
                    org.id!
                )
            ) {
                Object.assign((window as any).DialpadDX, {
                    [org.id!]: SDKinstance
                });
            }

            UserCallbackServiceInstance.TriggerOnKBMReady(
                store.getters[`${org.id}/currentOrg`]
            );

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this._app.mount(self.shadowRoot);

            if (self._props.darkMode !== undefined) {
                store.commit(`${org.id}/setDarkMode`, self._props.darkMode);
            }

            appRouter.afterEach((to) => {
                this.setAttribute('current-path', to.path);
            });
        }

        // Handle element being inserted into DOM
        async connectedCallback() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            self.attachShadow({ mode: 'open' });
            const componentProps = Object.entries(App.props);

            componentProps.forEach(([propName, propDetail]) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (propDetail.type === Number) {
                    this._numberProps.push(propName);
                }
                this.setAttr(propName);
            });
            this.createEventProxies();

            this.renderLoadingScreen();

            try {
                const serverUrl = new URL(
                    'flags',
                    `https://console.${self._props.zone}.karehq.com`
                );
                const res = await axios.get(serverUrl.toString());
                const flags = res.data || {};

                store.commit('setFeatureFlags', flags);
            } catch (e) {
                console.warn("KBM: Couldn't find flags");
            }

            const kareAPI = `https://api.${this._props.zone}.karehq.com/${
                import.meta.env.VITE_APP_API_VERSION
            }`;

            const urlParams = new URLSearchParams(window.location.search);
            const queryAuthToken = urlParams.get('auth-token');
            if (queryAuthToken?.length) {
                self._props.authToken = queryAuthToken;
            }

            if (self._props.authToken) {
                const token = self._props.authToken
                    .toLowerCase()
                    .startsWith('bearer')
                    ? self._props.authToken
                    : `Bearer ${self._props.authToken}`;
                const resOrg = await (
                    await fetch(`${kareAPI}/iam/org`, {
                        method: 'GET',
                        headers: {
                            ['Authorization']: token
                        }
                    })
                ).json();

                const resMe = await (
                    await fetch(`${kareAPI}/iam/users/me`, {
                        method: 'GET',
                        headers: {
                            ['Authorization']: token
                        }
                    })
                ).json();

                if (resMe) {
                    this._user = resMe;
                }

                if (resOrg) {
                    const org: Organisation = resOrg;
                    const canConnectToKoopid =
                        await this.verifyKoopidConnection(org, token);
                    self._props.limited = !canConnectToKoopid;

                    const axiosInstance = configureAxios(org.id);

                    const apiService = new ApiService(axiosInstance, kareAPI);

                    this.clearShadowRoot();
                    this.createApp(org, apiService);
                    this.mutationObserver = this.connectObserver();
                    this.mutationObserver.observe(this, {
                        attributes: true
                    });
                } else {
                    console.error(`Failed to fetch Kare organisation.`);
                    this.renderErrorScreen();
                }
            } else {
                console.warn(
                    'KBM: Failed to create application, org ID not provided.'
                );
            }
        }

        async verifyKoopidConnection(org: Organisation, authToken: string) {
            if (
                !org.digital_experience_provider?.url ||
                !org.dialpad_company?.id
            )
                return false;
            try {
                const res = await getDSSInstances(
                    org.dialpad_company?.id,
                    'default',
                    authToken,
                    org.digital_experience_provider
                );
                return res.data.code === 'success';
            } catch (e) {
                return false;
            }
        }

        disconnectedCallback() {
            this._app?.unmount();
            if (this._orgId) store.unregisterModule(this._orgId);
            this.mutationObserver?.disconnect();
        }
    }
    return VueCustomElement;
}
