<template>
    <div
        class="d-h100p"
        ref="optimizeWizardWrapper"
        v-on-resize="calculateWidth"
    >
        <dt-root-layout
            fixed
            class="d-h100p d-ps-relative"
            :sidebar-width="minSidebarWidth"
            sidebar-class="d-h100p d-ps-relative"
            content-class="d-bgc-secondary d-ps-relative"
        >
            <template #sidebar>
                <div
                    class="optimize-wizard-resize-parent d-h100p d-br d-brw1 d-bc-subtle d-bgc-primary"
                >
                    <div
                        class="optimize-wizard-resize-content d-h100p d-w100p d-ps-absolute d-zi-base1 d-box-border"
                    >
                        <div
                            class="d-w100p d-h100p d-px16 d-pt12 d-box-border d-d-flex d-fd-column"
                        >
                            <div class="d-d-flex d-ai-center d-mb16">
                                <Transition name="fade">
                                    <dt-button
                                        class="d-mr8"
                                        kind="muted"
                                        importance="clear"
                                        @click="handleBack"
                                        v-if="showBackButton"
                                    >
                                        <template #icon>
                                            <dt-icon
                                                name="chevron-left"
                                                size="200"
                                            />
                                        </template>
                                    </dt-button>
                                </Transition>
                                <div class="d-fs-100 d-d-flex d-fd-column">
                                    <div class="d-headline--lg">
                                        <dt-skeleton
                                            v-if="isLoading"
                                            :arial-label="$t('Loading')"
                                            :paragraphOption="{
                                                rows: 1,
                                                width: ['30%'],
                                                rowClass: 'd-h24'
                                            }"
                                        />
                                        <div v-else>
                                            {{ clusterTitle }}
                                            <span
                                                class="d-ml2"
                                                v-if="!showBackButton"
                                            >
                                                ({{
                                                    selectedQuestions?.length
                                                }})
                                            </span>
                                        </div>
                                    </div>
                                    <span class="d-body--sm">
                                        {{
                                            $t('Review questions in the group')
                                        }}
                                    </span>
                                </div>
                            </div>
                            <dt-skeleton
                                v-if="isLoading"
                                :arial-label="$t('Loading')"
                                :paragraphOption="{
                                    rows: 2,
                                    width: ['100%', '100%'],
                                    rowClass: 'd-h24'
                                }"
                            />
                            <!-- --------------------------------------------------------------- -->
                            <!--                               QUESTION                          -->
                            <!-- --------------------------------------------------------------- -->
                            <TransitionGroup
                                name="list"
                                tag="ul"
                                aria-labelledby="listQueries"
                            >
                                <li
                                    class="d-d-flex d-w100p"
                                    v-for="(q, i) in questions"
                                    :key="q.text"
                                    :data-index="i"
                                    @mouseenter="showHoverIcons(i)"
                                    @mouseleave="hideHoverIcons"
                                >
                                    <div
                                        class="d-d-flex d-mb4 d-w100p d-t d-td300"
                                    >
                                        <dt-card
                                            class="d-w100p d-bar8"
                                            :class="{
                                                'd-bgc-moderate': q.muted
                                            }"
                                            content-class="d-pl16 d-pr4 d-py4 d-d-flex d-ai-center"
                                            :container-class="{
                                                'd-ba d-bc-subtle d-bs-none d-fc-secondary': true
                                            }"
                                        >
                                            <template #content>
                                                <div
                                                    class="d-d-flex d-ai-center d-w100p d-hmn32"
                                                >
                                                    <dt-checkbox
                                                        :value="q.id"
                                                        :checked="q.selected"
                                                        :disabled="q.muted"
                                                        :aria-label="q.text"
                                                        @input="
                                                            (e: any) =>
                                                                handleCheckboxChange(
                                                                    e,
                                                                    i
                                                                )
                                                        "
                                                    />
                                                    <dt-badge
                                                        v-if="
                                                            markdown ||
                                                            nodeData?.response
                                                                ?.blocks
                                                        "
                                                        class="d-ml8"
                                                        :text="$t('New')"
                                                        type="success"
                                                    />
                                                    <div class="d-ml8 d-d-flex">
                                                        <span>
                                                            {{ q.text }}
                                                        </span>
                                                        <dt-tooltip
                                                            class="d-ml2"
                                                            v-if="!markdown"
                                                            placement="top-start"
                                                            :message="
                                                                $t(
                                                                    'How many times this question occurred'
                                                                )
                                                            "
                                                            content-class="d-fw-normal"
                                                        >
                                                            <template #anchor>
                                                                {{
                                                                    `(${q.count})`
                                                                }}
                                                            </template>
                                                        </dt-tooltip>
                                                    </div>
                                                </div>
                                                <!-- --------------------------------------------------------------- -->
                                                <!--                      QUESTION HOVER BUTTONS                     -->
                                                <!-- --------------------------------------------------------------- -->
                                                <dt-tooltip
                                                    class="d-ml-auto d-w64 d-d-flex d-jc-flex-end"
                                                    placement="top"
                                                    :message="
                                                        $t(
                                                            `${q.muted ? 'Unmute question' : 'Mute question'}`
                                                        )
                                                    "
                                                    content-class="d-fw-normal"
                                                >
                                                    <template #anchor>
                                                        <dt-button
                                                            class="d-as-flex-start"
                                                            importance="clear"
                                                            kind="muted"
                                                            size="xs"
                                                            @click="
                                                                toggleMuteQuestion(
                                                                    i
                                                                )
                                                            "
                                                            v-show="
                                                                hoverIcons === i
                                                            "
                                                            :active="q.muted"
                                                        >
                                                            <template #icon>
                                                                <dt-icon
                                                                    name="bell-off"
                                                                    size="200"
                                                                />
                                                            </template>
                                                        </dt-button>
                                                    </template>
                                                </dt-tooltip>
                                            </template>
                                        </dt-card>
                                    </div>
                                </li>
                            </TransitionGroup>
                            <!-- --------------------------------------------------------------- -->
                            <!--                           OLD QUESTIONS                         -->
                            <!-- --------------------------------------------------------------- -->
                            <node-questions
                                :node-id="nodeData?.id"
                                :org-id="orgId"
                                v-model:questions="oldQuestions"
                                :is-updating="isLoading"
                                is-optimized-wizard
                            />
                        </div>
                    </div>
                    <div
                        class="d-ps-relative d-r-horizontal d-of-x-auto d-zi-base optimize-wizard-resize"
                    />
                </div>
            </template>
            <template #default>
                <Transition name="fade">
                    <div
                        class="d-d-flex d-fd-column d-w100p d-pt12 d-px16 d-box-border d-bgc-secondary d-t d-td300"
                        v-if="showAddToDocument || showAddToResponse"
                    >
                        <div class="d-d-flex d-ai-center d-mb16">
                            <dt-button
                                class="d-mr8"
                                kind="muted"
                                importance="clear"
                                @click="handleAddToDocBack"
                            >
                                <template #icon>
                                    <dt-icon name="chevron-left" size="200" />
                                </template>
                            </dt-button>
                            <div class="d-fs-100 d-d-flex d-fd-column d-h48">
                                <span class="d-headline--lg">
                                    {{
                                        $t(
                                            `Add to ${
                                                showAddToResponse
                                                    ? 'response'
                                                    : 'document'
                                            }`
                                        )
                                    }}
                                </span>
                                <span class="d-body--sm">
                                    {{
                                        $t(
                                            `Pick which ${showAddToResponse ? 'response' : 'document'} to add to`
                                        )
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="d-mx32 d-mb32 d-d-flex d-fd-column">
                            <dt-input
                                v-show="
                                    nodes?.length ||
                                    documents?.length ||
                                    responses?.length ||
                                    search
                                "
                                aria-label="Search items"
                                :placeholder="`${$t('Search')} ${$t(currentNodeType)}`"
                                v-model="search"
                                :disabled="isLoading"
                                @input="debounce(fetchNodes)"
                            >
                                <template #leftIcon>
                                    <dt-icon name="search" size="200" />
                                </template>
                                <template v-if="search" #rightIcon>
                                    <dt-button
                                        kind="muted"
                                        importance="clear"
                                        size="xs"
                                        circle
                                        aria-label="Clear search"
                                        @click="handleClearInput"
                                    >
                                        <template #icon>
                                            <dt-icon name="close" size="200" />
                                        </template>
                                    </dt-button>
                                </template>
                            </dt-input>
                            <div
                                class="d-w100p d-px16 d-py4"
                                v-if="isNodesLoading"
                            >
                                <dt-skeleton
                                    :arial-label="$t('Loading')"
                                    :paragraphOption="{
                                        rows: 2,
                                        width: ['100%', '100%'],
                                        rowClass: 'd-h24 d-mt16'
                                    }"
                                />
                            </div>
                            <empty-state
                                class="d-mt16"
                                v-else-if="
                                    (!nodes?.length || search) &&
                                    (currentNodeType === 'responses'
                                        ? !responses.length
                                        : !documents.length)
                                "
                                :item-type="currentNodeType"
                                :hasSearchFilterOnly="!!search.length"
                            >
                                <template #subtitle>
                                    <div>
                                        <span
                                            class="d-body-compact d-fc-primary"
                                        >
                                            {{ `${$t('Head over to the')} ` }}
                                        </span>
                                        <dt-button link @click="handleRedirect">
                                            {{
                                                showAddToResponse
                                                    ? $t('Responses')
                                                    : $t('Documents')
                                            }}
                                        </dt-button>
                                        <span
                                            class="d-body-compact d-fc-primary"
                                        >
                                            {{
                                                ` ${$t('tab to get things started!')}`
                                            }}
                                        </span>
                                    </div>
                                </template>
                            </empty-state>
                            <div v-else>
                                <node-list
                                    v-if="nodes?.length && !search.length"
                                    :title="$t('Recommended')"
                                    :nodes="nodes"
                                    @node-click="handleOptimizeCardClick"
                                >
                                    <template #header>
                                        <dt-button
                                            v-if="showAddToResponse"
                                            class="d-fs-100"
                                            link
                                            @click="handleCreateNewResponse"
                                        >
                                            {{ $t('Create new response') }}
                                        </dt-button>
                                    </template>
                                </node-list>
                                <node-list
                                    v-if="
                                        (currentNodeType === 'responses' &&
                                            responses?.length) ||
                                        (currentNodeType === 'documents' &&
                                            documents?.length)
                                    "
                                    :title="
                                        search
                                            ? $t('Search results')
                                            : `${$t('All')} ${$t(currentNodeType)}`
                                    "
                                    :nodes="
                                        currentNodeType === 'responses'
                                            ? responses
                                            : documents
                                    "
                                    @node-click="handleOptimizeCardClick"
                                >
                                    <template #header>
                                        <dt-button
                                            v-if="
                                                showAddToResponse &&
                                                !nodes?.length
                                            "
                                            class="d-fs-100"
                                            link
                                            @click="handleCreateNewResponse"
                                        >
                                            {{ $t('Create new response') }}
                                        </dt-button>
                                    </template>
                                </node-list>
                            </div>
                        </div>
                    </div>
                    <!-- --------------------------------------------------------------- -->
                    <!--                      ADD TO DOCUMENT                            -->
                    <!-- --------------------------------------------------------------- -->
                    <div
                        class="d-d-flex d-fd-column d-w100p d-pt12 d-px16 d-box-border d-bgc-secondary d-h100p d-t d-td300"
                        v-else-if="markdown"
                    >
                        <header
                            class="d-d-flex d-ai-center d-mb16 d-h48 d-w100p"
                        >
                            <dt-button
                                class="d-mr8"
                                kind="muted"
                                importance="clear"
                                @click="handleMarkdownBack"
                            >
                                <template #icon>
                                    <dt-icon name="chevron-left" size="200" />
                                </template>
                            </dt-button>
                            <div class="d-fs-100 d-d-flex d-fd-column d-w100p">
                                <span class="d-headline--lg">
                                    {{ nodeData?.title }}
                                </span>
                                <div class="d-d-flex">
                                    <label-tag
                                        :labels="nodeData?.labels"
                                        chip-size="xs"
                                    />
                                </div>
                            </div>
                        </header>
                        <main
                            class="d-ml32 d-baw1 d-ba d-bc-subtle d-bar16 d-px2 d-py4 d-bgc-primary d-fl1"
                        >
                            <rich-text-editor
                                :editable="false"
                                :markdown="markdown"
                            />
                        </main>
                        <footer class="d-d-flex d-jc-flex-end d-bgc-secondary">
                            <dt-button
                                class="d-my16"
                                @click="patchNode"
                                :disabled="!selectedQuestions.length"
                            >
                                {{ $t('Add to this document') }}
                            </dt-button>
                        </footer>
                    </div>
                    <!-- --------------------------------------------------------------- -->
                    <!--                      ADD TO RESPONSE                            -->
                    <!-- --------------------------------------------------------------- -->
                    <div
                        class="d-d-flex d-fd-column d-w100p d-pt12 d-px16 d-box-border d-bgc-secondary d-h100p d-t d-td300"
                        v-else-if="nodeData?.response?.blocks"
                    >
                        <header class="d-d-flex d-ai-center d-mb16">
                            <dt-button
                                class="d-mr8"
                                kind="muted"
                                importance="clear"
                                @click="handleResponseBack"
                            >
                                <template #icon>
                                    <dt-icon name="chevron-left" size="200" />
                                </template>
                            </dt-button>
                            <div class="d-fs-100 d-d-flex d-fd-column">
                                <span class="d-headline--lg">
                                    {{ nodeData?.title }}
                                </span>
                                <div class="d-d-flex">
                                    <label-tag :labels="nodeData?.labels" />
                                </div>
                            </div>
                        </header>
                        <main class="d-ml32 d-px2 d-py4 d-pb64 d-fl1 d-of-auto">
                            <response-block-renderer
                                :raw-blocks="nodeData.response.blocks"
                                view-mode
                            />
                        </main>
                        <footer class="d-d-flex d-jc-flex-end d-bgc-secondary">
                            <dt-button
                                class="d-my16"
                                @click="patchNode"
                                :disabled="!selectedQuestions.length"
                            >
                                {{ $t('Add to this response') }}
                            </dt-button>
                        </footer>
                    </div>
                    <!-- --------------------------------------------------------------- -->
                    <!--                         ACTION CARDS                            -->
                    <!-- --------------------------------------------------------------- -->
                    <div
                        class="d-d-flex d-fd-column d-w100p d-pt12 d-px16 d-box-border d-bgc-secondary"
                        v-else
                    >
                        <span class="d-headline--lg">
                            {{ $t('What would you like to do?') }}
                        </span>
                        <span class="d-body--sm d-mb16">
                            {{
                                $t(
                                    'Pick how you want to handle this group of questions'
                                )
                            }}
                        </span>
                        <ul>
                            <li
                                v-for="(action, i) in actions"
                                :key="action.title"
                                @mouseenter="hoverCard(`${action.title}-${i}`)"
                                @mouseleave="hideHoverCard"
                            >
                                <action-card
                                    :is-loading="isLoading"
                                    :title="action.title"
                                    :description="action.description"
                                    :badge-type="action.badgeType"
                                    :badge-text="action.badgeText"
                                    :is-hover="
                                        `${action.title}-${i}` === actionCard
                                    "
                                    @click="handleAction(i)"
                                />
                            </li>
                        </ul>
                    </div>
                </Transition>
            </template>
        </dt-root-layout>
        <response-title-modal
            :org-id="orgId"
            v-model:show-prompt="showPrompt"
            :inherited-questions="selectedQuestions"
            :cluster-id="clusterId"
            current-tab="improve"
        />
        <confirmation-modal
            :title="
                $t(
                    isMuteConfirmationModal
                        ? 'Mute all questions'
                        : 'Snooze question group'
                )
            "
            :description="confirmationModalDescription"
            kind="default"
            :button-title="
                $t(
                    isMuteConfirmationModal
                        ? 'Yes, mute all'
                        : 'Yes, snooze question group'
                )
            "
            v-model:show-prompt="showConfirmationModal"
            @confirm="handleConfirm"
            no-translation
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { useRoute } from 'vue-router';

import {
    DtRootLayout,
    DtButton,
    DtIcon,
    DtInput,
    DtCard,
    DtBadge,
    DtCheckbox,
    DtSkeleton,
    DtTooltip
} from '@dialpad/dialtone/vue3';

import {
    ClusterDetail,
    ClusterQuery,
    Knowledgebase,
    type NodeDetail,
    NodeStatus,
    PatchClusterRequest
} from '@/open-api';

import { NodeType } from '@/open-api';

import type { ApiService } from '@/services/Api.service';

import { createDebounce, handleRequest, type IQuestion } from '@/utils/Common';
import type { INotification } from '@/utils/types/Notification';
import { NOTICE_KINDS, OPTIMIZE_ACTIONS } from '@/utils/Constants';

import type { fetchPayload } from '@/store/DocumentsModule';

import ActionCard from '@/components/node-editor/ActionCard.vue';
import OptimizeCard from '@/components/node-editor/OptimizeCard.vue';
import RichTextEditor from '@/components/rich-text-editor/RichTextEditor.vue';
import LabelTag from '@/components/label-tag/LabelTag.vue';
import ResponseTitleModal from '@/components/node-editor/ResponseTitleModal.vue';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal.vue';
import EmptyState from '@/components/empty-state/EmptyState.vue';
import NodeQuestions from '@/components/node-editor/NodeQuestions.vue';
import ResponseBlockRenderer from '@/components/node-editor/ResponseBlockRenderer.vue';
import NodeList from '@/components/node-editor/NodeList.vue';

export default defineComponent({
    components: {
        DtSkeleton,
        DtCard,
        DtButton,
        DtIcon,
        DtRootLayout,
        DtInput,
        DtBadge,
        DtCheckbox,
        DtTooltip,
        ResponseTitleModal,
        LabelTag,
        ActionCard,
        OptimizeCard,
        RichTextEditor,
        ConfirmationModal,
        EmptyState,
        NodeQuestions,
        ResponseBlockRenderer,
        NodeList
    },
    setup() {
        const orgId: string = inject('orgId')!;

        const route = useRoute();
        const clusterId = route?.params?.clusterId as string | undefined;
        const paramSubTab = route?.query?.subTab as string | undefined;

        return {
            debounce: createDebounce(),
            orgId,
            clusterId,
            paramSubTab
        };
    },
    mounted() {
        this.calculateWidth();
        this.getClusterData();

        const notificationMsg = this.$route.query.notification as string;

        if (notificationMsg) {
            this.$store.commit(`${this.orgId}/addNotification`, {
                kind: NOTICE_KINDS.SUCCESS,
                message: `${this.$t(
                    this.isTypeResponse
                        ? 'Response'
                        : this.isTypeDocument
                          ? 'Document'
                          : 'Chatter'
                )} ${this.$t(notificationMsg)}`
            } as INotification);
            this.$router.replace({ query: { notification: null } });
        }
    },
    watch: {
        search(newSearch) {
            if (newSearch.length) {
                this.isNodesLoading = true;
            }
        }
    },
    computed: {
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        selectedQuestions(): string[] {
            return this.questions
                .filter((q: IQuestion) => q.selected)
                .map((q: IQuestion) => q.text);
        },
        mutedQuestions(): NodeDetail[] {
            return this.questions
                .filter((q: IQuestion) => q.muted)
                .map((q: IQuestion) => q.text);
        },
        questionsArr(): NodeDetail[] {
            return this.questions.map((q: IQuestion) => q.text);
        },
        confirmationModalDescription(): string {
            if (!this.clusterTitle && !this.questions?.length) return '';
            return this.isMuteConfirmationModal
                ? `${this.$t('All')} ${this.questionsArr?.length > 1 ? this.questionsArr?.length + ' ' : ''}"${this.clusterTitle}" ${this.$t('questions will be muted. You can always unmute questions from the “Muted questions” page. Are you sure you want to mute all of the questions in this group?')}`
                : this.$t(
                      'This action will snooze all of the questions in the question group for 24 hours. You can always unsnooze question groups from the “Snoozed” page. Are you sure you want to snooze all the questions in this group?'
                  );
        },
        isMuteConfirmationModal(): boolean {
            return this.confirmationModalType === 'mute';
        },
        responses(): NodeDetail[] {
            return this.$store.getters[`${this.orgId}/responses/list`];
        },
        documents(): NodeDetail[] {
            return this.$store.getters[`${this.orgId}/documents/list`];
        },
        currentNodeType(): string {
            return this.showAddToResponse ? 'responses' : 'documents';
        }
    },
    methods: {
        async getClusterData() {
            this.isLoading = true;
            const res = await handleRequest<ClusterDetail>(
                this.apiService.improve.getCluster(
                    this.authToken,
                    this.knowledgebase?.id,
                    this.clusterId
                ),
                this.orgId
            );
            this.isLoading = false;
            this.rawData = res.data;

            if (this.rawData?.queries?.length) {
                this.questions = this.rawData.queries.map(
                    (q: ClusterQuery) => ({
                        id: q.id,
                        text: q.query,
                        count: q.query_count,
                        selected: true,
                        muted: false
                    })
                );
                const questionMostCount = this.rawData.queries.reduce(
                    (max: any, q: any) =>
                        max.query_count > q.query_count ? max : q
                );
                this.clusterTitle = questionMostCount.query;
                this.state = this.rawData.status!;

                this.selectedLabels = this.rawData.labels as string[];
                this.blocks =
                    this.rawData.response?.blocks ||
                    this.rawData.chattering?.blocks ||
                    [];
            }
        },
        calculateWidth() {
            const optimizeWizardWrapper = this.$refs
                .optimizeWizardWrapper as HTMLElement;
            const editorWidth = optimizeWizardWrapper?.clientWidth;
            this.minSidebarWidth = `${editorWidth * 0.4}px`;
            this.maxSidebarWidth = `${editorWidth * 0.5}px`;
        },
        showHoverIcons(i: number) {
            this.hoverIcons = i;
        },
        hideHoverIcons() {
            this.hoverIcons = null;
        },
        toggleMuteQuestion(i: number) {
            this.questions[i].muted = !this.questions[i].muted;
            this.questions[i].selected = false;
        },
        hoverCard(i: string) {
            this.actionCard = i;
        },
        hideHoverCard() {
            this.actionCard = undefined;
        },
        async handleAction(i: number) {
            switch (i) {
                case 0:
                    this.changeWidth();
                    this.showAddToDocument = true;
                    this.showBackButton = false;
                    await this.getClusterDetails();
                    await this.fetchNodes();
                    return;
                case 1:
                    this.showConfirmationModal = true;
                    this.confirmationModalType = 'snooze';
                    return;
                case 2:
                    this.changeWidth();
                    this.showAddToResponse = true;
                    this.showBackButton = false;
                    await this.getClusterDetails();
                    await this.fetchNodes();
                    return;
                case 3:
                    this.showConfirmationModal = true;
                    this.confirmationModalType = 'mute';
                    return;
                default:
                    return;
            }
        },
        handleCheckboxChange(checked: any, i: number) {
            this.questions[i].selected = checked;
            if (checked) {
                this.questions[i].muted = false;
            }
        },
        handleBack() {
            this.$router.push({
                name: 'improve',
                params: {
                    appId: this.$route.params?.appId,
                    kbId: this.knowledgebase?.id
                },
                query: {
                    subTab: this.paramSubTab
                }
            });
        },
        handleAddToDocBack() {
            this.showAddToDocument = false;
            this.showAddToResponse = false;
            this.showBackButton = true;
            const optimizeWizardWrapper = this.$refs
                .optimizeWizardWrapper as HTMLElement;
            const editorWidth = optimizeWizardWrapper?.clientWidth;
            this.minSidebarWidth = `${editorWidth * 0.4}px`;
            this.search = '';
        },
        changeWidth() {
            const optimizeWizardWrapper = this.$refs
                .optimizeWizardWrapper as HTMLElement;
            const editorWidth = optimizeWizardWrapper?.clientWidth;
            this.minSidebarWidth = `${editorWidth * 0.35}px`;
        },
        async fetchNodes() {
            this.isNodesLoading = true;

            await this.$store.dispatch(
                `${this.orgId}/${this.currentNodeType}/fetch`,
                {
                    kbId: this.knowledgebase?.id,
                    status: NodeStatus.Published,
                    source: undefined,
                    labels: this.labelsSelectedFilters,
                    sort: 'title',
                    order: 'asc',
                    search: this.search ?? undefined
                } as fetchPayload
            );

            this.isNodesLoading = false;
        },
        async fetchMarkdown(documentId: string) {
            return await handleRequest<string>(
                this.apiService?.knowledge.getMarkdownContent(
                    this.authToken,
                    this.knowledgebase?.id,
                    documentId
                ),
                this.orgId
            );
        },
        async patchNode() {
            if (!this.selectedQuestions.length) {
                return;
            }

            if (this.mutedQuestions.length) {
                await this.handleMuteQuestion();
            }

            const queries = [
                ...this.nodeData.queries,
                ...this.selectedQuestions
            ];
            const patchNodeRes = await handleRequest<NodeDetail>(
                this.apiService?.knowledge.patchNode(
                    this.authToken,
                    this.knowledgebase.id!,
                    this.nodeData.id,
                    {
                        queries
                    }
                ),
                this.orgId
            );
            const remainingClusterQuestions = this.questions.filter(
                (clusterQuestion: IQuestion) =>
                    !queries.filter(
                        (query: IQuestion) => query.id === clusterQuestion.id
                    )
            );

            const body: PatchClusterRequest = {
                cluster: {
                    queries: remainingClusterQuestions,
                    status: remainingClusterQuestions.length
                        ? 'active'
                        : 'approved'
                }
            };
            const patchClusterRes = await handleRequest<ClusterDetail>(
                this.apiService.improve.patchCluster(
                    this.authToken,
                    this.knowledgebase?.id,
                    this.clusterId,
                    this.showAddToDocument
                        ? NodeType.Content
                        : NodeType.Response,
                    body
                ),
                this.orgId
            );

            if (!patchNodeRes.error && !patchClusterRes.error) {
                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.SUCCESS,
                    title: `${this.selectedQuestions.length} ${this.selectedQuestions.length > 1 ? this.$t('questions') : this.$t('question')} ${this.$t(
                        this.markdown
                            ? 'added to document'
                            : 'added to response'
                    )} "${this.nodeData?.title}"`
                } as INotification);
                this.handleBack();
            }
        },
        async handleOptimizeCardClick(data: NodeDetail) {
            this.nodeData = data;
            this.oldQuestions = data?.queries.map((q: string) => ({
                text: q,
                status: 'keep'
            }));
            if (this.showAddToDocument) {
                this.markdown = (await this.fetchMarkdown(data.id))?.data ?? '';
                this.showAddToDocument = false;
            } else {
                this.showAddToResponse = false;
            }
        },
        handleMarkdownBack() {
            this.showAddToDocument = true;
            this.nodeData = undefined;
            this.oldQuestions = undefined;
            this.markdown = '';
        },
        handleResponseBack() {
            this.showAddToResponse = true;
            this.nodeData = undefined;
            this.oldQuestions = undefined;
        },
        handleClearInput() {
            this.search = '';
            this.fetchNodes();
        },
        async handleSnooze() {
            this.isLoading = true;
            const body: PatchClusterRequest = {
                cluster: {
                    status: 'snoozed'
                }
            };
            const res = await handleRequest<ClusterDetail>(
                this.apiService.improve.patchCluster(
                    this.authToken,
                    this.knowledgebase?.id,
                    this.clusterId,
                    this.showAddToDocument
                        ? NodeType.Content
                        : NodeType.Response,
                    body
                ),
                this.orgId
            );
            this.isLoading = false;

            if (res.error) {
                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.ERROR,
                    title: 'Error'
                } as INotification);
            } else {
                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.SUCCESS,
                    title: `"${this.clusterTitle}" ${this.$t('question group snoozed for 24 hours')}`,
                    action: {
                        title: this.$t('View snoozed'),
                        routerName: 'improve',
                        tabName: 'snoozed'
                    }
                } as INotification);
                this.handleBack();
            }
        },
        async getClusterDetails() {
            this.nodes = [];
            this.isNodesLoading = true;
            const res = await handleRequest<ClusterDetail>(
                this.apiService.improve.getCluster(
                    this.authToken,
                    this.knowledgebase.id,
                    this.clusterId,
                    this.showAddToDocument
                        ? NodeType.Content
                        : NodeType.Response
                ),
                this.orgId
            );
            this.isNodesLoading = false;

            if (!res.error) {
                this.nodes = res.data.nodes;
            }
        },
        handleCreateNewResponse() {
            this.showPrompt = true;
        },
        async handleMuteQuestion(all: boolean) {
            this.isLoading = true;
            const mutedQuestionsIds = all
                ? this.questions.map((q: IQuestion) => q.id)
                : this.questions
                      .filter((q: IQuestion) => q.muted)
                      .map((q: IQuestion) => q.id);
            const muteQueriesRes = await handleRequest<ClusterDetail>(
                this.apiService.improve.muteQueries(
                    this.authToken,
                    this.knowledgebase.id,
                    {
                        queries: mutedQuestionsIds
                    }
                ),
                this.orgId
            );

            if (!muteQueriesRes.error) {
                const queriesLeft = this.questions.filter(
                    (q: IQuestion) => !q.muted
                );
                if (all || !queriesLeft.length) {
                    const body: PatchClusterRequest = {
                        cluster: {
                            status: 'approved'
                        }
                    };
                    const patchClusterRes = await handleRequest<ClusterDetail>(
                        this.apiService.improve.patchCluster(
                            this.authToken,
                            this.knowledgebase?.id,
                            this.clusterId,
                            undefined,
                            body
                        ),
                        this.orgId
                    );

                    if (!patchClusterRes.error) {
                        this.$store.commit(`${this.orgId}/addNotification`, {
                            kind: NOTICE_KINDS.SUCCESS,
                            title: `${this.questionsArr?.length} "${this.clusterTitle}" ${this.questionsArr?.length > 1 ? this.$t('questions muted') : this.$t('question muted')}`,
                            action: {
                                title: this.$t('View muted questions'),
                                routerName: 'improve',
                                tabName: 'muted_questions'
                            }
                        } as INotification);
                        this.handleBack();
                    }
                }
            }

            this.isLoading = false;
        },
        handleRedirect() {
            this.$router.push({
                name: this.currentNodeType,
                params: {
                    kbId: this.knowledgebase.id
                },
                query: {
                    tab: this.currentNodeType
                }
            });
        },
        handleConfirm() {
            this.isMuteConfirmationModal
                ? this.handleMuteQuestion(true)
                : this.handleSnooze();
        }
    },
    data() {
        return {
            minSidebarWidth: '',
            maxSidebarWidth: '',
            isLoading: false,
            isNodesLoading: false,
            rawData: null as null | ClusterDetail,
            questions: [] as IQuestion[],
            clusterTitle: '',
            hoverIcons: null as null | number,
            actionCard: undefined as undefined | string,
            showAddToDocument: false,
            showAddToResponse: false,
            showBackButton: true,
            search: '',
            nodes: [],
            markdown: '',
            nodeData: undefined as undefined | NodeDetail,
            oldQuestions: [] as IQuestion[],
            actions: OPTIMIZE_ACTIONS as {
                title: string;
                description: string;
                badgeType?: string;
                badgeText?: string;
            }[],
            showPrompt: false,
            showConfirmationModal: false,
            confirmationModalType: ''
        };
    }
});
</script>

<style lang="less">
.optimize-wizard-resize {
    min-width: v-bind(minSidebarWidth);
    max-width: v-bind(maxSidebarWidth);

    /* this is necessary to make the scrollbars appear */
    &::before {
        content: '';
        width: 105%;
        height: 105%;
        background: var(--bgc-primary);
        pointer-events: none;
        display: block;
        position: absolute;
    }

    /* the scrollbar controls the size of the resizer */
    &::-webkit-scrollbar {
        /*at least the same as .resize */
        width: 5px;
    }

    &::-webkit-resizer {
        background: var(--dt-color-black-100);
    }

    &:hover,
    &:active {
        &::-webkit-resizer {
            background: hsla(
                var(--dt-color-purple-200-h) var(--dt-color-purple-200-s)
                    var(--dt-color-purple-200-l) / var(--bgo)
            ) !important;
        }
    }
}

.optimize-wizard-resize-content {
    padding-left: 5px;
    /* same scrollbar width */
    margin-left: -5px;
    /* same scrollbar width */
}
</style>
