import type { Module } from 'vuex';
import {
    ListMutedQueriesResponse,
    MutedQuery,
    Organisation,
    UnmuteQueriesParamsBody
} from '@/open-api';
import type { ApiService } from '@/services/Api.service';
import { handleRequest } from '@/utils/Common';

export interface MutedQueriesModuleState {
    data: MutedQuery[];
    fetching: boolean;
    error: boolean;
    totalHits?: number;
    canFetchMore?: boolean;
    kbId: string;
    order?: string;
    sort?: string;
    limit?: number;
}

export default function (org: Organisation) {
    return {
        namespaced: true,
        state: {
            data: [],
            fetching: false,
            error: false,
            totalHits: 0,
            canFetchMore: undefined,
            cursor: undefined,
            kbId: undefined
        },
        getters: {
            list(state): MutedQuery[] {
                return state.data;
            },
            isFetching(state): boolean {
                return state.fetching;
            },
            totalHits(state): number {
                return state.totalHits;
            },
            hasError(state): number {
                return state.error;
            },
            cursor(state): string {
                return state.cursor;
            },
            canFetchMore(state): boolean {
                return state.canFetchMore;
            },
            kbId(state) {
                return state.kbId;
            }
        },
        actions: {
            async fetch(
                { commit, rootGetters, getters },
                { kbId, sort, order, limit, cursor, disableFetching }
            ) {
                const apiService: ApiService =
                    rootGetters[`${org.id}/apiService`];
                const authToken: string = rootGetters[`${org.id}/authToken`];

                if (!disableFetching) commit('setFetching', true);

                commit('setError', false);
                commit('setKbId', kbId);
                commit('setSort', sort);
                commit('setOrder', order);
                commit('setLimit', limit);
                commit('setCursor', cursor);

                try {
                    const res = await handleRequest<ListMutedQueriesResponse>(
                        apiService.improve.listMutedQueries(
                            authToken,
                            kbId,
                            limit,
                            cursor,
                            sort,
                            order
                        ),
                        org.id
                    );
                    const queriesList = cursor
                        ? [...getters.list, ...res.data.queries]
                        : res.data.queries;
                    commit('setData', queriesList);
                    commit('setCursor', res.data.next_cursor);
                    commit('setTotalHits', queriesList.length);
                    commit('setCanFetchMore', !!res.data.next_cursor);
                } catch (e) {
                    commit('setError', true);
                }

                if (!disableFetching) commit('setFetching', false);
            },
            async unmute({ commit, rootGetters }, { kbId, selectedQueryId }) {
                const apiService: ApiService =
                    rootGetters[`${org.id}/apiService`];
                const authToken: string = rootGetters[`${org.id}/authToken`];

                commit('setKbId', kbId);

                const body: UnmuteQueriesParamsBody = {
                    queries: [selectedQueryId]
                };

                return await handleRequest(
                    apiService.improve.unmuteQueries(authToken, kbId, body),
                    this.orgId
                );
            },
            async fetchMore(
                { getters, dispatch },
                { kbId, sort, order, limit, cursor, disableFetching }
            ) {
                const canFetchMore = getters['canFetchMore'];
                if (canFetchMore) {
                    dispatch('fetch', {
                        kbId,
                        sort,
                        order,
                        limit,
                        cursor,
                        disableFetching
                    });
                }
            },
            resetSort({ commit }) {
                commit('setSort', 'query');
                commit('setOrder', 'asc');
            }
        },
        mutations: {
            setData(state, data: MutedQuery[]) {
                state.data = data;
            },
            setTotalHits(state, totalHits: number) {
                state.totalHits = totalHits;
            },
            setFetching(state, fetching: boolean) {
                state.fetching = fetching;
            },
            setError(state, error: boolean) {
                state.error = error;
            },
            setSort(state, sort?: string) {
                state.sort = sort;
            },
            setOrder(state, order?: 'asc' | 'desc') {
                state.order = order;
            },
            setLimit(state, limit: number) {
                state.limit = limit;
            },
            setCursor(state, cursor: string) {
                state.cursor = cursor;
            },
            setCanFetchMore(state, canFetchMore: boolean) {
                state.canFetchMore = canFetchMore;
            },

            setKbId(state, kbId: string) {
                state.kbId = kbId;
            }
        }
    } as Module<MutedQueriesModuleState, any>;
}
