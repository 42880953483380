<template>
    <default-template-view
        :title="$t('History')"
        :title-description="
            $t(
                `View every conversation users have had with your ${chatbotName} in this section.`
            )
        "
        :description-link="{
            title: $t('Need help?'),
            href: 'https://help.dialpad.com/v1/docs/en/create-an-ai-chatbot'
        }"
        show-timezone
        :search-placeholder="$t('Search usernames')"
        v-model:search="searchUsername"
        :is-fetching="isFetching"
        :has-loaded="hasLoaded"
        :total-items="totalConversations"
        :has-error="hasError"
        :table-data="rawData"
        hide-search
    >
        <template #actionButtons>
            <filter-popover
                id="historyDatesPopover"
                type="radio"
                :options="DATE_OPTIONS"
                title="date options"
                v-model:selected-options="dateSelectedFilters"
                :fetch="fetchAll"
                :is-fetching="isFetching"
                hide-checkboxes
                close-on-select
                dialog-style="d-w164"
                is-normal-select-style
                translate-item
                placement="bottom-end"
            />
        </template>
        <template #filters>
            <filter-popover
                id="historyLabelsPopover"
                :options="tags"
                title="labels"
                v-model:selected-options="labelsSelectedFilters"
                :fetch="fetchAll"
                :is-fetching="isFetchingTags"
            />
        </template>
        <template
            #table="{ titleHeight, searchFiltersBarHeight }"
            v-if="isFetching || (rawData?.length && !hasError)"
        >
            <base-table
                :is-fetching="isFetching"
                id="HistoryTable"
                :columns="columns"
                :data="rawData"
                :has-error="hasError"
                @row-click="onRowClick"
                :total-hits="totalConversations"
                :row-count="itemsPerBatch"
                item-type="chatbot"
                :pagination="false"
                :hide-checkbox="true"
                :active-row-id="activeRowId"
                v-model:sort="sortByVal"
                v-model:selected-rows="selectedRows"
                fixed-header
                :title-height="titleHeight"
                :search-filters-bar-height="searchFiltersBarHeight"
                :is-fetching-more="isFetchingMore"
                :row-height="HISTORY_PAGE_ROW_HEIGHT"
            />
        </template>
    </default-template-view>
    <web-component-loader
        v-model:loading="aiAssistLoading"
        :source="aiAssistChatbotUrl"
    />
    <empty-state
        v-if="!rawData.length && !isFetching && !hasError"
        item-type="chatbot"
    >
        <template #title>
            <span>
                {{ $t(`No ${chatbotName} history`) }}
            </span>
        </template>
        <template #subtitle>
            <p>
                {{
                    $t(
                        `Once the ${chatbotName} is being used, all conversations will appear here`
                    )
                }}
            </p>
            <p>{{ $t('Navigate to any Ai Contact Center to deploy') }}</p>
        </template>
    </empty-state>
</template>

<script lang="tsx">
import { defineComponent, h, inject } from 'vue';
import { useRoute } from 'vue-router';

import type {
    Conversation,
    GetConversationResponse,
    Knowledgebase,
    ListConversationEvents,
    ListConversationsResponse,
    Widget
} from '@/open-api';

import { LicenseType, type ListTagsOKBody } from '@/open-api';

import {
    DtButton,
    DtChip,
    DtIcon,
    DtInput,
    DtSkeleton,
    DtTab,
    DtTabGroup,
    DtTabPanel
} from '@dialpad/dialtone/vue3';

import DefaultTemplateView from '@/views/DefaultTemplateView.vue';

import FilterPopover from '@/components/filter-popover/FilterPopover.vue';
import BaseTable from '@/components/base-table/BaseTable.vue';
import type {
    IBaseSortableTableColumn,
    IBaseTableColumn,
    IBaseTableRow
} from '@/components/base-table/BaseTable.types';
import WebComponentLoader from '@/components/webcomponent-loader/WebComponentLoader.vue';
import type { Drawer, DrawerService } from '@/services/Drawer.service';
import HistoryDrawer from '@/components/history-drawer/HistoryDrawer.vue';
import EmptyState from '@/components/empty-state/EmptyState.vue';
import LabelTag from '@/components/label-tag/LabelTag.vue';

import type { ApiService } from '@/services/Api.service';

import {
    automationsEnabled,
    getFormattedHour,
    getFromToDates,
    handleRequest,
    type IFilter,
    replaceQueryFilterParams,
    uuidv4,
    getFullDateFromTimestamp,
    updatePathParams,
    convertTimezone
} from '@/utils/Common';
import {
    DATE_OPTIONS,
    type DIALPAD_LOCALES,
    DRAWER_WIDTH,
    HISTORY_PAGE_ROW_HEIGHT,
    NOTICE_KINDS
} from '@/utils/Constants';
import { Observable, Subject, Subscription } from 'rxjs';
import { APP_BY_LICENSE_TYPE } from '@/utils/types/App';

export default defineComponent({
    components: {
        WebComponentLoader,
        DefaultTemplateView,
        BaseTable,
        FilterPopover,
        EmptyState,
        DtChip,
        DtTabGroup,
        DtTab,
        DtTabPanel,
        DtButton,
        DtInput,
        DtIcon,
        DtSkeleton
    },
    setup() {
        const orgId: string = inject('orgId')!;
        const $route = useRoute();
        const selectedLabels = $route.query.labels
            ? $route.query.labels?.toString().split(',')
            : [];
        const selectedDate =
            $route.query.date && $route.query.date.length
                ? [$route.query.date.toString()]
                : ['30'];

        const onScrollY$: Observable<any> = (
            inject('onRootScrollY$')! as Subject<Event>
        ).asObservable();

        const conversationId = $route.params.conversationId;

        return {
            orgId,
            selectedLabels,
            selectedDate,
            onScrollY$,
            conversationId
        };
    },
    async mounted() {
        this.hasAutomationsEnabled = await automationsEnabled(
            this.apiService,
            this.authToken
        );
        await this.fetchAll();
        if (this.$refs.historyWrapper) {
            this.wrapperHeight = (
                this.$refs.historyWrapper as HTMLElement
            ).clientHeight;
            this.wrapperWidth = (
                this.$refs.historyWrapper as HTMLElement
            ).clientWidth;
        }
        this.rootOnScrollSubscription = this.onScrollY$.subscribe(
            this.onScroll
        );

        if (this.conversationId?.length) {
            const { data, error } = await this.fetchConversationById();
            if (error) {
                this.$store.commit(`${this.orgId}/addNotification`, {
                    kind: NOTICE_KINDS.ERROR,
                    title: this.$t('Failed to fetch conversation')
                });
            } else {
                const { conversation } = data;
                await this.openHistoryDrawer(conversation);
                this.activeRowId = conversation.id;
            }
        }
    },
    beforeUnmount() {
        this.rootOnScrollSubscription?.unsubscribe();
    },
    watch: {
        searchUsername: {
            handler() {
                this.fetchAll();
            }
        }
    },
    computed: {
        currentChatbot(): Widget {
            return this.$store.getters[`${this.orgId}/currentChatbot`];
        },
        currentKnowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        totalConversations(): number {
            return this.rawData?.length;
        },
        drawerService(): DrawerService | undefined {
            return this.$store.getters[`${this.orgId}/drawerService`];
        },
        apiService(): ApiService {
            return this.$store.getters[`${this.orgId}/apiService`];
        },
        authToken(): string {
            return this.$store.getters[`${this.orgId}/authToken`];
        },
        isSelfServiceChatbot(): boolean {
            return this.currentChatbot.license_type === LicenseType.Dss;
        },
        isAgentAssistChatbot(): boolean {
            return this.currentChatbot.license_type === LicenseType.Aaa;
        },
        automationsTitle(): string {
            return this.isSelfServiceChatbot ? 'Workflows' : 'Automations';
        },
        labels(): string | undefined {
            if (!this.tagsSelected?.length) return;
            return this.tagsSelected.join(',');
        },
        locale(): DIALPAD_LOCALES {
            return this.$store.getters[`${this.orgId}/locale`];
        },
        timezone(): string | undefined {
            return this.$store.getters[`${this.orgId}/timezone`];
        },
        columns(): IBaseTableColumn<Conversation>[] {
            const moreInfoColumns: IBaseTableColumn<Conversation>[] = this
                .conversationHistoryOpen
                ? []
                : [
                      {
                          key: 'missing_queries',
                          type: 'string',
                          title: 'Unanswered questions',
                          template: (response: Conversation) => {
                              const queries =
                                  response.counters.queries.missing || 0;
                              return (
                                  <p className="d-fc-black-900 d-px16 d-d-flex d-jc-flex-end">
                                      {queries}
                                  </p>
                              );
                          },
                          sort: 'queries_missing',
                          sortable: false
                      },
                      {
                          key: 'queries',
                          type: 'string',
                          title: 'Queries',
                          template: (response: Conversation) => {
                              const queries =
                                  response.counters.queries.total || 0;
                              return (
                                  <p className="d-fc-black-900 d-px16 d-d-flex d-jc-flex-end">
                                      {queries}
                                  </p>
                              );
                          },
                          sort: 'queries_count',
                          sortable: false
                      },
                      {
                          key: 'automations',
                          type: 'string',
                          title: this.automationsTitle,
                          template: (response: Conversation) => {
                              const webexts =
                                  response.counters.clicks.webexts || 0;
                              return (
                                  <p className="d-fc-black-900 d-px16 d-d-flex d-jc-flex-end">
                                      {webexts}
                                  </p>
                              );
                          },
                          sort: 'automations',
                          sortable: false
                      },
                      {
                          key: 'thumbs_up',
                          type: 'string',
                          title: 'Thumbs up',
                          template: (response: Conversation) => {
                              const thumbsUp =
                                  response.counters.clicks.thumbs_ups || 0;
                              return (
                                  <p className="d-fc-black-900 d-px16 d-d-flex d-jc-flex-end">
                                      {thumbsUp}
                                  </p>
                              );
                          },
                          sort: 'thumbs_up',
                          sortable: false
                      },
                      {
                          key: 'thumbs_down',
                          type: 'string',
                          title: 'Thumbs down',
                          template: (response: Conversation) => {
                              const thumbsDown =
                                  response.counters.clicks.thumbs_downs || 0;
                              return (
                                  <p className="d-fc-black-900 d-px16 d-d-flex d-jc-flex-end">
                                      {thumbsDown}
                                  </p>
                              );
                          },
                          sort: 'thumbs_down',
                          sortable: false
                      }
                  ];
            return [
                {
                    key: 'last_message_time',
                    type: 'datetime',
                    title: 'Conversation on',
                    sort: 'last_message_time',
                    sortable: false,
                    template: (response: Conversation) => {
                        const labels = response.labels;

                        return h(
                            <div className="d-py12 d-px16">
                                <p className="d-truncate d-to-ellipsis">
                                    {getFullDateFromTimestamp(
                                        this.convertDateTimezone(
                                            response.last_message_time
                                        ),
                                        this.$t
                                    )}{' '}
                                    {this.$t('at')}{' '}
                                    {getFormattedHour(
                                        this.convertDateTimezone(
                                            response.last_message_time
                                        )
                                    )}
                                </p>
                                <LabelTag labels={labels} />
                            </div>
                        );
                    }
                },
                ...moreInfoColumns.filter((col) => {
                    if (col.key !== 'automations') return true;
                    return (
                        (!this.isSelfServiceChatbot &&
                            this.hasAutomationsEnabled) ||
                        this.isSelfServiceChatbot
                    );
                })
            ];
        },
        labelsSelectedFilters: {
            get(): string[] {
                return this.selectedLabels;
            },
            set(labels: string[]) {
                this.selectedLabels.length = 0;
                if (labels.length && labels[0])
                    this.selectedLabels.push(...labels);
                this.onLabelsFilterChange();
            }
        },
        dateSelectedFilters: {
            get(): string[] {
                return this.selectedDate || [];
            },
            set(date: string[]) {
                this.selectedDate.length = 0;
                if (date.length && date[0]) this.selectedDate.push(...date);
                this.onDateFilterChange();
            }
        },
        sortByVal: {
            get(): { key: any; asc: any } {
                return this.sortBy;
            },
            set(sortBy: { key: any; asc: any }) {
                this.sortBy = {
                    ...this.sortBy,
                    ...sortBy
                };
            }
        },
        HISTORY_PAGE_ROW_HEIGHT(): number {
            return HISTORY_PAGE_ROW_HEIGHT;
        },
        aiAssistChatbotUrl(): string {
            return `https://virtual-assistant.${this.$store.getters[`${this.orgId}/zone`]}.karehq.com/latest.js`;
        },
        chatbotName(): string {
            return APP_BY_LICENSE_TYPE.SINGULAR[
                this.currentChatbot.license_type
            ];
        }
    },
    methods: {
        async fetchAll() {
            this.canFetchMore = true;
            this.cursor = undefined;
            this.rawData = [];

            if (this.conversationHistoryOpen) {
                this.closeConversationHistory();
                this.drawerService?.closeDrawer();
            }

            this.isFetching = true;
            const res = await this.fetchConversations();
            await this.fetchTags();

            if (res?.data && res.data.conversations !== undefined) {
                this.rawData = res.data.conversations;
                this.cursor = res.data.next_cursor;
            }
            this.isFetching = false;
            this.hasLoaded = true;
            if (res?.error) {
                this.hasError = true;
            }
        },
        async fetchMore() {
            if (!this.canFetchMore) return;
            this.isFetchingMore = true;
            const res = await this.fetchConversations();
            await this.fetchTags();

            if (res?.data && res.data.conversations !== undefined) {
                if (res.data.conversations.length < this.itemsPerBatch) {
                    this.canFetchMore = false;
                }
                this.rawData = [...this.rawData, ...res.data.conversations];
                this.cursor = res.data.next_cursor;
            }
            this.isFetchingMore = false;
        },
        async fetchConversations() {
            if (!this.dateSelectedFilters[0]) return;
            const { fromDate, toDate } = getFromToDates(
                this.dateSelectedFilters[0]
            );

            const fromToDates = {
                fromDate: Math.floor(fromDate / 1000),
                toDate: Math.floor(toDate / 1000)
            };
            return handleRequest<ListConversationsResponse>(
                this.apiService.activity.listConversations(
                    this.authToken,
                    this.currentChatbot.id!,
                    this.searchUsername,
                    undefined,
                    undefined,
                    this.labelsSelectedFilters?.join(','),
                    fromToDates?.fromDate || undefined,
                    fromToDates?.toDate || undefined,
                    this.cursor,
                    this.itemsPerBatch,
                    this.currentKnowledgebase.locale
                ),
                this.orgId
            );
        },
        async fetchConversationById() {
            return handleRequest<GetConversationResponse>(
                this.apiService.activity.getConversation(
                    this.authToken,
                    this.currentChatbot.id!,
                    this.conversationId
                ),
                this.orgId,
                true
            );
        },
        async fetchTags() {
            this.isFetchingTags = true;
            const res = await handleRequest<ListTagsOKBody>(
                this.apiService.knowledge.listTags(
                    this.authToken,
                    this.currentKnowledgebase.id!
                ),
                this.orgId,
                true
            );
            this.isFetchingTags = false;
            this.tagsError = !!res.error;

            if (res?.data) {
                this.tags =
                    res.data?.tags?.map((tag: string) => ({
                        label: tag,
                        value: tag
                    })) || [];
            }
        },
        async fetchConversationHistory(conversation: Conversation) {
            return handleRequest<ListConversationEvents>(
                this.apiService?.activity.listConversationEvents(
                    this.authToken,
                    this.currentChatbot.id!,
                    conversation.id
                ),
                this.orgId
            );
        },
        async onRowClick(conversationRow: IBaseTableRow<Conversation>) {
            await this.openHistoryDrawer(conversationRow.data);
            this.activeRowId = conversationRow.id;
            updatePathParams(this.$router, {
                conversationId: conversationRow.id
            });
        },
        async openHistoryDrawer(conversation: Conversation) {
            const historyDrawer = h(HistoryDrawer, {
                appId: this.currentChatbot.id,
                appName: this.currentChatbot.name!,
                conversationMetadata: conversation,
                aiAssistLoading: this.aiAssistLoading,
                readOnly: true,
                onClose: this.closeConversationHistory
            });

            if (!this.aiAssistLoading) {
                const drawer: Drawer = {
                    id: uuidv4(),
                    name: 'history',
                    width: DRAWER_WIDTH,
                    componentInstance: historyDrawer
                };
                this.drawerService?.toggleDrawer(drawer);
                this.selectedConversation = conversation;
                this.conversationHistoryOpen = true;
                const conversationHistoryResponse =
                    await this.fetchConversationHistory(
                        this.selectedConversation
                    );

                if (
                    (window as any).DialpadVA &&
                    (window as any).DialpadVA[this.currentChatbot.id!]
                ) {
                    (window as any).DialpadVA[
                        this.currentChatbot.id!
                    ].ShowConversationHistory(
                        conversationHistoryResponse.data?.events || [],
                        this.selectedConversation.id
                    );
                }
            }
        },
        onScroll(e: any) {
            if (this.isFetching || this.isFetchingMore || this.hasError) return;
            const { scrollTop, offsetHeight, scrollHeight } = e.target;
            if (
                scrollTop + offsetHeight >=
                scrollHeight - HISTORY_PAGE_ROW_HEIGHT
            ) {
                this.fetchMore();
            }
        },
        closeConversationHistory() {
            this.conversationHistoryOpen = false;
            this.activeRowId = undefined;
            updatePathParams(this.$router, { conversationId: '' });
        },
        onLabelsFilterChange() {
            replaceQueryFilterParams(
                'labels',
                this.labelsSelectedFilters,
                this.$route,
                this.$router
            );
        },
        onDateFilterChange() {
            replaceQueryFilterParams(
                'date',
                this.dateSelectedFilters,
                this.$route,
                this.$router
            );
        },
        convertDateTimezone(date: number) {
            return convertTimezone(date, this.timezone, this.locale);
        }
    },
    data() {
        return {
            searchUsername: '',
            conversationHistoryOpen: false,
            rawData: [] as Conversation[],
            isFetching: false,
            isFetchingMore: false,
            hasError: false,
            itemsPerBatch: 20,
            hasLoaded: false,
            cursor: undefined as string | undefined,
            aiAssistLoading: true,
            sortBy: {
                key: undefined,
                asc: undefined
            } as IBaseSortableTableColumn,
            selectedRows: [] as IBaseTableRow[],
            selectedConversation: {} as Conversation,
            wrapperHeight: 0,
            wrapperWidth: 0,
            activeRowId: undefined as string | undefined,
            isFetchingTags: false,
            tags: [] as IFilter[],
            tagsSelected: [] as string[],
            tagsError: false,
            DATE_OPTIONS,
            canFetchMore: true,
            hasAutomationsEnabled: false,
            rootOnScrollSubscription: undefined as Subscription | undefined
        };
    }
});
</script>
