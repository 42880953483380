<template>
    <Transition name="slide" v-bind="$attrs">
        <drawer-template
            v-if="showGoogleDriveConnectPage"
            @drawer-template-close="handleClose"
            @back="handleBackClick(successMessage)"
            is-secondary-page
        >
            <template #header>
                <h3 class="d-d-flex d-jc-flex-start d-py8 d-my2 d-ml32 d-pl8">
                    {{
                        `${isConnected ? $t('Configure') : $t('Connect')}
                            Google Drive
                        `
                    }}
                </h3>
            </template>
            <template #default>
                <div v-if="isLoadingSettings" class="d-d-flex d-jc-center">
                    <dt-icon class="loading" name="loading" size="700" />
                </div>
                <div
                    v-if="
                        !userEmail &&
                        !tempAttachments.length &&
                        !isLoadingSettings
                    "
                >
                    <div class="d-headline--md d-mb4">
                        {{ $t('How does it work?') }}
                    </div>
                    <ul class="d-px16 d-mb16">
                        <li class="d-lst-disc">
                            {{
                                $t(
                                    'Import and sync public & private Google Docs in a specific folder'
                                )
                            }}
                        </li>
                        <li class="d-lst-disc">
                            {{
                                $t(
                                    'The system will only import the files that you have access to. If your permissions change after the import, your imported files will be archived'
                                )
                            }}
                        </li>
                    </ul>
                    <div class="d-d-flex d-fd-column d-gg16 d-mb16">
                        <dt-checkbox
                            :checked="authorize"
                            @input="handleAuthorizeClick"
                            value="authorize"
                            :label="
                                $t(
                                    'I authorize Dialpad to access my Google Drive data for troubleshooting and support purposes.'
                                )
                            "
                            :validation-state="VALIDATION_MESSAGE_TYPES.ERROR"
                            :show-messages="showAuthorizeError"
                            :messages="[
                                $t(
                                    'Can’t link Google Drive account without consent confirmation'
                                )
                            ]"
                        />
                        <dt-checkbox
                            :checked="acknowledge"
                            @input="handleAcknowledgeClick"
                            value="acknowledge"
                            :label="
                                $t(
                                    'I acknowledge that I am prohibited from using data obtained through this integration for training generalized machine-learning (ML) or artificial intelligence (AI) models.'
                                )
                            "
                            :validation-state="VALIDATION_MESSAGE_TYPES.ERROR"
                            :show-messages="showAcknowledgeError"
                            :messages="[
                                $t(
                                    'Can’t link Google Drive account without consent confirmation'
                                )
                            ]"
                        />
                    </div>
                    <dt-button
                        @click="handleConnectGoogleDrive"
                        :disabled="isGoogleButtonDisabled || isRequesting"
                    >
                        {{ $t('Link Google Drive account') }}
                    </dt-button>
                </div>
                <div
                    v-if="
                        (userEmail || tempAttachments.length) &&
                        !isLoadingSettings
                    "
                >
                    <p>{{ $t('This account is authorized as') }}:</p>
                    <p class="d-fw-bold">
                        {{ userEmail }}
                    </p>

                    <h4 class="d-mt16">{{ $t('Target folder') }}</h4>
                    <div class="d-d-flex d-ai-center d-gg8 d-mb8">
                        <dt-icon name="folder" size="300" />
                        <div
                            :class="{
                                'd-fc-warning':
                                    tempAttachments?.length &&
                                    !tempAttachments?.[0]?.name
                            }"
                        >
                            {{
                                tempAttachments?.length
                                    ? tempAttachments?.[0]?.name ||
                                      $t("Can't fetch folder name")
                                    : $t('No folder selected')
                            }}
                        </div>
                        <dt-button
                            link
                            class="d-ml-auto"
                            @click="handleChangeFolder"
                        >
                            {{
                                $t(
                                    tempAttachments?.length
                                        ? 'Change folder'
                                        : 'Select folder'
                                )
                            }}
                        </dt-button>
                    </div>
                    <div
                        class="d-fs-100 d-fc-warning d-d-flex d-ai-flex-start d-gg4"
                        v-if="isGDriveAccountDifferent"
                    >
                        <dt-icon
                            name="alert-triangle"
                            size="100"
                            class="d-mt4"
                        />
                        {{
                            $t(
                                "You don't have access to this account. If you want to change the folder then you have to connect your account."
                            )
                        }}
                    </div>
                    <div
                        class="d-fs-100 d-fc-warning d-d-flex d-ai-center d-gg4"
                        v-if="gDriveData?.status === 'not-found'"
                    >
                        <dt-icon
                            name="alert-triangle"
                            size="100"
                            class="d-mt4"
                        />
                        {{
                            $t(
                                'Your access to this folder is revoked. Please change the folder.'
                            )
                        }}
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="d-d-flex d-py16">
                    <dt-button
                        class="d-mr16"
                        importance="clear"
                        @click="handleBackClick"
                        :disabled="isRequesting"
                    >
                        {{ $t('Cancel') }}
                    </dt-button>
                    <dt-button
                        v-if="isConnected || tempAttachments?.length"
                        id="open-child-window"
                        kind="danger"
                        importance="clear"
                        @click="handleShowConfirmationModal"
                        :disabled="isRequesting"
                    >
                        {{ $t('Disconnect') }}
                    </dt-button>
                </div>
            </template>
        </drawer-template>
    </Transition>
    <confirmation-modal
        type="Google Drive"
        v-model:show-prompt="showConfirmationModal"
        @confirm="handleDisconnectGoogleDrive"
    />
    <confirmation-modal
        :title="$t('Change folder')"
        :description="
            $t(
                'Your Google Drive account will be unlinked so that you can re-authorize and select a new target folder. All previously imported documents will be archived. Please note that this action is irreversible. Are you sure you want to proceed?'
            )
        "
        :button-title="$t('Yes, unlink Google Drive account')"
        v-model:show-prompt="showDifferentAccountConfirmationModal"
        @confirm="handleConnectGoogleDrive"
    />
</template>
<script lang="ts">
import { defineComponent, inject, type PropType } from 'vue';
import {
    DtButton,
    DtIcon,
    DtCheckbox,
    VALIDATION_MESSAGE_TYPES
} from '@dialpad/dialtone/vue3';
import DrawerTemplate from '@/components/drawer-template/DrawerTemplate.vue';
import ConfirmationModal from '@/components/confirmation-modal/ConfirmationModal.vue';
import type {
    IExternalSource,
    INotice
} from '@/components/sources-drawer/Sources.types';

import {
    AccessTokenResponse,
    AuthUrlResponse,
    GetSettingsResponse,
    Knowledgebase,
    UpdateSettingsRequest
} from '@/open-api';

import { handleRequest, uuidv4 } from '@/utils/Common';
import { NOTICE_KINDS } from '@/utils/Constants';
import type { Integrations } from '@/store/AppModule';

function initialState() {
    return {
        isRequesting: false,
        successMessage: '' as INotice,
        tempAttachments: [] as any[],
        isLoadingSettings: false,
        gDriveData: null as any,
        userEmail: null,
        accessToken: '' as string,
        authorize: false,
        acknowledge: false
    };
}

export default defineComponent({
    props: {
        showGoogleDriveConnectPage: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        data: {
            type: Object as PropType<IExternalSource>
        }
    },
    setup() {
        const orgId: string = inject('orgId')!;

        return {
            orgId
        };
    },
    beforeUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        window.removeEventListener('message', this.handleGDriveOnMessage);
    },
    components: {
        ConfirmationModal,
        DrawerTemplate,
        DtButton,
        DtIcon,
        DtCheckbox
    },
    watch: {
        data(newData) {
            this.gDriveData = newData;
            if (this.gDriveData?.status === 'Connected') {
                this.getGDriveSettings();
            }
        },
        importStatus(newStatus, oldStatus) {
            if (newStatus.gdrive !== oldStatus.gdrive) {
                this.isGDriveAccountDifferent = newStatus.gdrive === 'failed';
            }
        }
    },
    mounted() {
        this.isGDriveAccountDifferent = this.importStatus?.gdrive === 'failed';
    },
    emits: ['back', 'connectClose'],
    computed: {
        VALIDATION_MESSAGE_TYPES() {
            return VALIDATION_MESSAGE_TYPES;
        },
        /* c8 ignore next 22 */
        knowledgebase(): Knowledgebase {
            return this.$store.getters[`${this.orgId}/currentKnowledgebase`];
        },
        isConnected(): boolean {
            return this.gDriveData?.status === 'Connected';
        },
        config(): Integrations {
            return this.$store.getters[`${this.orgId}/integrations`];
        },
        configUrl(): string {
            const provider = 'gdrive';
            const redirectUri = new URL(
                this.config?.[provider].redirectPath,
                this.config?.[provider].redirectOrigin
            );

            return redirectUri.toString();
        }
    },
    methods: {
        handleClose() {
            this.$emit('connectClose');
        },
        handleBackClick(resp: INotice) {
            this.$emit(
                'back',
                Object.keys(resp).length && resp.title
                    ? {
                          title: resp.title,
                          message: resp.message,
                          kind: NOTICE_KINDS.SUCCESS
                      }
                    : {}
            );
            this.resetValues();
        },
        handleAuthorizeClick(checked: any) {
            this.authorize = !!checked;
            this.showAuthorizeError = false;
        },
        handleAcknowledgeClick(checked: any) {
            this.acknowledge = !!checked;
            this.showAcknowledgeError = false;
        },
        /* c8 ignore next 120 */
        async handleConnectGoogleDrive() {
            if (!this.authorize || !this.acknowledge) {
                this.showAuthorizeError = !this.authorize;
                this.showAcknowledgeError = !this.acknowledge;
                return;
            }

            this.isRequesting = true;
            this.tempAttachments = [];

            const provider = 'gdrive';
            const completeUrl = new URL(
                window.location.pathname + this.config?.[provider].completePath,
                window.location.origin
            );

            const authUrlRes = await handleRequest<AuthUrlResponse>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.postGDriveAuthenticationUrl(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    {
                        redirect_uri: this.configUrl,
                        state_token: btoa(completeUrl.toString())
                    }
                ),
                this.orgId
            );

            const authUrl: AuthUrlResponse = authUrlRes?.data;
            if (authUrl?.authentication_url) {
                let oauthWindow = this.popupCenter({
                    url: authUrl?.authentication_url,
                    title: `gdrive-${uuidv4()}`,
                    w: 500,
                    h: 800
                });

                window.addEventListener(
                    'message',
                    this.handleGDriveOnMessage,
                    false
                );

                this.interval = setInterval(() => {
                    let sameOrigin: boolean;
                    try {
                        sameOrigin =
                            oauthWindow?.origin === window.location.origin;
                    } catch (e) {
                        sameOrigin = false;
                    }
                    try {
                        if (oauthWindow?.closed && !sameOrigin) {
                            clearInterval(this.interval);
                            this.isRequesting = false;
                            oauthWindow = null;
                        }
                    } catch (e) {
                        console.log('err', e);
                    }
                }, 500);
            }
        },
        popupCenter({ url, title, w, h }) {
            const dualScreenLeft =
                window.screenLeft !== undefined
                    ? window.screenLeft
                    : window.screenX;
            const dualScreenTop =
                window.screenTop !== undefined
                    ? window.screenTop
                    : window.screenY;

            const width = window.innerWidth
                ? window.innerWidth
                : document.documentElement.clientWidth
                  ? document.documentElement.clientWidth
                  : screen.width;
            const height = window.innerHeight
                ? window.innerHeight
                : document.documentElement.clientHeight
                  ? document.documentElement.clientHeight
                  : screen.height;

            const systemZoom = width / window.screen.availWidth;
            const left = (width - w) / 2 / systemZoom + dualScreenLeft;
            const top = (height - h) / 2 / systemZoom + dualScreenTop;
            const newWindow = window.open(
                url,
                title,
                `
                scrollbars=yes,
                width=${w / systemZoom},
                height=${h / systemZoom},
                top=${top},
                left=${left}
                `
            );

            if (window.focus) newWindow.focus();

            return newWindow;
        },
        async handleChangeFolder() {
            if (this.isGDriveAccountDifferent) {
                this.handleShowDifferentAccountConfirmationModal();
                return;
            }

            this.isRequesting = true;

            const accessTokenRes = await handleRequest<AccessTokenResponse>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.getGDriveAccessToken(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id
                ),
                this.orgId,
                true
            );

            this.isRequesting = false;

            if (accessTokenRes?.error) {
                this.isGDriveAccountDifferent = true;
                this.handleShowDifferentAccountConfirmationModal();
            }

            if (accessTokenRes?.data?.access_token) {
                this.accessToken = accessTokenRes.data?.access_token;
                this.userEmail = accessTokenRes?.data?.google_email;

                if (this.accessToken) {
                    this.$gapi.load('picker', () => {
                        this.pickerApiLoaded = true;
                        this.createPicker();
                    });
                }
            }
        },
        handleGDriveOnMessage(event: MessageEvent) {
            if (event.data?.typeEvent === 'gDriveEvent') {
                const code = event.data?.code;
                if (code) {
                    this.getAccessToken(code);
                }
            }
        },
        async getAccessToken(code: string) {
            const accessTokenRes = await handleRequest<AccessTokenResponse>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.authenticateGDrive(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    {
                        authentication_code: code,
                        redirect_uri: this.configUrl
                    }
                ),
                this.orgId
            );

            this.isRequesting = false;
            this.accessToken = accessTokenRes?.data?.access_token;
            this.userEmail = accessTokenRes?.data?.google_email;
            this.successMessage = {
                title: `Google Drive ${this.$t(
                    'account connected successfully'
                )}`
            };

            if (this.accessToken) {
                this.$gapi.load('picker', () => {
                    this.pickerApiLoaded = true;
                    this.createPicker();
                });
                this.gDriveData.status = 'Connected';
            }
        },
        async getGDriveSettings() {
            this.isLoadingSettings = true;

            const gDriveSettingsRes = await handleRequest<GetSettingsResponse>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.getGDriveSettings(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    {
                        redirect_uri: this.configUrl
                    }
                ),
                this.orgId
            );
            this.isLoadingSettings = false;
            if (gDriveSettingsRes?.data) {
                this.tempAttachments = gDriveSettingsRes.data.folders;
                this.userEmail = gDriveSettingsRes.data.google_email;
            }
        },
        async handleDisconnectGoogleDrive() {
            this.isRequesting = true;

            const resKb = await handleRequest(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.disconnectGDrive(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id
                ),
                this.orgId
            );
            this.isRequesting = false;

            if (resKb.error) {
                this.showErrorMsg(
                    'Could not disconnect this GoogleDrive account'
                );
            } else {
                this.handleBackClick({
                    title: `Google Drive ${this.$t(
                        'account disconnected successfully'
                    )}`
                });
                this.authorize = false;
                this.acknowledge = false;
            }
        },
        createPicker() {
            this.isGDriveAccountDifferent = false;
            if (this.pickerApiLoaded && this.accessToken) {
                const enableSelectFolders =
                    //@ts-ignore
                    new google.picker.DocsView()
                        .setIncludeFolders(true)
                        .setMimeTypes(
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document,' + // .docx
                                'application/pdf,' + // .pdf
                                'application/vnd.google-apps.document,' + // Google Docs
                                'text/plain,' + // .txt
                                'text/markdown,' + // .md
                                'text/html' // .html
                        )
                        .setSelectFolderEnabled(true);

                //@ts-ignore
                const picker = new google.picker.PickerBuilder()
                    .addView(enableSelectFolders)
                    .setSelectableMimeTypes(
                        'application/vnd.google-apps.folder'
                    )
                    .setOAuthToken(this.accessToken)
                    .setCallback(this.pickerCallback)
                    .setTitle(this.$t('Select a folder'))
                    .build();
                picker.setVisible(true);
            }
        },

        async pickerCallback(data: any) {
            if (
                //@ts-ignore
                data[google.picker.Response.ACTION] ===
                //@ts-ignore
                google.picker.Action.PICKED
            ) {
                const docs = data.docs;
                const files = [];
                for (let i = 0; i < docs.length; i++) {
                    const file: {
                        id?: string;
                        name?: string;
                    } = {};
                    file.id = docs[i].id;
                    file.name = docs[i].name;
                    files.push(file);
                }

                if (files.length) {
                    this.tempAttachments = [...files];
                    await this.updateGDriveSettings();
                }
            }
        },
        async updateGDriveSettings() {
            this.isRequesting = true;
            const body: UpdateSettingsRequest = {
                access_token: this.accessToken,
                folder_ids: this.tempAttachments.map((folder: any) => folder.id)
            };
            const settingsRes = await handleRequest<AuthUrlResponse>(
                this.$store.getters[
                    `${this.orgId}/apiService`
                ]?.ingestion.updateGDriveSettings(
                    this.$store.getters[`${this.orgId}/authToken`],
                    this.knowledgebase?.id,
                    body
                ),
                this.orgId
            );

            this.isRequesting = false;

            if (settingsRes.error) {
                this.handleBackClick({
                    title: this.$t('Error'),
                    message: settingsRes.error,
                    kind: NOTICE_KINDS.ERROR
                });
            } else {
                this.handleBackClick({
                    title: `Google Drive ${this.$t(
                        'account connected successfully'
                    )}`
                });
            }
        },
        resetValues() {
            Object.assign(this.$data, initialState());
        },
        showErrorMsg(title: string) {
            this.$store.commit(`${this.orgId}/addNotification`, {
                kind: NOTICE_KINDS.ERROR,
                title: this.$t(title)
            });
        },
        handleShowConfirmationModal() {
            this.showConfirmationModal = true;
        },
        handleShowDifferentAccountConfirmationModal() {
            this.showDifferentAccountConfirmationModal = true;
        }
    },
    data() {
        return {
            ...initialState(),
            showConfirmationModal: false,
            showDifferentAccountConfirmationModal: false,
            pickerApiLoaded: false,
            isGoogleButtonDisabled: false,
            isGDriveAccountDifferent: false,
            showAuthorizeError: false,
            showAcknowledgeError: false
        };
    }
});
</script>
