<script lang="ts">
import { inject, defineComponent, h, type PropType } from 'vue';

export default defineComponent({
    setup() {
        const orgId = inject(`orgId`);
        return {
            orgId
        };
    },
    mounted() {
        document.addEventListener('CHATBOT_SESSION_ENDED', this.onClose);
    },
    props: {
        loading: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        source: {
            type: String as PropType<string>,
            required: true
        }
    },
    /* v8 ignore next 20 */
    methods: {
        onLoaded() {
            this.$emit('update:loading', false);
        },
        onClose() {
            this.$emit('close');
        }
    },
    /* v8 ignore next 10 */
    render() {
        return h('div', [
            h('script', {
                defer: true,
                // src: `http://localhost:8888/src/main.ts`,
                src: this.source,
                type: 'module',
                onLoad: this.onLoaded
            })
        ]);
    }
});
</script>
